<template lang="pug">
.query-editor-mode(:class='{ saving: saving }')
  a.visual(
    :class="{ active: visualMode, disabled: disabledVisualMode }"
    @click="updateMode('forepaas')"
  )
    .text {{ $t('query.mode.visual') }}
  a.sql(
    id="button-select-sql-mode"
    :class="{ active: !visualMode }"
    @click="updateMode('sql')"
  )
    .text {{ $t('query.mode.sql') }}

</template>

<script>
import CannotUseBecauseOfTrino from '@/qb/components/CannotUseBecauseOfTrino'
import ConfirmModal from '@/shared/components/modals/Confirm'
import Config from '@/shared/Config'

export default {
  props: {
    fromExplorer: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    visualLink: { type: String, default: '' },
    sqlLink: { type: String, default: '' },
    item: { type: Object, default: () => { } },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null
    }
  },
  computed: {
    disabledVisualMode () {
      if (!this.item?.configuration?.sql) return false
      return !this.visualMode
    },
    visualMode () {
      return this.item?.type !== 'sql'
    },
    trinoActive () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE
    },
    hasTrinoOrMutualized () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE_OR_MUTUALIZED
    },
    isAPHP () {
      return this.hasTrinoOrMutualized && this.config?.CLUSTER === 'k8s-aphp'
    },
    isDataPlatform () {
      return this.hasTrinoOrMutualized && this.config?.DATA_PLATFORM_ACCESS
    }
  },
  async mounted () {
    this.config = await Config()
  },
  methods: {
    async updateMode (mode) {
      if (this.disabledVisualMode || this.readOnly) return

      let activeTrino = true
      if (!this.trinoActive && !this.isAPHP && !this.isDataPlatform && !this.fromExplorer) {
        activeTrino = await new Promise(resolve => {
          this.$modal.show(CannotUseBecauseOfTrino, {
            noActivate: () => resolve(false),
            activate: () => resolve(true)
          }, {
            height: '451px',
            width: '510px'
          })
        })
      }

      if (!activeTrino && !this.fromExplorer) return

      // Get all fields in configuration to check if we need to get the ask confirmation
      let dataScaleFilterOrder = []
      Object.keys(this.item.configuration).forEach(val => {
        if (val === 'filter' && this.item.configuration[val]) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.item.configuration[val])]
        }
        if (val === 'scale' && this.item.configuration[val]?.fields && this.item.configuration[val]?.fields.length > 0) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...this.item.configuration[val].fields]
        }
        if (val === 'data' && this.item.configuration[val]?.fields) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.item.configuration[val].fields)]
        }
        if (val === 'order' && this.item.configuration[val]) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.item.configuration[val])]
        }
      })

      if (dataScaleFilterOrder.length) {
        const confirmResult = await ConfirmModal(this, {
          contentHtml: true,
          content: `<span style="font-size:14px">${this.$t('query.mode.sql.warning_text')}</span><br>`,
          title: this.$t('query.mode.sql.warning_title'),
          yes: {
            text: this.$t('query.mode.sql.warning_button'),
            color: 'blue-flash'
          }
        })

        if (!confirmResult) return
        this.$analytics.track('Switch to SQL mode', {
          active_sqlview: true,
          service: this.fromExplorer ? 'Lakehouse Explorer' : 'AM'
        })

        try {
          const sql = await this.$api.QB.getSQLFromConfiguration(this.item?.configuration)
          this.item.update('configuration.sql', sql?.sql)

          // Because for now, there only table to preview result in SQL
          this.item.update('chart_active', 'table')
          this.item.update('type', mode)
        } catch (err) {
          this.$fpuiMessageBlock.error(err)
        }
      } else {
        // Because for now, there only table to preview result in SQL
        this.item.update('chart_active', 'table', false)
        this.item.update('type', mode, false)
      }
    }
  }
}
</script>

<style lang="less">
.query-editor-mode {
  display: flex;
  position: absolute;
  left: 45%;
  top: 31px;
  user-select: none;
  cursor: pointer;
  height: 25px;
  transition: opacity 200ms;
  &.saving {
    cursor: wait;
    opacity: 0.6 !important;
  }
  .text {
    margin-top: 5px;
    text-transform: uppercase;
    color: #97A7B7;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.63px;
    line-height: 13px;
    text-align: center;
  }
  .visual {
    box-sizing: border-box;
    width: 70px;
    border: 1px solid rgba(151,167,183,0.21);
    border-radius: 5px 0 0 5px;
    background-color: #FFFFFF;
  }
  .sql {
    box-sizing: border-box;
    height: 25px;
    width: 70px;
    border: 1px solid rgba(151,167,183,0.21);
    border-radius: 0 5px 5px 0;
  }
  .visual, .sql {
    &.active {
      background-color: #97A7B7;
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.3);
      .text {
        color: #FFFFFF;
      }
    }
    &.disabled {
      opacity: 0.5;
      background: #dedede;
      cursor: not-allowed;
    }
  }
}
</style>
