import { API } from '@/shared/plugins/Api/API'
import Certificates from './Certificates'
import Dataplants from './Dataplants'
import Prices from './Prices'
import Config from '@/shared/Config'
import urljoin from 'url-join'
import qs from 'qs'
class FPAPI extends API {
  constructor (id) {
    super(id, true)
    this.dataplants = new Dataplants(id)
    this.prices = new Prices(id)
    this.certificate = new Certificates(id)
  }

  // Get default configuration
  async offers () {
    const offers = await this.request({
      method: 'get',
      url: '/offers'
    })

    return offers
  }

  // Get default configuration
  async configuration (organizationId) {
    const configuration = await this.request({
      method: 'get',
      url: `/configurations/${organizationId}`
    })

    return configuration
  }

  // Check domain availability
  async checkDomainAvailability (subdomain, domain = 'forepaas.io', organizationId) {
    const availability = await this.request({
      method: 'GET',
      url: `check_domain/${subdomain}/${domain}`,
      params: {
        organization_id: organizationId
      }
    })

    return availability
  }

  async preferences (clientId = 'hq', organizationId, options) {
    let headers = {}
    if (organizationId) headers = { 'Organization-Id': organizationId }
    return this.request({
      url: `iam/v4/preferences/${clientId}`,
      headers,
      ...options
    })
  }

  async loginRedirect (loginMode, forceAuth = false, clientId = null, organizationId) {
    const config = await Config()
    const queryString = qs.stringify({
      authentication_provider: loginMode?._id,
      app_id: clientId || config.APP_ID,
      redirect_uri: window.location.href,
      token_mode: 'cookie',
      force_auth: forceAuth,
      organization_id: organizationId
    })
    const loginUrl = urljoin((config)[this.xhrID], '/iam/v4/login', `?${queryString}`)
    window.location.href = loginUrl
  }
}

export default FPAPI
