<template lang="pug">
.data-project-templates.loading(v-if="loading")
  fp-loading
.data-project-templates(v-else)
  project-template-box(
    v-for="template in templates"
    :item="template"
    :key="template.id"
    :disabled="!canCreateProject"
    :class="{ active: activeTemplate === template.id }"
    :from-funnel='fromFunnel'
    v-tooltip="{ content: !canCreateProject ? disabledMessage : '', delay: 0 }"
    @chosenTemplate="$emit('chosenTemplate', $event)"
    @next="$emit('next')"
    @create-project="createProject"
    :from-onboarding="fromOnboarding"
  )
</template>

<script>
import _get from 'lodash/get'
import Config from '@/shared/Config'
import ProjectTemplateBox from '@/shared/components/project/ProjectTemplateBox'

export default {
  components: {
    ProjectTemplateBox
  },
  props: {
    organizationId: { type: String, default: () => '' },
    activeTemplate: { type: String, default: () => '' },
    fromFunnel: { type: Boolean, default: false },
    fromServices: { type: Boolean, default: false },
    fromOnboarding: { type: Boolean, default: true }
  },
  data () {
    return {
      loading: true,
      templates: [],
      config: {}
    }
  },
  computed: {
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    canCreateProject () {
      return this.$acl(this.config.CMP ? this.organizationId : true, this.organizationSession).can('core', 'dataplants', 'create')
    },
    disabledMessage () {
      return this.$t('core.onboarding.no_permission_to_create')
    }
  },
  async mounted () {
    this.config = await Config()
    await this.$store.dispatch('STORE_INIT', ['project'])
    await this.loadTemplatesMeta()
    this.loading = false
  },
  methods: {
    async loadTemplatesMeta () {
      const templates = []
      const storeTemplates = this.$store.getters.STORE_PACKAGES.project?.templates
      // For the data platform, do not show aab options
      if (this.config?.DATA_PLATFORM_ACCESS) delete storeTemplates['ai-app-builder']
      const modules = this.$store.getters.STORE_PACKAGES.project?.modules
      for (const templateId in storeTemplates) {
        const template = storeTemplates[templateId]
        const meta = await this.$api.STORE.metas('project', 'templates', templateId, 'latest')
        const services = Object.keys(meta.options?.services).map(service => {
          let icon = ''
          try {
            icon = require(`@/core/components/Dataplant/assets/${service}.svg`)
          } catch (err) {
            icon = require('@/core/assets/img/logo.png')
          }
          return {
            img: icon,
            service: _get(modules, [service, 'description']),
            id: service
          }
        }).filter(service => {
          if (this.config?.DATA_PLATFORM_ACCESS && service.id === 'mlm') return false
          if (!this.config?.DATA_PLATFORM_ACCESS && service.id === 'gab') return false
          return true
        })
        templates.push({
          id: templateId,
          services: services,
          title: template.description,
          description: template.long_description,
          image: template.image
        })
      }

      const sortOrder = this.config?.DATA_PLATFORM_ACCESS ? ['end-to-end', 'analytics', 'blank'] : ['end-to-end', 'analytics', 'ai-app-builder', 'machine-learning', 'blank']
      templates.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))

      if (!this.fromServices || this.config?.DATA_PLATFORM_ACCESS) this.templates = templates.slice(0, 3)
      else this.templates = templates
    },
    createProject (template) {
      this.$emit('create-project', template)
    }
  }
}
</script>

<style lang="less">
.data-project-templates {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.loading {
    justify-content: center;
  }

  .project-template-box {
    margin-bottom: 10px;

    &.active {
      background-color: #E7F8FF;
      border: 1px solid #0089C0;

      .icon-container {
        background-color: white;
      }
    }

  }
}
</style>
