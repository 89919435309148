<template lang="pug">
.mfa-authenticator
  .text-center
    label {{$t('authentication.mfa.authenticator.code_label')}}
    input(
      type="text"
      name="digitCode"
      autocomplete="off"
      :value="value"
      :placeholder="$t('authentication.mfa.code')"
      @input="(event)=>$emit('input',event.target.value)"
    )
</template>

<script>
export default {
  icon: 'fp4 fp4-authenticator',
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less">
.mfa-authenticator {
  .text-center {
    text-align: center;
    .button-container {
      display: inline-block;
    }
    label {
      text-transform: none;
      max-width: 565px;
      color: #3e4550;
      font-size: 14px;
      font-weight: normal;
      margin: 0 auto;
    }
    input {
      width: 200px;
      margin-top: 10px;
      text-align: center;
    }
  }
}
</style>
