<template lang="pug">
.open-in-project-container
  .projects-list-header
    span {{ $t('sidebar.open_in_project', [serviceName]) }}
  .projects-list-search
    fpui-input-search(
      ref="search"
      v-model="searchProject"
    )
  .project-list-container(
    :style="projectListMaxHeight"
  )
    .service-in-organization(v-if="serviceInOrganization")
      a.service(
        :href="serviceInOrganizationLink"
        @click.stop="trackOpenService"
      )
        .icon-container
          img(:src="require('@/shared/assets/img/logo.png')")
        span {{ $t('sidebar.service_in_organization.open', [serviceName]) }}
    .sub-title(
      v-if="projectsWithService.length"
    ) {{ $t('sidebar.sub_title.including_this_service') }}
    .projects-list-list
      a.project(
        v-for="project in projectsWithService"
        :href="getLink(project)"
        :class="{ disabled: !projectIsReady(project) || healthCheckInterval[project.id] }"
        v-tooltip="{ content: projectTooltip(project), delay: 0 }"
        @click.stop="trackOpenService"
      )
        .icon-container
          i.fp4.project-icon(
            :class="[getProjectIconSettings(project).type]"
            :style="{ color: getProjectIconSettings(project).color }"
          )
        span {{ project.display_name }}
        span(
          v-if="project.id === projectId" class="current-project"
          ) {{ $t('sidebar.current_project') }}

        fp-loading.installing(v-if="healthCheckInterval[project.id]")
        i.fp4(
          v-if="fromSidebar && !healthCheckInterval[project.id]"
          v-tooltip="{ content: isFavorites(project) ? $t('home.dataplant.remove.pin_favorites') : $t('home.dataplant.pin_favorites'), delay: 0 }"
          :class="isFavorites(project) ? 'fp4-pin-filled' : 'fp4-pin-empty'"
          @click.stop.prevent="manageFavorites(project)"
        )

    .sub-title(
      v-if="projectsWithoutService.length || (!projectsFiltered.length && !searchProject)"
    ) {{ $t('sidebar.sub_title.other_projects') }}
    .projects-list-list
      .project(
        v-if="projectsWithoutService.length"
        v-for="project in projectsWithoutService"
        :class="{ disabled: !projectIsReady(project) }"
        v-tooltip="{ content: !projectIsReady(project) ? $t('header.project_is_not_ready') : '', delay: 0 }"
        @click="addService(project, true)"
      )
        .icon-container
          i.fp4.project-icon(
            :class="[getProjectIconSettings(project).type]"
            :style="{ color: getProjectIconSettings(project).color }"
          )
        span {{ project.display_name }}

    .project.no-elements(
      v-if="!projectsFiltered.length && !searchProject"
    )
      .title-no {{ $t('sidebar.no_projects_title') }}
      .text {{ $t('sidebar.no_projects_text') }}

  .create-project(
    v-if="displayCreateProject"
  )
    fpui-button(
      color="blue-flash"
      icon="fp4 fp4-plus"
      autoWidth
      icon-left
      reverse
      no-border
      noshadow
      :disabled="!canCreateProject"
      v-tooltip="{ content: !canCreateProject ? $t('dashboard.access.tooltip') : '', delay: 0 }"
      @click.stop="createProject"
    ) {{ createProjectText }}
</template>

<script>
import QuickProjectCreation from '@/shared/components/app/QuickProjectCreation'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import Config from '@/shared/Config'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    organizationId: { type: String, default: '' },
    fromSidebar: { type: Boolean, default: false },
    fromDataplant: { type: Boolean, default: false },
    fromService: { type: Boolean, default: false },
    fromOrganizationService: { type: Boolean, default: false },
    serviceActive: { type: Object, default: () => ({}) },
    maxHeight: { type: String, default: null },
    currentService: { type: String, default: null },
    displayServiceInOrganization: { type: Boolean, default: true }
  },
  data () {
    return {
      searchProject: '',
      healthCheckInterval: {},
      config: {},
      addLoading: false
    }
  },
  computed: {
    createProjectText () {
      if (this.searchProject && !this.projectsFiltered.length) return this.$t('sidebar.create_custom_project', [this.searchProject])
      return this.$t('sidebar.create_project')
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription || null
    },
    projectId () {
      return this.$route.params?.dataplantId || this.$route.params?.projectId
    },
    projects () {
      if (this.config?.TITLE === 'CC' && !this.projectId) return this.$store.getters.CC_DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId) || []
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId) || []
    },
    currentUser () {
      if (this.fromDataplant) return this.$store.getters.KING_SESSION || this.$store.getters.SESSION // In CC KING_SESSION is not set
      return this.$store.getters.SESSION
    },
    favorites () {
      return this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.favorites || []
    },
    projectsFiltered () {
      if (!this.searchProject) return this.projects
      return this.projects.filter(p => p.name?.toLowerCase().includes(this.searchProject.toLowerCase()))
    },
    projectsWithAndWithoutService () {
      const projectsWithAndWithoutService = {
        with: [],
        without: []
      }
      this.projectsFiltered.forEach(p => {
        if (Array.isArray(p.services) && p.services?.includes(this.serviceActive?.serviceId)) projectsWithAndWithoutService.with.push(p)
        else projectsWithAndWithoutService.without.push(p)
      })

      return projectsWithAndWithoutService
    },
    projectsWithService () {
      return this.projectsWithAndWithoutService.with
    },
    projectsWithoutService () {
      return this.projectsWithAndWithoutService.without
    },
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    canCreateProject () {
      const acl = this.config?.CMP ? this.organizationId : (this.currentService === 'iam' ? this.organizationId : true)
      return this.$acl(acl, this.organizationSession).can('core', 'dataplants', 'create')
    },
    serviceInOrganization () {
      if (!this.displayServiceInOrganization) return false
      const services = ['iam', 'control-center']
      return services.includes(this.serviceActive?.id)
    },
    serviceName () {
      return this.$t(this.serviceActive && this.serviceActive?.displayName ? this.serviceActive.displayName : '')
    },
    displayCreateProject () {
      if (this.fromService || this.fromOrganizationService) return false
      return !this.searchProject || (this.searchProject && !this.projectsFiltered.length)
    },
    serviceInOrganizationLink () {
      const service = this.serviceActive.id === 'control-center' ? 'cc' : this.serviceActive.id
      return `${window.location.origin}/${service}/#/${this.organizationId}/`
    },
    projectListMaxHeight () {
      if (this.maxHeight) return { 'max-height': this.maxHeight }
      return { 'max-height': this.fromSidebar ? '448px' : '245px' }
    }
  },
  watch: {
    '$store.getters.CLICK_FROM_IFRAME': {
      handler () {
        this.close()
      }
    },
    serviceActive: {
      handler (val, oldVal) {
        this.projectsWithService.forEach(project => {
          // If an interval is set and the service is not the same, remove the interval
          if ((val?.id !== oldVal?.id) && this.healthCheckInterval[project.id]) this.removeInterval(project?.id)
          this.checkInstallStatus(project)
        })
      }
    }
  },
  async mounted () {
    this.config = await Config()
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
    // Remove all intervals if the component is destroyed
    this.projectsWithService.forEach(project => this.removeInterval(project?.id))
  },
  methods: {
    removeInterval (projectId) {
      if (this.healthCheckInterval[projectId]) {
        clearInterval(this.healthCheckInterval[projectId])
        delete this.healthCheckInterval[projectId]
      }
    },
    async makeCheckInterval (project, service, serviceHealth, redirect = false) {
      this.healthCheckInterval[project.id] = setInterval(async () => {
        try {
          const serviceUrl = this.serviceUrl(project, this.serviceActive)

          // Usage of ready for some services
          if (service.id === 'aab') {
            const { status } = await serviceHealth.ready(project?.url)
            if (!['READY'].includes(status)) throw Error('SERVICE_NOT_READY')
          } else await serviceHealth.health()
          // If health is working, clear interval
          this.removeInterval(project?.id)
          // Refresh project to get last info about services wizard
          await project.refresh()
          // Remove services wizard from object then redirect to the service
          const serviceRemoved = structuredClone(project?.display_settings?.services_wizard || {})
          delete serviceRemoved[service.id]
          project.update('display_settings.services_wizard', serviceRemoved, false)
          await project.save()
          this.addLoading = false
          if (redirect) {
            this.$fpuiMessageBlock.success(this.$t('services.add_services.created', [serviceUrl]))
            window.open(serviceUrl, '_self')
          }
        } catch (err) {
        }
      }, 5000)
    },
    checkInstallStatus (project) {
      const service = this.serviceActive
      const isInstalling = Object.keys(project.display_settings?.services_wizard || {}).includes(service?.id)

      if (isInstalling && !this.healthCheckInterval[project.id]) {
        const serviceHealth = this.getServiceHealth(this.serviceActive)
        if (!serviceHealth) return

        this.makeCheckInterval(project, service, serviceHealth, false)
      } else if (this.healthCheckInterval[project.id]) this.removeInterval(project?.id)
    },
    projectIsReady (project) {
      return project.isReady && !project.isLimited
    },
    getProjectIconSettings (project) {
      return this.projects.find(p => p.id === project.id)?.display_settings?.icon || {}
    },
    async createProject (service) {
      this.close()

      if (!this.subscription) await this.organization.loadSubscription()
      if (
        (['active', 'non_renewing'].includes(this.subscription?.status)) ||
        (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')
      ) {
        if (!this.canCreateProject) return
        if (this.fromSidebar) {
          this.$analytics.track('Open new project funnel', {
            from: 'sidebar',
            channel: 'new single service'
          })
        } else {
          this.$analytics.track('Open new project funnel', {
            from: 'services list page',
            channel: 'new single service'
          })
        }
        this.$modal.show(QuickProjectCreation, {
          organizationId: this.organizationId,
          module: ['api', 'app'].includes(this.serviceActive?.id) ? 'appservice' : this.serviceActive?.id,
          afterConfirm: () => {
            this.$router.push(`home/${this.organizationId}`)
          }
        }, {
          height: 'auto',
          width: '600px',
          adaptative: true,
          classes: []
        })
      } else {
        this.updateTrialToRealPlan(this.serviceActive)
      }
    },
    async updateTrialToRealPlan (service) {
      await this.organization.loadSubscription()
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: this.organization?.subscription?.status === 'in_trial' && this.organization?.subscription?.scheduled_status === 'cancelled',
        onConfirm: async () => {
          await this.organization.loadSubscription()
          this.createProject(service)
        }
      }, {
        height: 'auto',
        width: 900
      })
    },
    isFavorites (project) {
      return this.favorites.find(f => f.service === this.serviceActive?.id && f.projectId === project.id)
    },
    manageFavorites (project) {
      const fav = this.isFavorites(project)
      if (fav) this.removeFromFavorites(fav)
      else this.addToFavorites(project)
    },
    addToFavorites (project) {
      this.$analytics.track('Favorite service in main sidebar', { from: 'sidebar', service: this.serviceActive?.id })
      this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.favorites`, [...this.favorites, { service: this.serviceActive?.id, projectId: project.id }])
    },
    removeFromFavorites (favorite) {
      this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.favorites`, this.favorites.filter(f => !(f.projectId === favorite.projectId && f.service === favorite.service)))
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.searchProject = ''
      this.$emit('close')
    },
    getLink (project) {
      if (!this.projectIsReady(project) || this.healthCheckInterval[project?.id]) return
      let link = `${window.location.origin}/${this.serviceActive.id}/#/${project.id}`

      if (['infra'].includes(this.serviceActive.id)) {
        link = `${window.location.origin}/#/${this.serviceActive.id}/${project.id}`
      }
      if (['api', 'app'].includes(this.serviceActive.id)) {
        link = `${window.location.origin}/#/project/${project.id}`
      }
      if (['cc', 'control-center'].includes(this.serviceActive.id)) {
        if (project.id) link = `${window.location.origin}/cc/#/${project.organization_id}/${project.id}`
      }
      if (this.serviceActive.id === 'iam') {
        if (project.id) link = `${window.location.origin}/${this.serviceActive.id}/#/${project.id}`
      }
      if (this.serviceActive.id === 'mlm') {
        link = `${window.location.origin}/ml/#/${project.id}`
      }

      return link
    },
    serviceUrl (project, service) {
      if (['infra'].includes(service.id)) {
        return `${window.location.origin}/#/${service.id}/${project.id}`
      }
      if (['api', 'app', 'aab'].includes(service.id)) {
        return `${window.location.origin}/#/project/${project.id}`
      }
      if (['cc', 'control-center'].includes(service.id)) {
        if (project.id) return `${window.location.origin}/cc/#/${this.config.ORGANIZATION_ID}/${project.id}`
        return `${window.location.origin}/cc/#/${this.config.ORGANIZATION_ID}`
      }
      if (service.id === 'mlm') return `${window.location.origin}/ml/#/${project.id}`
      return `${window.location.origin}/${service.id}/#/${project.id}`
    },
    async addService (project) {
      const serviceClone = _cloneDeep(this.serviceActive)
      if (this.addLoading) return // Prevent multiple click
      if (!this.projectIsReady(project)) return

      // If service already installing -> redirect to project home
      if (Object.keys(project?.display_settings?.services_wizard || {}).includes(serviceClone.id) || (project?.services || []).includes(serviceClone.id)) {
        window.open(`${window.location.origin}/#/project/${project.id}`, '_self')
        return
      }

      this.addLoading = true
      let serviceToUse = serviceClone.id
      if (['api', 'app'].includes(serviceClone.id)) serviceToUse = 'appservice'
      if (['lakehouse', 'data-catalog'].includes(serviceClone.id)) serviceToUse = 'dm'
      if (['aab'].includes(serviceClone.id)) serviceToUse = 'gab'
      const packageUrl = `${this.config.STORE}/v1/packages/project/modules/${serviceToUse}/latest`
      const serviceHealth = this.getServiceHealth(serviceClone)
      if (!serviceHealth) {
        this.addLoading = false
        return
      }

      try {
        this.$fpuiMessageBlock.success(this.$t('services.add_services.creating'))
        await project.addService(project.id, packageUrl)

        // Refresh project to get last info about services wizard
        await project.refresh()
        const servicesWizard = {
          ...project.display_settings.services_wizard || {},
          [serviceClone.id]: new Date()
        }
        project.update('display_settings.services_wizard', servicesWizard, false)
        await project.save()

        this.makeCheckInterval(project, serviceClone, serviceHealth, true)
      } catch (error) {
        console.error(error)
        this.removeInterval(project?.id)
        this.$fpuiMessageBlock.error(this.$t('services.add_services.creating.error'))
        this.addLoading = false
      }
    },
    trackOpenService () {
      if (this.fromSidebar) {
        window.localStorage.setItem('tracking-from', 'sidebar')
        window.localStorage.setItem('tracking-from-object', JSON.stringify({
          fromOrganizationId: this.organizationId,
          fromProjectId: this.projectId
        }))
      } else {
        window.localStorage.setItem('tracking-from', 'services list page')
      }
    },
    projectTooltip (project) {
      if (!this.projectIsReady(project)) return this.$t('header.project_is_not_ready')
      if (this.healthCheckInterval[project?.id]) return this.$t('header.service_installing')
      return ''
    },
    getServiceHealth (serviceObject) {
      const service = serviceObject || this.serviceActive
      let serviceHealth = null

      if (service.id === 'dpe') serviceHealth = this.$api.DPE
      if (service.id === 'dm' || service.id === 'lakehouse' || service.id === 'data-catalog') serviceHealth = this.$api.DM
      if (service.id === 'am') serviceHealth = this.$api.QUERY_ADMIN
      if (service.id === 'mlm') serviceHealth = this.$api.ML
      if (service.id === 'iam') serviceHealth = this.$api.IAM
      if (service.id === 'control-center') serviceHealth = this.$api.CC
      if (service.id === 'storage-engines') serviceHealth = this.$api.STORAGE
      if (service.id === 'api' || service.id === 'app') serviceHealth = this.$api.APPSERVICE
      if (service.id === 'aab') serviceHealth = this.$api.GAB

      return serviceHealth
    }
  }
}
</script>

<style lang="less">
.open-in-project-container {
  .sub-title {
    height: 28px;
    border-top: 1px solid #E4E7EC;
    border-bottom: 1px solid #E4E7EC;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #0089C0;
    text-transform: uppercase;
    margin: auto;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .projects-list-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #3E4550;
    height: 36px;
    box-shadow: inset 0px -1px 0px #F6F9FC;
  }
  .projects-list-search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 8px 20px;
  }
  .service-in-organization {
    width: 100%;
    a.service {
      height: 40px;
      padding: 8px 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #3E4550;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      &:hover {
        background-color: @background-color_aside;
      }
      .icon-container {
        position: relative;
        border-radius: 5px;
        background-color: @white;
        width: 24px;
        height: 100%;
        padding: 2px;
        margin-right: 12px;
        img {
          width: 100%;
        }
      }
    }
  }

  .project-list-container {
    height: 100%;
    max-height: 448px;
    overflow-y: auto;
    .projects-list-list {
      .project {
        height: 40px;
        padding: 8px 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3E4550;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        .current-project{
          color: @grey;
          font-style: italic;
          margin-left: 3px;
        }

        .icon-container {
          height: 24px;
          width: 24px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          border: 1px solid #E4E7EC;
          background: #fff;
          .project-icon {
            font-size: 18px;
            color: white;
          }
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        img.fp-loading.installing {
          position: absolute;
          right: 10px;
          top: 5px;
        }

        .fp4-pin-empty, .fp4-pin-filled {
          display: none;
          font-size: 20px;
          color: #CBD3DB;
          position: absolute;
          top: 10px;
          right: 15px;
          cursor: pointer;

          &:hover {
            color: @blue;
          }
        }

        &.no-value {
          cursor: default;
        }

        &:hover {
          background: #F6F9FC;
          .fp4-pin-empty, .fp4-pin-filled {
            display: block;
          }
        }

        &:last-of-type {
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }

        &.disabled {
          cursor: not-allowed;
          color: #97A7B7 !important;
        }
      }
    }

    .no-elements {
      padding: 0 12px;
      .title-no {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3E4550;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #97A7B7;
      }

      &.project {
        padding: 0 25px 8px 25px;
      }
    }
  }

  .create-project {
    padding: 10px 20px;
    height: 48px;
    border-top: 1px solid #E4E7EC;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #00CCF9;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;

    .button-container {
      width: 100%;
      .fpui-button {
        max-width: 100%;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
