<template lang="pug">
.login-all-in-one-content
  .organization-mode(v-if="organizationMode")
    organization-login
  form.login.all-in-one(
    v-else
    @submit="onSubmit"
  )
    fp-loader(v-if="submitLoading")
    img.logo(:src="logo")

    //- Signup done and email confirmation sent
    .inner-content(v-if="signupDone")
      h1 {{ $t('authentication.confirm_email') }}
      p {{ $t('authentication.confirm_email.desc') }}

    //- Password reset request sent
    .inner-content(v-else-if="forgotSend")
      h1 {{ title }}
      p.message {{ message }}

    .inner-content(v-else)
      h1 {{ title }}
      p.message {{ message }}

      .auth-modes-all-in-one(v-if="signup || !passwordReset")
        .auth-mode-container
          fpui-button.auth-mode.ovh-auth-mode(
            v-if="authModeOvh"
            color="ovh"
            type="button"
            noshadow
            @click="openAuthMode(authModeOvh)"
          )
            img(
              :src="require('@/shared/components/authentication/assets/ovh.png')"
              :title="authModeOvh.name"
            )
            | {{ $t('authentication.oauth_provider.ovh') }}
          fpui-button.auth-mode(
            :key='authMode.type'
            v-if="authMode.type !== 'login_password'"
            v-for="authMode in authModesWithoutOvh"
            :style="authModeStyle(authMode)"
            @click="openAuthMode(authMode)"
            :class="{ 'ovh-auth-mode': authMode.type === 'forepaas' }"
            color='white'
            noshadow
            type="button"
          )
            img.icon(
              :src="authMode.type === 'forepaas' ? require('@/shared/components/authentication/assets/ovh.png') : authMode.icon"
              :title="authMode.name"
            )
            span {{ $t('authentication.oauth_provider.signin_with', [authMode.name]) }}

      .auth-mode-separator(v-if="(signup || !passwordReset) && displayLoginPassword")
        .hr-divider
          span {{ hrDivider }}

      //- Signup
      .signup(v-if="signup && displayLoginPassword")
        fpui-input-text(
          v-model="name"
          name="name"
          :placeholder="$t('authentication.fullname.placeholder')"
          :error="!!name.length && name.split(' ').filter(e => e).length < 2"
          :errorMessage="$t('authentication.fullname.error')"
        )
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.fullname.label') }}

        fpui-input-email(
          v-model="email"
          name="email"
          :placeholder="$t('authentication.email.placeholder')"
          :error="!validateEmail && email !== ''"
          :errorMessage="$t('authentication.email.error')"
        )
          //- est-ce qu'on check si user exist deja ?
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.email.label') }}

        fpui-input-password-strength(
          v-model="password"
          name="password"
          :helper-title="$t('authentication.password.helper.title')"
          :helper="passwordHelper"
          :error="!!isValidPassword.length && !!password.length"
          :error-message="$t('authentication.password.error')"
          :placeholder="$t('authentication.password.placeholder')"
          :class="{ 'error': !isValidPassword }"
        )
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.password.label') }}

        fpui-input-password(
          v-model="repeatPassword"
          name="confirm_password"
          :error="confirmPasswordError"
          :error-message="$t('authentication.confirm_password.error')"
          :placeholder="$t('authentication.confirm_password.placeholder')"
        )
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.confirm_password.label') }}
        .terms(v-if="signup")
          fpui-input-checkbox(v-model="agreedTermsAndConditions")
          .text(v-html="$sanitize($t('authentication.signup_term_disclaimer',[links[0].links[lang], links[1].links[lang],links[2].links[lang],links[3].links[lang]]))")

      //- Password reset
      .password-reset(v-else-if="passwordReset")
        fpui-input-text(
          v-model="email"
          name='email'
          :placeholder='$t("authentication.email.placeholder")'
          :error="!validateEmail && email !== ''"
          :errorMessage="$t('authentication.email.error')"
        )
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.email.label') }}

      //- Login
      .login-form(v-else-if="displayLoginPassword")
        fpui-input-text#input-email(
          v-model="email"
          :placeholder='$t("authentication.email.placeholder")'
          name='email'
          :error="initPage && email === ''"
          :errorMessage="$t('authentication.signup.empty')"
        )
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.email.label') }}

        fpui-input-password#input-password(
          v-model="password"
          autocomplete="current-password"
          name='password'
          :placeholder='$t("authentication.password.placeholder")'
          :error="initPage && password ===''"
          :errorMessage="$t('authentication.signup.empty')"
        )
          template(
            v-slot:label
          )
            label(:style="labelStyle") {{ $t('authentication.password.label') }}

      //- Footer with buttons + links
      .actions.action-login(v-if="displayLoginPassword")
        fpui-button#submit-button-login(
          :customId="!signup && !passwordReset ? 'button-login' : null"
          type='submit'
          color="custom"
          :disabled="!ready"
          :class="{'disabled': !ready, signup }"
          :button-style="buttonStyle"
        )
          span(v-if="signup") {{ $t('authentication.signup') }}
          span(v-else-if="passwordReset") {{ $t('authentication.send') }}
          span(v-else) {{ $t('authentication.signin.login_password') }}

        .links
          a.forgot-password-link(
            @click="passwordReset = true"
            v-if="!passwordReset && !signup"
          ) {{ $t('authentication.forgot_password') }}


      .cancel-link(
        v-if="passwordReset"
        @click="passwordReset = false"
      ) {{ $t('authentication.forgot_password.link.text') }}&nbsp;
        a(
          :style="labelStyle"
        ) {{ $t('authentication.cancel') }}
      .signup-link(
        v-else-if="selfService && !signup && signupEnable && displayLoginPassword"
        @click="signup = true"
      ) {{ $t('authentication.no_account') }}&nbsp;
        a(
          :style="labelStyle"
        ) {{ $t('authentication.signup') }}
      .signup-link(
        v-else-if="selfService && signup && displayLoginPassword"
        @click="signup = false"
      ) {{ $t('authentication.already_have_account') }}&nbsp;
        a(
          :style="labelStyle"
        ) {{ $t('authentication.signin') }}
</template>

<script>
import Config from '@/shared/Config'
import OrganizationLogin from '@/shared/components/authentication/OrganizationLogin'

export default {
  components: { OrganizationLogin },
  filters: {
    check: function (status) {
      if (status) return 'fp4-check-bold'
      return 'fp4-xmark-bold'
    }
  },
  data () {
    return {
      submitLoading: false,
      signupEnable: false,
      passwordReset: false,
      signup: false,
      signupDone: false,
      forgotSend: false,
      phone: false,
      name: '',
      password: '',
      repeatPassword: '',
      email: '',
      initPage: false,
      config: null,
      links: [
        {
          id: 'legal-age',
          links: {
            en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/4789dde-contrat_genServices-IE-7.0.pdf',
            fr: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/2f391d2-contrat_genServices-FR-14.1.pdf'
          }
        },
        {
          id: 'ovhcloud-special',
          links: {
            en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/fd80c12-contrat_partSERVICETEST-IE-1.0.pdf',
            fr: ' https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/8d93763-contrat_partSERVICETEST-FR-1.0.pdf'
          }
        },
        {
          id: 'aab-special',
          links: {
            en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/6c46348-contrat_partAIAPPBUILDER-IE-1.0.pdf',
            fr: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/613c3b2-contrat_partAIAPPBUILDER-FR-1.0.pdf'
          }
        },
        {
          id: 'generative-ai-special',
          links: {
            en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/503ad73-regles_utilisation_IAGEN_OVH-IE-1.0.pdf',
            fr: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/b18adf8-regles_utilisation_IAGEN_OVH-FR-1.0.pdf'
          }
        }
      ],
      agreedTermsAndConditions: false
    }
  },
  computed: {
    selfService () { return this.$store.getters.PREFERENCES.self_service || false },
    color () { return this.$store.getters.PREFERENCES.color || '#0089C0' },
    lang () {
      return this.$route?.query?.lang || this.$i18n.locale()
    },
    buttonStyle () {
      return {
        color: 'white', // TODO - Add a detect for light or dark background
        backgroundColor: this.color
      }
    },
    labelStyle () {
      return {
        color: this.color
      }
    },
    authModes () {
      return this.$store.getters.PREFERENCES.auth_mode.filter(authMode => {
        return authMode.visible && !authMode.hidden
      })
    },
    authModesWithoutOvh () {
      return this.authModes.filter(a => a.name !== 'OVH' && a.provider !== 'openid_ovh')
    },
    authModesWithoutOvhAndLoginPassword () {
      return this.authModes.filter(a => a.name !== 'OVH' && a.provider !== 'openid_ovh' && a.type !== 'login_password')
    },
    authModeOvh () {
      return this.authModes.find(a => a.name === 'OVH' || a.provider === 'openid_ovh')
    },
    displayLoginPassword () {
      return this.authModes.find(am => am.type === 'login_password')
    },
    logo () {
      const defaultLogo = require('@/shared/assets/img/ovhcloud_dataplatform_logo.svg')
      return this.$store.getters.PREFERENCES?.logo || defaultLogo || ''
    },
    title () {
      if (this.forgotSend) return this.$t('authentication.forgot_password_title')
      else if (this.signup) return this.$t('authentication.signup_title')
      else if (this.passwordReset) return this.$t('authentication.password_reset_title')

      // return this.$t('authentication.login_title')
      return this.$store.getters.PREFERENCES?.title || this.$store.getters.PREFERENCES.name
    },
    message () {
      if (this.forgotSend) return this.$t('authentication.password_reset_sent')
      else if (this.signup) return this.$t('authentication.signup_desc')
      else if (this.passwordReset) return this.$t('authentication.password_reset_desc')

      // return this.$t('authentication.login.desc')
      return this.$store.getters.PREFERENCES?.message || this.$store.getters.PREFERENCES.description
    },
    validateEmail () {
      if (/^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(this.email)) {
        return true
      }
      return false
    },
    scoreClass () {
      return 'score-internal-' + this.score
    },
    nbInt () {
      let number = 0
      for (const i in this.password) {
        if (!isNaN(this.password.charAt(i))) number++
      }
      return number
    },
    nbSpecial () {
      let number = 0
      const specialChars = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
      for (const i in this.password) {
        if (specialChars.indexOf(this.password.charAt(i)) !== -1) number++
      }
      return number
    },
    nbCaps () {
      let number = 0
      for (const i in this.password) {
        if (/[A-Z]/.test(this.password.charAt(i))) number++
      }
      return number
    },
    passwordPolicies () {
      return {
        nb_char: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_char),
        nb_int: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_int),
        nb_schar: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_schar),
        nb_cchar: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_cchar)
      }
    },
    errors () {
      if (this.signup) {
        const errors = []
        if (!this.password) errors.push('missing_password')
        if (!this.name) errors.push('missing_name')
        if (this.name.split(' ').filter(e => e).length < 2) errors.push('not_full_name')
        if (!this.email) errors.push('missing_email')
        if (!this.validateEmail) errors.push('wrong_email')
        if (this.password !== this.repeatPassword) errors.push('different_password')
        if (!this.agreedTermsAndConditions) errors.push('no_check_for_terms_and_conditions')
        return errors.concat(this.isValidPassword)
      }

      if (this.passwordReset) {
        const errors = []
        if (!this.email || !this.validateEmail) errors.push('missing_email')
        return errors
      }

      if (!this.signup && !this.passwordReset) {
        const errors = []
        if (!this.email) errors.push('missing_email')
        if (!this.password) errors.push('different_password')
        return errors
      }

      return []
    },
    isValidPassword () {
      const errors = []
      if (this.password.length < this.passwordPolicies.nb_char) errors.push('not_enough_char')
      if (this.nbInt < this.passwordPolicies.nb_int) errors.push('not_enough_number')
      if (this.nbCaps < this.passwordPolicies.nb_cchar) errors.push('not_enough_caps')
      if (this.nbSpecial < this.passwordPolicies.nb_schar) errors.push('not_enough_special_char')
      return errors
    },
    passwordHelper () {
      return `<div>
        <ul class="password-helper-content">
          ${this.passwordPolicies.nb_char ? `<li><i class="fp4 ${this.icon(this.password.length < this.passwordPolicies.nb_char)}"></i><span>${this.$t('authentication.password.helper.nbChar_minimum', [this.passwordPolicies.nb_char], this.passwordPolicies.nb_char)}</span></li>` : ''}
          ${this.passwordPolicies.nb_char ? `<li><i class="fp4 ${this.icon(this.nbCaps < this.passwordPolicies.nb_cchar)}"></i><span>${this.$t('authentication.password.helper.nbUpperCaseChar_minimum', [this.passwordPolicies.nb_cchar], this.passwordPolicies.nb_cchar)}</span></li>` : ''}
          ${this.passwordPolicies.nb_int ? `<li><i class="fp4 ${this.icon(this.nbInt < this.passwordPolicies.nb_int)}"></i><span>${this.$t('authentication.password.helper.nbNumber_minimum', [this.passwordPolicies.nb_int], this.passwordPolicies.nb_int)}</span></li>` : ''}
          ${this.passwordPolicies.nb_schar ? `<li><i class="fp4 ${this.icon(this.nbSpecial < this.passwordPolicies.nb_schar)}"></i><span>${this.$t('authentication.password.helper.nbSpecial_minimum', [this.passwordPolicies.nb_schar], this.passwordPolicies.nb_schar)}</span></li>` : ''}
        </ul>
      </div>`
    },
    passwordError () {
      if (this.password.length === 0) return false
      const passwordRegexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/)
      return !passwordRegexp.test(this.password)
    },
    confirmPasswordError () {
      if (this.repeatPassword.length === 0) return false
      return this.repeatPassword !== this.password
    },
    ready () {
      return !this.errors.length
    },
    organizationMode () {
      // Hack - For IAM where the projectid = organizationid
      return this.config?.PROJECT_ID && this.config?.ORGANIZATION_ID && (this.config?.PROJECT_ID === this.config?.ORGANIZATION_ID)
    },
    projectLogin () {
      return !this.$store.getters.PREFERENCES?.url.startsWith(this.config?.FPUI)
    },
    hrDivider () {
      if (this.signup) return this.$t('authentication.signup.divider.or')
      if (this.projectLogin) return this.$t('authentication.signin_another_method.project')
      return this.$t('authentication.signin_another_method')
    }
  },
  async mounted () {
    this.config = await Config()
    this.signupEnable = this.config.SIGNUP === 'true' || this.config.SIGNUP === true
    if (this.$route.query.signup === 'true') {
      this.signup = true
      this.email = this.$route.query.email || ''
    }
  },
  methods: {
    icon (ruleValue) {
      return !ruleValue ? 'fp4-check-bold' : 'fp4-xmark-bold'
    },
    countCharRegex (reg) {
      let count = 0
      const len = this.password.length
      for (let i = 0; i < len; i++) {
        if (reg.test(this.password.charAt(i))) count++
      }
      return count
    },
    textRule (text, number) {
      if (number > 1) text += 's'
      return this.$t('authentication.password.rules.' + text, [number])
    },
    openAuthMode (authMode) {
      this.submitLoading = true
      this.$api.IAM.loginRedirect(authMode, this.$route.query.force_auth)
    },
    authModeStyle (authMode) {
      return {
        borderColor: authMode.color
      }
    },
    onSubmit ($event) {
      $event.preventDefault()
      if (!this.ready) return
      this.submitLoading = true
      if (this.passwordReset) return this.onPasswordReset()
      if (this.signup) return this.onSignup()
      this.onLogin()
    },
    async onSignup () {
      const { email, password, name } = this
      try {
        await this.$store.dispatch('SIGNUP', {
          email,
          password,
          name,
          tc_accepted: this.agreedTermsAndConditions,
          redirect_uri: window.location.origin,
          tokenJoin: this.$route.query.token_join,
          organizationId: this.$route.query.organization_join
        })
        this.signupDone = true
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      } finally {
        this.submitLoading = false
      }
    },
    async onLogin () {
      this.initPage = true
      const { email, password } = this
      try {
        await this.$store.dispatch('LOGIN', { email, password })
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      } finally {
        this.submitLoading = false
      }
    },
    async onPasswordReset () {
      const { email } = this
      try {
        await this.$store.dispatch('PASSWORD_RESET', { email })
        this.forgotSend = true
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>

<style lang="less">
  .password-helper-content {
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      i.fp4-check-bold {
        color: @green;
      }
      i.fp4-xmark-bold {
        color: @red;
      }
      i {
        margin-right: 15px;
      }
    }
  }

  .authentication .content {
    // .logo {
    //   width: 150px;
    // }

    .login.all-in-one {
      .inner-content {
        // margin-top: 10px;
        // max-width: none;

        // h1 {
        //   margin-bottom: 0;
        //   line-height: 54px;
        //   padding-top: 15px;
        //   font-size: 54px;
        // }

        p {
          margin-top: 10px;
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: 300;
          &.message {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            color: #748294;
          }
        }

        .fpui-input-text, .fpui-input-phone, .fpui-input-select-container {
          margin-top: 21px;
        }
        .fpui-input-label-container {
          text-transform: uppercase;
          color: #0089C0;
          font-weight: 600;
        }

        .fpui-input-password {
          &.error {
            .fpui-helper {
              > i.fp4.text-default {
                color: @red !important;
              }
            }
          }
        }

        input {
          margin: 0;
          max-width: 100%;
        }

        .actions {
          margin-top: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .button-container {
            border-radius: 5px;
            &.signup {
              width: 100%;
              .fpui-button {
                width: 100%;
              }
            }
          }
          .links {
            position: relative;
            a {
              color: #B2BECA;
              cursor: pointer;
            }
            .forgot-password-link:hover {
              text-decoration: underline;
            }
          }
          &.action-login {
            display: block;
            margin-top: 20px;
            .links {
              margin-top: 15px;
            }
            .button-container {
              width: 100%;
              .fpui-button {
                width: 100%;
              }
            }
          }
        }

        .terms {
          margin-top: 20px;
          display: flex;
          align-items: center;
          a {
            color: #B2BECA;
            text-decoration: underline;
          }
          .text {
            margin-top: 5px;
            color: #B2BECA;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            text-transform: none;
          }
          .fpui-input-checkbox{
            margin-right: 0 !important;
          }
        }

        .signup-link, .cancel-link {
          margin-top: 15px;
          color: #B2BECA;
          font-size: 14px;
          text-align: center;
          a {
            color: #2089C0;
            cursor: pointer;
            font-weight: 700;
            margin-left: 5px;
            font-size: 14px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      img.logo {
        max-height: 200px;
        max-width: 200px;
      }
    }
    .auth-modes-all-in-one {

      .button-container {
        width: 230px;
        img {
          width: 20px;
          margin-right: 5px;
        }
        .fpui-button {
          width: 100%;
          color: white !important;
          .btn-content {
            display: flex;
            align-items: center;
          }
        }
      }

      .separator {
        height: 34px;
        width: 1px;
        background: #97A7B7;
        margin-left: 10px;
        margin-right: 10px;
      }
      .auth-mode-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        .auth-mode {
          width: 100%;
          flex: 0 0 auto;
          .btn.fpui-button.v4 {
            height: 44px;
            font-weight: bold;
            .btn-content {
              line-height: 13px;
            }
            img {
              width: 24px;
            }
            span {
              color: #748294;
            }
          }
          &:hover {
            background-color: #F6F9FC;
          }
          span {
            color: #748294;
            font-size: 13px;
            font-weight: 400;
            text-transform: uppercase;
            padding-left: 10px;
          }
          &.ovh-auth-mode {
            .btn.fpui-button.v4 {
              border: 1px solid #0050d7 !important;
              background-color: #0050d7 !important;
              height: 44px;
              font-weight: bold;
              .btn-content {
                line-height: 13px;
              }
              span {
                color: #FFF;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }

  .auth-mode-separator {
    padding: 10px 0;
    margin: 15px 0;
  }

  .hr-divider {
    color: #B2BECA;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    position: relative;
    line-height: 13px;
    display: block;
    &:before {
      content:"";
      height: 1px;
      background: #B2BECA;
      position: absolute;
      left: 0;
      right: 0;
      z-index:1;
      top: 50%;
    }
    span {
      background: white;
      padding: 0 10px;
      position: relative;
      z-index: 2;
    }
  }
</style>
