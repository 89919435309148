<template lang='pug'>
ul.fp-pagination(v-show='!hidePagination')
  li.fp-pagination-item.arrow(
    :class="{ 'hide-item': isInFirstPage }"
  )
    button(
      @click="onClickPreviousPage"
      :disabled="isInFirstPage"
    )
      i.fp4.fp4-angle-left

  li.fp-pagination-item(
    v-for="page in pages"
  )
    button(
      @click="changePage(page)"
      :disabled='isPageActive(page)'
      :class="{ active: isPageActive(page) }"
    ) {{ page }}

  li.fp-pagination-item.arrow(
    :class="{ 'hide-item': isInLastPage }"
  )
    button(
      @click="onClickNextPage"
      :disabled="isInLastPage"
    )
      i.fp4.fp4-angle-right
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    maxVisibleButtons: {
      type: Number,
      default: 3
    },
    perPage: {
      type: Number,
      default: 5
    },
    hideEmpty: {
      type: Boolean,
      default: true
    },
    hideOnePage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    pages () {
      if (this.items.length > this.perPage) return Math.ceil(this.items.length / this.perPage)
      return 1
    },
    startPage () {
      // TODO handle the number of pages to show with maxVisibleButtons
      return 1
    },
    endPage () {
      return Math.min(this.startPage + this.maxVisibleButtons, this.pages)
    },
    isInFirstPage () {
      return this.currentPage === 1
    },
    isInLastPage () {
      return this.currentPage === this.pages
    },
    hidePagination () {
      if (!this.hideEmpty) return false
      if (!this.hideOnePage && this.items.length === 1) return false
      return this.items.length <= this.perPage
    }
  },
  mounted () {
    this.changePage(1)
  },
  methods: {
    changePage (page) {
      let offset = page === 1 ? this.perPage : (page - 1) * this.perPage
      if (page === this.startPage) offset = 0

      // TODO: check if limit outbounds items
      const limit = offset + this.perPage
      this.$emit('page-changed', {
        currentPage: page,
        offset: offset,
        limit: limit,
        items: this.items.slice(offset, limit)
      })
      this.currentPage = page
    },
    onClickPreviousPage () {
      this.changePage(this.currentPage - 1)
    },
    onClickNextPage () {
      this.changePage(this.currentPage + 1)
    },
    isPageActive (page) {
      return this.currentPage === page
    }
  }
}
</script>

<style lang="less">
ul.fp-pagination {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 10px 0 0 0;
  li.fp-pagination-item {
    transition: opacity 0.2s;
    button {
      background: none;
      color: inherit;
      border: none;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      padding: 0;

      border-radius: 4px;
      color: #3E4550;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background: #0089C0;
        color: white;
      }
    }
    &.arrow {
      border-radius: 4px;
      border: 1px solid #CBD3DB;
      i {
        color: #CBD3DB;
        font-size: 15px;
      }
    }
    &.hide-item {
      opacity: 0;
    }
  }
}
</style>
