// WARNING it is used pretty much every where on orga home, project home, side bar and header
// Update with caution

export default [
  {
    category: 'analytics',
    color: '#00CCF9',
    services: [
      {
        id: 'dm',
        serviceId: 'dm',
        image: require('@/core/components/Dataplant/assets/dm.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/dm_grey.svg'),
        displayName: 'services.display_name.dm',
        smallDescription: 'services.small_description.dm',
        longDescription: 'services.long_description.dm',
        documentation: 'https://docs.forepaas.io/#/en/product/data-manager/index',
        hidden: true
      },
      {
        id: 'data-catalog',
        serviceId: 'data-catalog',
        image: require('@/core/components/Dataplant/assets/data-catalog.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/data-catalog_grey.svg'),
        displayName: 'services.display_name.data-catalog',
        smallDescription: 'services.small_description.data-catalog',
        longDescription: 'services.long_description.data-catalog',
        documentation: 'https://docs.forepaas.io/#/en/product/data-catalog/index'
      },
      {
        id: 'lakehouse',
        serviceId: 'lakehouse',
        image: require('@/core/components/Dataplant/assets/lakehouse.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/lakehouse_grey.svg'),
        displayName: 'services.display_name.lakehouse',
        smallDescription: 'services.small_description.lakehouse',
        longDescription: 'services.long_description.lakehouse',
        documentation: 'https://docs.forepaas.io/#/en/product/lakehouse-manager/index'
      },
      {
        id: 'dpe',
        serviceId: 'dpe',
        image: require('@/core/components/Dataplant/assets/dpe.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/dpe_grey.svg'),
        displayName: 'services.display_name.dpe',
        smallDescription: 'services.small_description.dpe',
        longDescription: 'services.long_description.dpe',
        documentation: 'https://docs.forepaas.io/#/en/product/dpe/index'
      },
      {
        id: 'am',
        serviceId: 'am',
        image: require('@/core/components/Dataplant/assets/am.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/am_grey.svg'),
        displayName: 'services.display_name.am',
        smallDescription: 'services.small_description.am',
        longDescription: 'services.long_description.am',
        documentation: 'https://docs.forepaas.io/#/en/product/am/index'
      }
    ]
  }, {
    category: 'artificial-intelligence',
    color: '#9EDF10',
    services: [
      {
        id: 'mlm',
        serviceId: 'mlm',
        image: require('@/core/components/Dataplant/assets/ml.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/ml_grey.svg'),
        displayName: 'services.display_name.mlm',
        smallDescription: 'services.small_description.mlm',
        longDescription: 'services.long_description.mlm',
        documentation: 'https://docs.forepaas.io/#/en/product/ml/index',
        dataplatform: false
      },
      {
        id: 'aab',
        serviceId: 'gab',
        image: require('@/core/components/Dataplant/assets/gab.svg'),
        displayName: 'services.display_name.gab',
        smallDescription: 'services.small_description.gab',
        longDescription: 'services.long_description.gab',
        documentation: 'https://docs.forepaas.io/#/en/product/gab/index',
        dataplatform: true,
        hidden: true
      },
      {
        id: 'notebooks',
        serviceId: 'notebooks',
        image: require('@/core/components/Dataplant/assets/ml.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/ml_grey.svg'),
        displayName: 'services.display_name.notebooks',
        smallDescription: 'services.small_description.notebooks',
        longDescription: 'services.long_description.notebooks',
        documentation: 'https://docs.forepaas.io/#/en/product/ml/index',
        hidden: true
      }
    ]
  }, {
    category: 'application-services',
    color: '#FFCD2F',
    services: [
      {
        id: 'app',
        serviceId: 'appservice',
        image: require('@/core/components/Dataplant/assets/app.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/app_grey.svg'),
        displayName: 'services.display_name.app',
        smallDescription: 'services.small_description.app',
        longDescription: 'services.long_description.app',
        documentation: 'https://docs.forepaas.io/#/en/product/app-manager/index'
      },
      {
        id: 'api',
        serviceId: 'appservice',
        image: require('@/core/components/Dataplant/assets/api.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/api_grey.svg'),
        displayName: 'services.display_name.api',
        smallDescription: 'services.small_description.api',
        longDescription: 'services.long_description.api',
        documentation: 'https://docs.forepaas.io/#/en/product/api-manager/index'
      },
      {
        id: 'functions',
        serviceId: 'functions',
        image: require('@/core/components/Dataplant/assets/app.svg'),
        displayName: 'services.display_name.functions',
        smallDescription: 'services.small_description.functions',
        longDescription: 'services.long_description.functions',
        documentation: '',
        hidden: true
      },
      {
        id: 'workflow-manager',
        serviceId: 'workflow-manager',
        image: require('@/core/components/Dataplant/assets/api.svg'),
        displayName: 'services.display_name.workflow_manager',
        smallDescription: 'services.small_description.workflow_manager',
        longDescription: 'services.long_description.workflow_manager',
        documentation: '',
        hidden: true
      }
    ]
  }, {
    category: 'admin-tools',
    color: '#F62172',
    services: [
      {
        id: 'billing-explorer',
        serviceId: 'billing-explorer',
        image: require('@/core/components/Dataplant/assets/plan.svg'),
        displayName: 'services.display_name.billing_explorer',
        smallDescription: 'services.small_description.billing_explorer',
        longDescription: 'services.long_description.billing_explorer',
        documentation: 'https://docs.forepaas.io/#/en/product/billing/account/index',
        fromOrganization: true
      },
      {
        id: 'storage-engines',
        serviceId: 'storage-engines',
        image: require('@/core/components/Dataplant/assets/storage-engines.svg'),
        displayName: 'services.display_name.storage_engines',
        smallDescription: 'services.small_description.storage_engines',
        longDescription: 'services.long_description.storage_engines',
        documentation: 'https://docs.forepaas.io/#/en/product/dataplant/storage-engine/index',
        fromOrganization: true
      },
      {
        id: 'control-center',
        serviceId: 'control-center',
        image: require('@/core/components/Dataplant/assets/cc.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/cc_grey.svg'),
        displayName: 'services.display_name.control-center',
        smallDescription: 'services.small_description.control_center',
        longDescription: 'services.long_description.control_center',
        documentation: 'https://docs.forepaas.io/#/en/product/cc/index'
      },
      {
        id: 'iam',
        serviceId: 'iam',
        image: require('@/core/components/Dataplant/assets/iam.svg'),
        imageDisabled: require('@/core/components/Dataplant/assets/iam_grey.svg'),
        displayName: 'services.display_name.iam',
        smallDescription: 'services.small_description.iam',
        longDescription: 'services.long_description.iam',
        documentation: 'https://docs.forepaas.io/#/en/product/iam/index'
      }
    ]
  }
]
