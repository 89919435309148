<template lang='pug'>
.modal-footer-container
  .custom-top(
    v-if="$scopedSlots.custom"
  )
    slot(
      name="custom"
    )
  .modal-footer
    .left-footer
      slot
    .footer-actions
      //- HACK: $parent.$parent is not ideal, let's find another solution
      fpui-button(
        v-for='(button, idx) in buttons'
        v-if='buttonConditional(button.if)'
        :key='idx'
        :color='button.color ? button.color : "white"'
        :icon="button.icon"
        :icon-left="button.iconLeft"
        :disabled='buttonConditional(button.disabled, false)'
        :auto-width="button.autoWidth"
        :unbordered="button.unbordered"
        :reverse="button.reverse"
        :noshadow="button.noshadow"
        v-tooltip="button.tooltip"
        :customId="button.id"
        @click='button.handler ? button.handler.bind($parent.$parent)() : ""'
        :button-style="button.buttonStyle"
      ) {{ button.label || button.text }}
</template>

<script>

export default {
  props: {
    buttons: { type: Array, default: () => [] }
  },
  methods: {
    buttonConditional (parameter, fallback = true) {
      if (parameter !== undefined) {
        if (typeof parameter === 'function') return parameter()
        return parameter
      } else if (parameter === undefined) return fallback
      return parameter
    }
  }
}
</script>

<style lang='less'>
.modal-footer-container {
  border-top: 1px solid rgba(151,167,183,0.21);
  background: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .custom-top {
    margin: 10px 29px 0;
  }

  .modal-footer {
    padding: 14px 29px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    .left-footer {
      flex: 0 1 auto;
      align-self: auto;
    }

    .footer-actions {
      display: flex;
      flex: 0 1 100%;
      align-self: auto;
      justify-content: flex-end;
      .button-container:not(:first-child) {
        margin-left: 13px;
      }
    }
  }
}
</style>
