import { API } from '@/shared/plugins/Api/API'

class Authentications extends API {
  constructor (ID) {
    super(ID, true)
  }

  async list (options) {
    const pref = await this.request({
      method: 'GET',
      url: 'v1/applications/preferences?app_id=hq',
      ...options
    })
    return pref.auth_mode
  }

  async revoke (id) {
    await this.request({
      method: 'DELETE',
      url: `v4/users/authentication/${id}`
    })
  }
}

export default Authentications
