<template lang='pug'>
.fpui-steps
  .fpui-steps-header(v-if="headers")
    .fpui-steps-head(
      v-for="(step, idx) in steps"
      :class="{ active: isActive(step), disabled: isDisabled(step) }"
      v-if="step.componentInstance"
    )
      span.text(@click="goTo(step)") {{ step.componentInstance.header }}
      span.divider(v-if="idx !== steps.length-1")
        i.fp4.fp4-chevron-right-light
  .fpui-steps-content
    fp-loader(v-if="loading")
    slot
  .fpui-steps-footer(
    v-if="!$scopedSlots.footer"
    :class='{ "with-stepper-progression": stepperProgression }'
  )
    .stepper-progression__step-progression(v-if="stepperProgression")
      .step-active {{ indexOf(activeStep) + 1 }}
      .step-total /{{ steps.length }}
    .stepper-progression__step-description(v-if="stepperProgression")
      span {{ descriptionStep }}
    .actions
      fpui-button.previous(
        color='white'
        type="button"
        v-if="!previousStep && cancel"
        @click='cancel()'
      ) {{ 'button.cancel' | translate }}
      fpui-button.previous(
        customId="button-modal-previous"
        color='white'
        :disabled="isDisabled(previousStep)"
        type="button"
        v-if="previousStep"
        @click='previous()'
      ) {{ 'button.previous' | translate }}
      fpui-button.next(
        customId="button-modal-next"
        type="button"
        :color="nextColor"
        :disabled="isDisabled(nextStep) || !canProceed()"
        v-if="nextStep&&!disabledNext"
        v-tooltip="{ content: buttonTooltip('Next'), delay: 0 }"
        :auto-width="!!nextButtonText"
        @click='next()'
      ) {{ nextButtonText? nextButtonText : $t('button.next')  }}
      fpui-button.send(
        customId="button-create-table"
        :disabled="!canProceed()"
        :color='completeColor'
        type="button"
        @click="$emit('complete')"
        v-if="!nextStep&&!disabledNext"
      ) {{ $t(confirmButtonText) }}
    .stepper(v-if="stepper && !stepperProgression")
      .step(
        @click="goTo(step, indexOf(step) > indexOf(activeStep) ? 1 : -1)"
        :class="{ active: isActive(step), disabled: isDisabled(step) }"
        v-if="!step.isComment"
        v-for="step in steps"
      )
  .fpui-steps-footer-custom(
    v-else
  )
    slot(name="footer")
</template>

<script>
export default {
  props: {
    disabledNext: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Function,
      default: null
    },
    nextColor: {
      type: String,
      default: 'blue'
    },
    completeColor: {
      type: String,
      default: 'green'
    },
    // If set to false, the "confirm button will be disabled"
    validate: {
      type: Boolean,
      default: true
    },
    // If enable, it will display a header bar, every step will need a :header field (String)
    headers: {
      type: Boolean,
      default: false
    },
    // If enabled, it will display a footer dots bar
    stepper: {
      type: Boolean,
      default: true
    },
    // If enabled, it will take over the original stepper and display
    // steps progression (i.e.: 1/3), with a description if any
    stepperProgression: {
      type: Boolean,
      default: false
    },
    nextButtonText: {
      type: String,
      default: ''
    },
    displayFooter: {
      type: Boolean,
      default: true
    },
    confirmButtonText: {
      type: String,
      default: 'button.confirm'
    }
  },
  data () {
    return {
      loading: false,
      active: null, // That internal object is use to store the active step object, it will be use for check activity
      steps: [] // That internal object is updated at every mount unmount of substep -> DO NOT MOVE IT TO COMPUTED, VueJs doesnt self update the slots on mount/unmount
    }
  },
  computed: {
    /*
      Access to previous step
      It will ignore a step if v-if set to false
      It will return null, if the first step is the active step
    */
    previousStep () {
      let idx = this.index
      while (idx > 0) {
        idx--
        if (!this.steps[idx].isComment) return this.steps[idx]
      }
      return null
    },
    /*
      Access to next step
      It will ignore a step if v-if set to false
      It will return null, if the last step is the active step
    */
    nextStep () {
      let idx = this.index
      while (idx < this.steps.length - 1) {
        idx++
        if (!this.steps[idx].isComment) return this.steps[idx]
      }
      return null
    },
    /*
      Will return the index of the current step
    */
    index () {
      const index = this.steps.findIndex(s => s.componentInstance?._uid === this.active)
      this.$emit('activeStep', index)
      return index
    },
    /*
      Will return the current step
    */
    activeStep () {
      return this.steps.find(s => s.componentInstance?._uid === this.active)
    },
    descriptionStep () {
      if (this.steps.length && this.steps[this.index]) {
        const currentStep = this.steps[this.index]
        return currentStep.data?.attrs?.description || ''
      }
      return ''
    }
  },
  // Perform the first slot update, then set first step as active
  mounted () {
    this.updateSlots()
    this.goTo(this.steps[0])
  },
  methods: {
    /*
      Hack for perform vuejs watch on slots
      They are copy on a data property, it will be call on mounted, and on child event (mounted/destroyed)
    */
    updateSlots () {
      this.steps = this.$slots?.default
    },
    // Trigger an event, set as a parameter in the step (only if set)
    // Available event : onLeave, onNext, onPrevious
    async trigger (evt) {
      if (this.activeStep?.componentInstance?.[evt]) await this.activeStep.componentInstance[evt]()
    },
    // Accessor to check if a step is disabled
    isDisabled (step) {
      if (!step.componentInstance) return true
      if (typeof step.componentInstance.disabled === 'function') return step.componentInstance.disabled()
      return step.componentInstance.disabledbuttonTooltip
    },
    buttonTooltip (type) {
      if (!this.activeStep) return false
      return this.activeStep.componentInstance[`tooltip${type}`] || false
    },
    // Accessor to check if user can proceed to the next step
    canProceed () {
      if (!this.activeStep) return true
      if (typeof this.activeStep.componentInstance.validate === 'function') return this.activeStep.componentInstance.validate()
      return this.activeStep.componentInstance.validate
    },
    // Accestor to check if a step is active
    isActive (step) {
      if (!step) return false
      if (!this.active) return false
      return this.active === step.componentInstance._uid
    },

    /*
      goTo a specifc step
      @step : The step internal object
      @move : If positive is a forward move, negative -> backward
    */
    async goTo (step, move) {
      // The disabled is not disabled if we dont wait a loop
      process.nextTick(async () => {
        if (this.isDisabled(step)) return
        if (!this.canProceed() && move > 0) return
        this.loading = true
        try {
          if (this.active) {
            await this.trigger('onLeave')
            if (move > 0) await this.trigger('onNext')
            if (move < 0) await this.trigger('onPrevious')
          }
        } catch (err) {
          this.loading = false
          throw err
        }
        this.loading = false
        this.active = step.componentInstance._uid
      })
    },
    // Shortcuts
    indexOf (step) {
      return this.steps.indexOf(step)
    },
    next () { this.goTo(this.nextStep, 1) },
    previous () { this.goTo(this.previousStep, -1) }
  }
}
</script>

<style lang='less'>
  .fpui-steps {
    .fpui-steps-header {
      display: flex;
      border-bottom: 1px solid rgba(151, 167, 183, 0.21);
      .fpui-steps-head {
        margin-bottom:-3px;
        span.text {
          color: #333;
          font-size: 20px;
          line-height: 30px;
          text-transform: uppercase;
          margin-right: 0px;
          position: relative;
          padding: 0 10px;
          cursor: pointer;
          border-bottom: transparent 1px solid;
        }
        &.active, &:hover {
          span.text {
            border-color: #0089c0;
            color: #0089c0;
          }
        }
        span.divider {
          font-size: 16px;
        }
      }
    }
    > .fpui-steps-footer {
      &.with-stepper-progression {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: stretch;
        align-items: center;
        justify-content: center;
        .stepper-progression__step-progression {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-content: stretch;
          align-items: flex-end;
          margin-right: 43px;
          .step-active, .step-total {
            color: #3E4550;
            font-family: Arial;
          }
          .step-active {
            font-size: 32px;
            font-weight: 600;
          }
          .step-total {
            font-size: 22px;
            font-weight: 200;
            line-height: 32px;
          }
        }
        .stepper-progression__step-description {
          width: 410px;
          color: #B2BECA;
          font-size: 11px;
          line-height: 14px;
        }
        .actions {
          > .button-container {
            height: 100%;
            > button {
              height: 46px;
            }
          }
        }
      }
      .actions {
        display:flex;
        > * {
          margin: 0 10px 0 0;
        }
      }
      .stepper {
        display:flex;
        margin: 20px 0;
        .step {
          background: #E4E8EC;
          width: 7px;
          height: 7px;
          margin-right: 10px;
          border-radius: 100%;
          cursor:pointer;
          &.disabled {
            cursor: not-allowed;
          }
          &.active {
            background: #97A7B7;
          }
        }
      }
    }
  }
</style>
