<template lang="pug">
  .fpui-canvas-icon(
    :style="{ height: `${finalHeight}px`, width: `${finalWidth}px`}"
    :class="`type-${type}`"
    :id="`button-resource-tile-${name}`"
  )
    .img-mode(
      v-if="(logo && !imageError) || (!logo && (!name || name === ''))"
      :style="{ height: `${finalHeight}px`, width: `${finalWidth}px` }"
    )
      img(
        :src="logo || defaultImage"
        @error='onImageError'
        :style="{ height: `${finalHeight - 2}px`, width: `${finalWidth - 2}px` }"
      )
    .canvas-mode(v-else)
      fpui-canvas-mode.icon(
        :key="key"
        :name="name",
        :size="size"
        :fontSize="fontSize",
        :color="color",
        :backgroundColor="backgroundColor",
        :letterNumber="letterNumber",
        :logo="logo",
        :paddingTop="paddingTop",
        :edit="edit",
        :imageError="imageError"
      )
    .img-change(v-if="edit && !disabled" :key="id")
      input(@change='onChange' type="file" accept="image/*" :id="id")
      label(:for="id")
        i.fp4.fp4-pencil-small
    .img-remove(v-if="remove && !disabled && logo")
      i.fp4.fp4-xmark(@click='removeIcon')
</template>

<script>
import FpuiCanvasMode from './FpuiCanvasMode'
import _uniqueId from 'lodash/uniqueId'

export default {
  components: { FpuiCanvasMode },
  props: {
    type: { type: String, default: 'square' },
    name: { type: String, default: '' },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    size: { type: Number, default: 16 },
    fontSize: { type: String, default: '' },
    color: { type: String, default: 'black' },
    backgroundColor: { type: String, default: 'lightblue' },
    letterNumber: { type: Number, default: 2 },
    logo: { type: String, default: '' },
    paddingTop: { type: Number, default: 5 },
    edit: { type: Boolean, defaut: false },
    remove: { type: Boolean, defaut: false },
    disabled: { type: Boolean, default: false },
    defaultImage: { type: String, default: require('./default.png') }
  },
  data () {
    return {
      key: 1,
      imageError: false,
      finalHeight: this.height || this.size,
      finalWidth: this.width || this.size,
      id: null
    }
  },
  watch: {
    name () {
      this.key++
    },
    backgroundColor () {
      this.key++
    }
  },
  created () {
    this.id = _uniqueId('fpui-canvas-icon-')
  },
  methods: {
    onChange () {
      const reader = new FileReader()
      reader.onloadend = async (e) => {
        this.$emit('input', e.target.result)
      }
      if (event.target.files[0]) reader.readAsDataURL(event.target.files[0])
    },
    onImageError (evt) {
      if (evt.type === 'error') {
        this.imageError = true
        return true
      }
      return false
    },
    removeIcon () {
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="less">
.fpui-canvas-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .icon-mode {
    top: 0;
    left: 0;
    position: relative;
    border-radius: 6px;
  }
  .img-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      user-drag: none;
      user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
    }
  }
  .canvas-mode {
    width: 100%;
    height:100%;
    line-height: 0;
    canvas {
      border-radius: 5px;
    }
  }
  .img-change {
    position: absolute;
    transition: all 300ms ease-in-out;
    top: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background-color: rgba(62, 69, 80, 0.78);
    color: #fff;
    display: none;
    border-radius: 3px;
    i {
      position: absolute;
      right: 10px;
      top: 8px;
      cursor: pointer;
      font-size: 25px;
    }
    input {
      width: 25px;
      position: absolute;
      right: 10px;
      top: 8px;
      z-index: 1;
      display: none;
    }
  }
  .img-remove {
    position: absolute;
    transition: all 300ms ease-in-out;
    top: 100%;
    height: 100%;
    left: 0;
    right: 0;
    color: #fff;
    display: none;
    border-radius: 3px;
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      font-size: 20px;
    }
  }
  &:hover {
    .img-change {
      top: 0px;
      display: block;
    }
    .img-remove {
      top: 0px;
      right: 30px;
      display: block;
    }
  }
  &.type-circle {
    canvas , img {
      border-radius: 100%;
    }
  }
  &.type-square {
    border-radius: 3px;
    canvas, img {
      border-radius: 3px;
    }
  }
}

</style>
