import Store from '@/shared/store'
import _get from 'lodash/get'
import Vue from 'vue'
import AclManager from './AclManager'

Vue.use({
  install (Vue) {
    Vue.acl = Vue.prototype.$acl = (organizationId = null, forced_session = null) => {
      const session = forced_session || _get(Store, 'getters.SESSION')
      if (organizationId) {
        // KING CASE
        // When we're in a service, we have the IAM session instead of the CMP
        if (organizationId === true) {
          const organizationAccess = _get(Store, 'getters.KING_ORGANIZATION_ACCESS')
          const acl = organizationAccess === '*' ? '*' : { core: organizationAccess }
          return new AclManager(acl)
        }

        if (session?.roles?.length) {
          const acl = session.acls === '*' ? '*' : { core: session.roles || [] }
          return new AclManager(acl)
        }
        return new AclManager()
      } else {
        // IAM CASE
        const isAdmin = _get(session, 'isAdmin')
        let acl = _get(session, 'acls')
        if (isAdmin) acl = '*'
        return new AclManager(acl)
      }
    }
  }
})
