import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import { uid } from 'uid'

class Dashboard extends APIObject {
  constructor (options) {
    // Init
    super('QUERY_ADMIN', options)

    // Default variables
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.tiles = (this.tiles || []).map(t => {
      return {
        ...t,
        query: t.query || '',
        title: t.title || '',
        i: uid(),
        height: t.height < 10 ? t.height * 230 : t.height
      }
    }) || []
    this.filter = this.filter || {}
    this.triggers = this.triggers || []
    this.story = this.story || {}
    this.override_parameters = this.override_parameters || {}
    this.service_account = this.service_account || ''
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new Dashboard(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'shared_id',
      'name'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v3/dashboards'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v3/dashboards/${this.shared_id}`,
      params: {
        repository: this.repository,
        repository_version: this.repository_version
      }
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v3/dashboards/${this.shared_id}`,
      params: {
        repository: this.repository,
        repository_version: this.repository_version
      }
    })
  }

  async listViewers () {
    const dashboardViewers = await this.request({
      method: 'GET',
      url: `v3/dashboards/${this.shared_id}/viewers`
    })
    return dashboardViewers
  }

  /**
  * Create a dashboardViewer
  *
  * @param   String  type             The type of id. It should be 'user' or 'group'.
  * @param   String  id               Either userId or groupId
  * @return {Object} Request result message
  */
  async createViewer (type, id) {
    return await this.request({
      method: 'POST',
      url: `v3/dashboards/${this.shared_id}/viewers`,
      data: {
        type,
        id
      }
    })
  }

  async removeViewer (viewerId) {
    return this.request({
      method: 'DELETE',
      url: `v3/dashboards/${this.shared_id}/viewers/${viewerId}`
    })
  }
}

class Dashboards extends API {
  async list () {
    const dashboards = await this.request({
      method: 'get',
      url: 'v3/dashboards'
    })

    return dashboards.map(dashboard => {
      try {
        return new Dashboard(dashboard)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)
  }

  new (queryString) {
    const item = {}
    const parameters = Object.assign({}, queryString)
    const queryKeys = Object.keys(parameters)
    if (!queryKeys.includes('repository') && !queryKeys.includes('repository_version')) {
      parameters.repository = Store.getters.QB_REPOSITORY_ACTIVE?._id
      parameters.repository_version = Store.getters.QB_REPOSITORY_ACTIVE?.editingVersion?._id
    }
    for (const key in parameters) {
      _set(item, key, parameters[key])
    }
    return new Dashboard(item)
  }
}

export default Dashboards
export {
  Dashboard,
  Dashboards
}
