<template lang='pug'>
.fp-header
  .preload-font
    i.fa.fa-spinner

  .left
    i.fp4.fp4-burger(
      v-if='!lightHeader'
      @click="openSidebar"
    )
    organization-project-selector(
      :key="'organization'"
      type="organization"
      :hover-from-parent="hoverSelector"
      :light-mode='lightHeader'
      @hover="hover"
    )
    organization-project-selector(
      v-if="fromDataplant && !lightHeader"
      :key="'dataplant'"
      type="dataplant"
      from-service
      :hover-from-parent="hoverSelector"
      :current-service='service'
      @hover="hover"
    )
    service-selector(
      v-if="dataplantName"
      type="service"
      :service="service"
      :hover-from-parent="hoverSelector"
      :light-mode='lightHeader'
      @hover="hover"
    )
    .organization-service-title(
      v-if="isOrganizationService"
      :style="{ left: 0, height: '26px' }"
    )
      .name-container
        span.name {{ organizationServiceName }}
    .flag
      span.beta-flag(v-if="beta") BETA
      span.beta-flag(v-if="beta4") V4 BETA
      span.alpha-flag(v-if="alpha") ALPHA
      span.alpha-flag(v-if="alpha4") V4 ALPHA

  .right
    .actions
      button.button.button-lang
        .current {{ lang }}
        ul.dropdown
          li.lang(:class="{active:isActiveLang(l)}" @click="setLang(l)" v-for="l in locales") {{ l | translate }}
      button.button.button-profile(v-if='!lightHeader')
        i.fp4.fp4-gear
        ul.dropdown(v-if="links.organization")
          li.organization.organization-name
            a.no-flex(:href="links.organization")
              .organization-container
                .organization-name {{ config.ORGANIZATION_NAME }}
                .dataplants-length(v-if="dataplantsList && dataplantsList.length") {{ dataplantsList.length }} {{ $t('header.project', [dataplantsByOrganizationId.length], dataplantsByOrganizationId.length) }}
                .dataplants-length(v-else) {{ $t('header.your_current_organization') }}
              .flex
                span {{ $t('header.my_organization') }}
          li.organization
            a(:href="`${links.organization}/plan`")
              span {{ $t('header.my_organization_billing') }}
          li.control-center(v-if="config && config.ORGANIZATION_ID")
            a(:href="`cc/#/${config.ORGANIZATION_ID}`")
              span {{ $t('header.control_center') }}
      button.button.button-help
        i.fp4.fp4-circle-info-small
        ul.dropdown
          li.docs
            a(:href="links.docs" target="blank" v-if="links.docs")
              i.fp4.fp4-book
              span {{ $t('header.docs') }}
          li.status(v-if="!isDataPlatform")
            a(:href="links.status" target="blank" v-if="links.status")
              i.fp4.fp4-heart-pulse
              span {{ $t('header.status') }}
          li.changelogs
            a(href="https://docs.forepaas.io/#/en/changelog" target="blank")
              i.fp4.fp4-arrows-rotate
              span {{ $t('header.changelog') }}
          li.roadmap(v-if="!isDataPlatform")
            a(href="#/features" target="blank")
              i.fp4.fp4-rocket
              span {{ $t('header.roadmap') }}
          li.support-link(v-if="config && config.ORGANIZATION_ID")
            a(:href="supportLink" target="blank")
              i.fp4.fp4-life-ring
              span {{ $t('header.support') }}
          li.discord-link
            a(:href="ovhDiscordUrl" target="blank")
              img(:src="require('@/shared/assets/img/discord.svg')")
              span {{ $t('header.discord') }}
      button.button.button-user
        img.profile-picture(
          :src="picture"
        )
        ul.dropdown
          li.profile-container(v-if='!lightHeader')
            a(v-if="links.profile" :href="links.profile")
              .profile-info(v-if="user.firstname || user.lastname")
                img.profile-picture(:src="picture")
                span {{ user.firstname }} {{ user.lastname }}
              .profile-info(v-else)
                i.fp4.fp4-circle-user
                span {{ $t('header.my_profile') }}
          li.account(v-if='!lightHeader')
            a(v-if="links.account" :href="links.account")
              span {{ $t('header.my_account') }}
          li.logout
            span(@click="logout") {{ $t('header.logout') }}
</template>

<script>
import moment from 'moment'
import Config from '@/shared/Config'
import OrganizationProjectSelector from '@/shared/components/app/Header/OrganizationProjectSelector.vue'
import ServiceSelector from './ServiceSelector.vue'
import { ovhSupportUrl, ovhDiscordUrl } from '@/shared/store/constants/supportUrl'

export default {
  components: {
    ServiceSelector,
    OrganizationProjectSelector
  },
  props: {
    service: { type: String, default: null },
    info: { type: Object, default: () => ({}) },
    image: { type: String, default: null },
    icon: { type: String, default: null },
    beta: { type: Boolean, default: false },
    beta4: { type: Boolean, default: false },
    alpha: { type: Boolean, default: false },
    alpha4: { type: Boolean, default: false },
    storageEngine: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null,
      picture: require('@/core/assets/img/default_avatar.png'),
      dataplantsList: null,
      showHeaderList: false,
      hoverSelector: null,
      ovhDiscordUrl: ovhDiscordUrl
    }
  },
  computed: {
    // Checks if the header should be in a light mode or not
    // If the user is logged in as ≠ 'forepaas' (king)
    // we only display the logo, current service and service selector
    lightHeader () {
      if (['cc', 'se', 'iam'].includes(this.service)) return false
      return !this.$store.getters.AUTHENTICATION_WITH_KING
    },
    isDataPlatform () {
      return this.config?.DATA_PLATFORM_ACCESS
    },
    user () {
      return this.$store.getters.KING_SESSION || this.$store.getters.SESSION
    },
    dataplantName () {
      return this.config?.PROJECT_NAME || this.info?.dataplantName
    },
    dataplantMode () {
      return this.config?.DATAPLANT_MODE || false
    },
    locales () { return this.$i18n.locales() },
    lang () { return this.$i18n.locale().toUpperCase() },
    links () {
      return {
        docs: this.config?.DOCS,
        status: this.config?.STATUS,
        support: this.config?.SUPPORT,
        profile: this.config?.FPUI ? `${this.config.FPUI}/#/user` : null,
        account: this.config?.FPUI ? `${this.config.FPUI}/#/accounts` : null,
        organization: this.config?.FPUI ? `${this.config.FPUI}/#/organization/${this.config.ORGANIZATION_ID}` : null,
        features: this.config?.FPUI ? `${this.config.FPUI}/#/features` : null,
        return: this.returnUrl
      }
    },
    returnUrl () {
      if (!this.config?.PROJECT_ID) return null
      return `${window.location.origin}/#/project/${this.config?.PROJECT_ID}`
    },
    returnToOverview () {
      if (!this.config?.PROJECT_ID || !this.dataplantName) {
        if (this.service === 'cc') return `${window.location.origin}/${this.service}/#/${this.config?.ORGANIZATION_ID}`
        return window.location.origin
      }
      return `${window.location.origin}/${this.service}/#/${this.config?.PROJECT_ID}`
    },
    fromDataplant () {
      return !!this.$route.params.dataplantId || !!this.$route.params.projectId
    },
    supportLink () {
      return this.config?.DATA_PLATFORM_ACCESS ? ovhSupportUrl : `#/support/${this.config.ORGANIZATION_ID}`
    },
    isOrganizationService () {
      // As the organization IAM is the same ORGAID & PROJECTID (see Config.js), we need that not-so-pretty
      // conditional to check if we are currently on a organization-level service
      if (this.config?.ORGANIZATION_ID !== this.config?.PROJECT_ID && this.service === 'iam') return false
      if (this.config?.ORGANIZATION_ID && this.config?.PROJECT_ID && this.service !== 'iam') return false
      const services = ['iam', 'cc', 'se']
      return services.includes(this.service)
    },
    organizationServiceName () {
      const service = this.service?.toUpperCase() || this.service
      return this.$i18n.keyExists(service) ? this.$t(service) : service
    }
  },
  created () {
    if (this.user?.configuration?.logo) {
      this.picture = this.user.configuration.logo
    }
  },
  async mounted () {
    moment.locale(this.lang)
    this.config = await Config()

    if (!this.lightHeader) {
      // Load organization and dataplants for selector
      await this.$store.dispatch('LOAD_ORGANIZATIONS')

      // We do not want this call because it is too heavy. To replace in the future
      // try {
      //   if (!this.dataplantsList) this.dataplantsList = await this.$api.FPAPI.dataplants.list(this.config.ORGANIZATION_ID)
      // } catch (err) {}

      try {
        this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', {
          organizationId: this.config.ORGANIZATION_ID,
          ignoreCache: true
        })
      } catch (err) {
        console.error(err)
      }
    }
  },
  methods: {
    setLang (lang) {
      this.$i18n.set(lang)
      moment.locale(lang)
      window.localStorage.setItem('hq_lang', lang)
    },
    isActiveLang (lang) {
      return lang === this.lang.toLowerCase()
    },
    logout () {
      this.$store.commit('SET_OPEN_SIDE_BAR', false)
      if (this.service === 'cc' || this.service === 'se') this.$store.dispatch('LOGOUT_CMP')
      else this.$store.dispatch('LOGOUT')
    },
    openSidebar () {
      this.$analytics.track('Open main sidebar', { from: 'sidebar' })
      this.$store.commit('SET_OPEN_SIDE_BAR', !this.$store.getters.OPEN_SIDE_BAR)
    },
    hover (type) {
      this.hoverSelector = type
    },
    trackCloseSidebar () {
      this.$analytics.track('Close main sidebar', { from: 'sidebar' })
    }
  }
}
</script>

<style lang='less'>
// Style in src/shared/styles/header.less
</style>
