import ModalConfirm from './ModalConfirm.vue'

export default (ctx, { content, contentHtml, title, icon, cancel, yes, buttons, className }, options) => {
  cancel = cancel || {}
  yes = yes || {}
  options = options || {}
  options.height = options.height || 'auto'
  options.width = options.width || '475px'

  if (icon) {
    title = `<i class="${icon}"></i>${title}`
  }

  return new Promise((resolve) => {
    ctx.$modal.show(ModalConfirm, {
      title,
      content,
      contentHtml,
      className,
      buttons: buttons || [
        {
          text: cancel.text || cancel.label || ctx.$t('fpui-modal-confirm.cancel'),
          color: cancel.color || 'white',
          type: 'close',
          handler () {
            this.$emit('close')
            return resolve(false)
          }
        },
        {
          text: yes.text || yes.label || ctx.$t('fpui-modal-confirm.yes'),
          color: yes.color || 'green',
          handler () {
            this.$emit('close')
            return resolve(true)
          },
          id: yes.id
        }
      ]
    }, options, {
      closed: () => {
        return resolve(false)
      }
    })
  })
}
