import Vue from 'vue'

export default {
  async get () {
    this.cache = this.cache || Vue.api.KING.users.me()
    const { token } = await this.cache
    return token
  },
  async delete () {
    this.cache = null
  },
  // Force assign the cache object, used in SET_ORGANIZATION_SESSION
  // to prevent multiple requests checksession
  assign (object) {
    this.cache = object
  }
}
