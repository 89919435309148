<template lang="pug">
.header-list-container
  .categories-container-loading(v-if='servicesLoading')
    fp-loading
  .categories-container(v-else)
    .categories(
      v-for="service in servicesList"
    )
      .category-title {{ categoryName(service.category) }}
      a.service(
        v-for="serv in service.services"
        v-if="serv.hidden !== true && serv.installed"
        :class="{ disabled: serv.installing }"
        :href='getLink(serv)'
        v-tooltip="{ content: serv.installing ? $t('header.service_installing') : '', delay: 0 }"
        @click.stop="trackOpenService"
      )
        .service-img-title
          .service-image
            img(:src="serv.image")
          .service-title
            .title {{ $t(serv.displayName) }}
        .service-description {{ $t(serv.smallDescription) }}
        fp-loading.installing(v-if="serv.installing")

      .service-empty(
        v-if="!service.services.filter(s => s.installed).length"
      ) {{ $t('services.project_home.no_service_active') }}

  .header-list-footer(v-if="!lightMode")
    a(
      :href="returnUrl"
    )
      fpui-button(
        color='white'
        icon='fp4 fp4-home'
        icon-left
        auto-width
      ) {{ $t('services.project_home') }}
    fpui-button(
      color='green'
      icon='fp4 fp4-plus'
      icon-left
      auto-width
      :disabled="allServicesUsed"
      v-tooltip="{ content: allServicesUsed ? $t('services.cannot_add_more') : '', delay: 0 }"
      @click="openAddServiceModal"
    ) {{ $t('services.add_more') }}
</template>

<script>
import Config from '@/shared/Config'
import Services from '@/core/helpers/services'
import qs from 'qs'
export default {
  props: {
    autoSave: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    lightMode: { type: Boolean, default: false }
  },
  data () {
    return {
      servicesList: [],
      serviceNotUsed: 0,
      config: null
    }
  },
  computed: {
    projectId () {
      return this.$route.params.dataplantId || this.$route.params.projectId || this.config?.PROJECT_ID
    },
    project () {
      if (this.projectId) return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
      return null
    },
    dataplant () {
      if (this.projectId) {
        if (this.lightMode) return { services: this.config?.SERVICES }
        return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
      }
      return {}
    },
    returnUrl () {
      return `${window.location.origin}/#/project/${this.projectId}`
    },
    allServicesUsed () {
      return this.serviceNotUsed === 0
    },
    servicesLoading () {
      return this.dataplant?.services === undefined
    }
  },
  async mounted () {
    this.config = await Config()
    this.servicesList = Services.map(categories => {
      return {
        ...categories,
        services: categories.services.map(service => {
          if (!this.dataplant?.services?.includes(service.serviceId) && !service.hidden) this.serviceNotUsed += 1
          const svId = service.serviceId === 'gab' ? 'aab' : service.serviceId

          return {
            ...service,
            installed: this.dataplant?.services?.includes(service.serviceId),
            installing: Object.keys(this.project?.display_settings?.services_wizard || {}).includes(svId)
          }
        }).filter(service => {
          if (this.config?.DATA_PLATFORM_ACCESS && service.id === 'mlm') return false
          if (service.dataplatform && !this.config?.DATA_PLATFORM_ACCESS) return false
          return true
        })
      }
    })
  },
  methods: {
    serviceHasAccess (service) {
      if (['api', 'app'].includes(service)) return this.hasAccess.appservice
      if (['ml', 'mlm'].includes(service)) return this.hasAccess.ml
      if (['data-catalog'].includes(service)) return this.hasAccess.dm
      if (['lakehouse'].includes(service)) return this.hasAccess.dm || this.hasAccess.datastore
      return this.hasAccess[service]
    },
    categoryName (category) {
      return this.$i18n.keyExists(`services.title.${category}`) ? this.$t(`services.title.${category}`) : category
    },
    openAddServiceModal () {
      this.$analytics.track('Open service addition funnel', { from: 'header menu' })
      const queryString = qs.stringify({
        project_id: this.config.PROJECT_ID
      })
      window.open(`${window.location.origin}/#/home/${this.config.ORGANIZATION_ID}/services?${queryString}`, '_self')
    },
    getLink (service) {
      if (service.installing) return
      let link = `${window.location.origin}/${service?.id}/#/${this.projectId}`
      if (['infra'].includes(service?.id)) {
        link = `${window.location.origin}/#/${service?.id}/${this.projectId}`
      }
      if (['api', 'app'].includes(service?.id)) {
        link = `${window.location.origin}/#/project/${this.projectId}`
      }
      if (['cc', 'control-center'].includes(service?.id)) {
        if (this.projectId) link = `${window.location.origin}/cc/#/${this.config.ORGANIZATION_ID}/${this.projectId}`
        else link = `${window.location.origin}/cc/#/${this.config.ORGANIZATION_ID}`
      }
      if (['ml', 'mlm'].includes(service?.id)) link = `${window.location.origin}/ml/#/${this.projectId}`
      if (service?.id === 'iam') link = `${window.location.origin}/iam/#/${this.projectId || this.config.ORGANIZATION_ID}/`

      return link
    },
    trackOpenService () {
      window.localStorage.setItem('tracking-from', 'header menu')
    }
  }
}
</script>

<style lang="less">
.header-list-container {
  position: absolute;
  top: 50px;
  left: -20px;
  width: 100vw;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  filter: drop-shadow(0px 10px 10px rgba(62, 69, 80, 0.19));

  .categories-container-loading {
    display: flex;
    padding: 46px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #E4E7EC;
    justify-content: center;
  }

  .categories-container{
    padding-left: 46px;
    padding-right: 46px;
    display: flex;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #E4E7EC;

    .categories{
      padding: 20px 20px 0px;
      border-right: 1px solid #E4E7EC;
      width: 25%;
      max-width: 350px;

      &:last-of-type {
        border-right: none;
      }
    }
  }


  .category-title{
    width: 182px;
    height: 18px;
    margin-bottom: 17px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #748294;
  }

  .service {
    margin-bottom: 21px;
    display: block;
    .service-img-title {
      display: flex;
      align-items: center;
      img {
        width: 28px;
        transition: transform 100ms;
      }
    }

    .service-title {
      margin-left: 16px;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #0089C0;
      }
    }
    .service-icon {
      margin-left: auto;
    }

    .service-description {
      margin-left: 44px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      max-width: 186px;
      color: #748294;
    }

    &:hover:not(.disabled) {
      border-right: 2px solid #0089C0;
      .title {
        font-weight: 600;
        text-decoration-line: underline;
      }
      .service-img-title {
        img {
          transform: scale(1.3)
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      position: relative;
      .service-img-title, .service-description {
        filter: grayscale(1);
        opacity: 0.5;
      }
      .fp-loading.installing {
        position: absolute;
        top: calc(~"50% - 15px");
        right: 0;
      }
    }
  }

  .service-empty {
    font-weight: 400;
    font-size: 14px;
    color: #748294;
  }


  .header-list-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 24px;
    background: #F6F9FC;
    height: 60px;

    .fpui-button {
      margin-left: 15px;
    }
  }
}
</style>
