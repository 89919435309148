<template lang="pug">
.chart-table(ref="chart")
  fpui-table(
    v-if="!isTypeJson"
    :height="height"
    :columns='columns'
    :data='rows'
    :pagination="pagination"
    :virtual-scroll="true"
  )

  monaco-editor.editor(
    v-if="isTypeJson"
    ref="editor"
    v-model="JSON.stringify(value, null, 2)"
    language="json"
    read-only
  )
</template>

<script>
import _get from 'lodash/get'
import MonacoEditor from '@/shared/components/editors/MonacoEditor.vue'

export default {
  components: {
    MonacoEditor
  },
  props: {
    value: { type: Object, default: () => {} },
    sql: { type: Boolean, default: false }
  },
  data () {
    return {
      rows: []
    }
  },
  computed: {
    options () {
      return this.value?.options
    },
    isTypeJson () {
      return !!this.options?.json
    },
    evolDepth () {
      if (!this.value?.query_params?.evol || !this.value.query_params.evol.scale) return 0
      return this.value?.query_params?.evol.depth || 1
    },
    columns () {
      const columns = []

      if (!this.sql) {
        if (this.value?.query_params?.scale?.fields) {
          this.value.query_params.scale.fields.forEach(scale => {
            columns.push({
              label: scale,
              target: `scales.${scale}`,
              paddingLeft: 15,
              minWidth: () => {
                let width = 150
                const length = scale.length
                width = length * 5 + 50
                if (width > 300) return 300
                if (width < 150) return 150
                return width
              }
            })
          })
        }
        if (this.value?.query_params?.data?.fields) {
          Object.keys(this.value.query_params.data.fields).forEach(data => {
            this.value.query_params.data.fields[data].forEach(cm => {
              for (let evol = 0; evol <= this.evolDepth; evol++) {
                columns.push({
                  label: cm === 'select' ? data : cm + ' ' + data,
                  type: 'number',
                  target: (row) => {
                    const val = _get(row, `data.${data}.${cm}[${evol}].value`)
                    const type = _get(row, `data.${data}.${cm}[${evol}].type`)
                    // If the column is number, we add number separator ( ex.) 1000 -> 1,000 )
                    return ['bigint', 'double', 'decimal'].includes(type) && val.toLocaleString() ? val.toLocaleString() : val
                  },
                  paddingLeft: 15,
                  minWidth: () => {
                    let width = 150
                    const length = cm === 'select' ? data.length : (cm + ' ' + data).length
                    width = length * 5 + 50
                    if (width > 300) return 300
                    if (width < 150) return 150
                    return width
                  }
                })
              }
            })
          })
        }
      } else {
        (this.value?.results?.columns || []).forEach((column, idx) => {
          columns.push({
            label: column,
            target: (row) => {
              const val = _get(row, column)
              // If the column is number, we add number separator ( ex.) 1000 -> 1,000 )
              return ['bigint', 'double', 'decimal'].includes(this.value.columns[idx]?.typeSignature?.rawType) && val.toLocaleString() ? val.toLocaleString() : val
            },
            paddingLeft: 15,
            minWidth: () => {
              let width = 150
              const length = column.length || 0
              width = length * 5 + 50
              if (width > 300) return 300
              if (width < 150) return 150
              return width
            }
          })
        })
      }

      return columns
    },
    height () {
      if (!this.pagination.disabled && this.options.paginationNumber >= this.rows.length) return '100%'
      if (!this.pagination.disabled) return 'calc(100% - 50px)'
      return '100%'
    },
    pagination () {
      if (this.options?.pagination) return { perPage: this.options.paginationNumber }
      return { disabled: true }
    }
  },
  mounted () {
    this.setRows()
  },
  methods: {
    setRows () {
      let rows = this.value?.results

      if (this.sql) {
        const { results, columns } = this.value?.results || {}
        if (results) {
          rows = new Array(results.length)

          for (let i = 0; i < rows.length; i++) {
            // eslint-disable-next-line no-sequences
            rows[i] = results[i].reduce((acc, cur, i) => {
              return ((acc[columns[i]] = cur), acc)
            }, {})
          }
        }
      }

      if (rows && rows.length && !Object.keys(rows[0]).includes('id')) {
        for (let i = 0; i < rows.length; i++) {
          rows[i].id = i
        }
      }

      this.rows = rows
    }
  }
}
</script>

<style lang="less">
.chart-table {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: auto;

  .editor {
    height: 100%;
    width: 100%;
    z-index: 3;
    position: relative;
  }

  .fpui-table {
    section {
      &.fpui-table_thead {
        >.fpui-table_tr {
          border-top: 1px solid #E4E7EC;
          border-bottom: 1px solid #E4E7EC;
          >.fpui-table_th {
            &:first-of-type {
              border-left: 1px solid #E4E7EC;
            }
            border-right: 1px solid #E4E7EC;
          }
        }
      }
      &.fpui-table_body {
        &.alternative-rows {
          .fpui-table_row {
            height: 32px;

            &:nth-child(even) {
              background-color: rgba(246, 249, 252, .5);
            }
            &:not(:first-of-type) {
              border-top: 1px solid #E4E7EC;
            }

            > .fpui-table_cell {
              line-height: 32px;
              height: 32px;
              border-right: 1px solid #E4E7EC;
              border-left: none;
            }
            &:first-of-type {
              border-left: 1px solid #E4E7EC;
            }
          }
          .vue-recycle-scroller__item-view {
            &:nth-child(even) {
              background-color: #F6F9FC;
            }

            &:not(:first-of-type) {
              border-top: 1px solid #E4E7EC;
            }
          }
        }
      }
    }
  }
}
</style>
