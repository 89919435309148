import { API } from '@/shared/plugins/Api/API'
import Authentications from './Authentications'
import Users from './Users'

class KING extends API {
  constructor (id) {
    super(id, true)
    this.users = new Users(id)
    this.authentications = new Authentications(id)
  }

  /**
   * Reset MFA
   * If a user is blocked out of his account because he does not have access to any of his configured MFA methods, he should be able to reset his MFA.
   * @param {string} otp - backup code
  */
  async resetMFA (otp) {
    return this.request({
      method: 'POST',
      url: 'v4/mfa/reset',
      retries: 0,
      data: {
        otp
      },
      noOrganizationId: true
    })
  }

  /**
   * Register MFA. It will add `"active": false` to user under mfa field.
   * @param {string} mfaMethod - method type. It will be either 'authenticator' or 'sms'
  */
  // mfaMethod is either 'authenticator' or 'sms'
  async registerMfa (mfaMethod) {
    return this.request({
      method: 'POST',
      url: `v4/mfa/register/${mfaMethod}`,
      retries: 0,
      noOrganizationId: true
    })
  }

  /**
   * Validate MFA
   * It need to be called after calling `v4/mfa/register/${mfaMethodType}`
   * @param {string} type - method type. It will be either 'authenticator' or 'sms'
   * @param {string} otp - one time password
  */
  async validateMFACode ({ type, otp }) {
    return this.request({
      method: 'POST',
      retries: 0,
      url: `v4/mfa/validate/${type}`,
      data: {
        otp
      },
      noOrganizationId: true
    })
  }

  /**
   * Get backup codes for MFA. The backup code can be only downloaded only for the initial setup. if the user deactivated all mfas and reactivate again, it is possible to download.
  */
  async getMFABackupCode () {
    return this.request({
      method: 'GET',
      retries: 0,
      url: 'v4/mfa/backup',
      noOrganizationId: true
    })
  }

  /**
   * Disable MFA
   * @param {string} type - It will be either 'authenticator' or 'sms'
  */
  async disableMFA ({ type }) {
    return this.request({
      method: 'DELETE',
      retries: 0,
      url: `v4/mfa/${type}`,
      noOrganizationId: true
    })
  }

  async logout (options) {
    options = options || {}
    options.noOrganizationId = options.noOrganizationId || false

    return this.request({
      method: 'delete',
      url: 'v4/logout',
      retries: 0,
      ...options
    })
      .catch(err => {
        if (err.response.status === 400) return null
        if (err.response.status === 401) return null
        throw err
      })
  }

  async getToken (authenticationId) {
    const { authentication_providers: ap } = await this.request({
      method: 'GET',
      url: 'v4/checksession',
      retries: 0
    })
    return ap.find(a => a.id === authenticationId)?.refresh_token
  }

  async reply (token) {
    return this.request({
      url: 'v4/reply',
      params: {
        redirect_uri: 'false',
        token_mode: 'cookie',
        authentication_provider: 'forepaas'
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  async acceptTermsAndConditions () {
    return await this.request({
      method: 'POST',
      url: 'v4/consent/terms_and_conditions'
    })
  }
}

export default KING
