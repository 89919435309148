<template lang="pug">
.dataplant-creation__provider-selection__personalize
  .dp-personalize-container(:class="{ 'show-content': options.configuration.dataplant.provider }")
    .project-template
      .project-template-header
        .project-template-logo
          img(v-if="options.template.image" :src="options.template.image")
          img(v-else :src="require('@/core/assets/img/logo.png')")
        .project-template-title-description
          .project-template-title(v-if="options.template.id") {{ $t(`project.${options.template.id}.name`) }}
          .project-template-title(v-else) {{ $t('dataplant.creation.no_chosen_template') }}
          .project-template-description(
            @click="browseTemplates"
          ) {{ $t('dataplant.creation.template.change') }}
      .active-templates
        .active-templates-titles {{ $t('dataplant.creation.active_services') }}
        .active-templates-tag-list(v-if="options.template.id")
          .active-templates-tags(v-for="service in servicesWithDCAndLM") {{ service.service }}
        .active-templates-tag-list(v-else)
          .active-templates-tags {{ $t('dataplant.creation.no_active_services') }}

    .provider-personalize
      .form(v-if="canUseCurrentProvider")
        .row
          .col-xs-12
            fpui-input-text(
              id="input-create-project-name"
              v-model="dataplantName"
              :label="$t('dataplant.creation.dataplant_details.form.name.label')"
              :placeholder="$t('dataplant.creation.dataplant_details.form.name.placeholder')"
            )
        .row
          .col-xs-12
            fpui-input-text(
              id="input-project-description"
              v-model="dataplantDescription"
              :label="$t('dataplant.creation.dataplant_details.form.description.label')"
              :placeholder="$t('dataplant.creation.dataplant_details.form.description.placeholder')"
            )
        .row
          .col-xs-12
            fpui-input-tags(
              id="input-project-tags"
              v-model="dataplantTags"
              :label="$t('dataplant.creation.dataplant_details.form.tags.label')"
              :placeholder="$t('dataplant.creation.dataplant_details.form.tags.placeholder')"
            )
        .row.domain-row
          .advanced-options(
            @click="showAdvancedOptions"
          )
            span.advanced-options-text {{ $t('dataplant.creation.provider.advanced_options') }}
            span.advanced-options-right-arrow(
              v-if="!isShownAdvancedOptions"
            ) {{ '▶' }}
            i.fp4.fp4-sort-down(
              v-if="isShownAdvancedOptions"
            )

          transition-group(name="slide-fade")
            .col-xs-6.subdomain(
              v-show="isShownAdvancedOptions"
              :key="'subdomain'"
            )
              img.loading(
                src='@/core/assets/img/ic_spinner.png'
                :class="{ 'is-loading': checkingDomain }"
              )
              fpui-input-text(
                v-model="dataplantSubdomain"
                :label="$t('dataplant.creation.dataplant_details.form.subdomain.label')"
                :placeholder="$t('dataplant.creation.dataplant_details.form.subdomain.placeholder')"
                :error="!!errorDomain"
              )
            .col-xs-6.domain(
              v-show="isShownAdvancedOptions"
              :key="'domain'"
            )
              fpui-input-text(
                v-if="availableDomains.length < 2"
                v-model="dataplantDomain"
                :label="$t('dataplant.creation.dataplant_details.form.domain.label')"
                disabled
              )
              fpui-input-select(
                v-else
                v-model='dataplantDomain'
                name='domain'
                direction='top'
                :options='availableDomains.map(ad => ({ label: ad.domain, value: ad.domain }))'
                :clearable='false'
                :label="$t('dataplant.creation.dataplant_details.form.domain.label')"
              )
            .col-xs-12.error-placeholder(
              :class="{ error: errorDomain }"
              :key="'placeholder'"
            )
              span {{ errorDomain }}
      .not-available(v-else)
        h3 {{ $t(`dataplant.creation.details.provider_not_available.title.${isFreeTrial ? 'free_trial' : 'upgrade' }`) }}
        span.description {{ $t(`dataplant.creation.details.provider_not_available.description.${isFreeTrial ? 'free_trial' : 'upgrade' }`) }}
        fpui-button(
          auto-width
          color="blue-flash"
          @click="upgradePlan()"
        ) {{ $t('onboarding.blocks.scale.cta') }}

    .provider-location-informations-region
      .provider-location-informations
        .provider-logo
          img(v-if="options.configuration.dataplant.provider" :src="providerImage(options.configuration.dataplant.provider)")
        .provider-infos
          .provider-name {{ providerName }}
          .provider-region(v-if="regionName !== ''") {{ regionName }}
      .provider-region-selection
        fpui-input-select(
          v-if='options.configuration.dataplant.provider !== "automatic"'
          v-model="region"
          customId="input-dropdown-create-dp-region"
          :clearable="false"
          :options="regions.map( r => ({ label: r.id, value: r, image: providerImage(r.provider), sublabel: r.regionName }))"
          :search-placeholder="$t('dataplant.creation.region_selector.search')"
          custom-value-format="sublabel"
          :show-selected-sublabel="false"
        )

    dataplant-creation-step-footer(
      :steps="[0, 1, 2]"
      :step-active="1"
      customId="button-create-project-next-2"
      :disabled-next="disabledNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
    )

  .provider-not-selected(v-show="!options.configuration.dataplant.provider") {{ $t('dataplant.creation.provider.empty_provider_subtitle') }}

</template>

<script>
import _get from 'lodash/get'
import _find from 'lodash/find'
import _debounce from 'lodash/debounce'
import SubdomainName from '@/shared/filters/SubdomainName'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import DataplantCreationStepFooter from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationStepFooter.vue'
import Store from '@/shared/components/store'

export default {
  components: {
    DataplantCreationStepFooter
  },
  props: {
    options: { type: Object, required: true },
    organizationId: { type: String, required: true },
    disabledNext: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Function,
      default: null
    },
    nextColor: {
      type: String,
      default: 'blue'
    },
    // If enable, it will display a header bar, every step will need a :header field (String)
    headers: {
      type: Boolean,
      default: false
    },
    // If enabled, it will display a footer dots bar
    stepper: {
      type: Boolean,
      default: true
    },
    // If enabled, it will take over the original stepper and display
    // steps progression (i.e.: 1/3), with a description if any
    stepperProgression: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkingDomain: false,
      isShownAdvancedOptions: false,
      steps: [], // That internal object is updated at every mount unmount of substep -> DO NOT MOVE IT TO COMPUTED, VueJs doesnt self update the slots on mount/unmount
      errorDomainResponse: null
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    availableDomains () {
      const authorizedDomains = this.organization.authorized_domain || []
      return authorizedDomains.filter(ad => ad.forepaas)
    },
    servicesWithDCAndLM () {
      let services = [...this.options.template.services]
      const dmService = services.find(s => s.id === 'dm')
      if (dmService) {
        services.push({ id: 'data-catalog', img: dmService.img, service: 'Data Catalog' })
        services.push({ id: 'lakehouse', img: dmService.img, service: 'Lakehouse Manager' })
        services = services.filter(s => s.id !== 'dm')
      }

      return services
    },
    region: {
      get () {
        return this.options.configuration.dataplant.region
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.provider': value.provider })
        this.$emit('update', { 'configuration.dataplant.region': value })
      }
    },
    regionName () {
      if (!this.region) return this.$t('dataplant.creation.automatic_provider.description')
      return this.region?.regionName || ''
    },
    dataplantName: {
      get () {
        return this.options.configuration.dataplant.name
      },
      set (value) {
        this.checkingDomain = true
        this.$emit('update', { 'configuration.dataplant.name': value })
        this.$emit('update', { 'configuration.dataplant.subdomain': SubdomainName(value) })
        this.checkDomain()
      }
    },
    dataplantDescription: {
      get () {
        return this.options.configuration.dataplant.description
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.description': value })
      }
    },
    dataplantTags: {
      get () {
        return this.options.configuration.dataplant.tags
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.tags': value })
      }
    },
    dataplantSubdomain: {
      get () {
        return this.options.configuration.dataplant.subdomain
      },
      set (value) {
        this.checkingDomain = true
        this.$emit('update', { 'configuration.dataplant.subdomain': SubdomainName(value) })
        this.checkDomain()
      }
    },
    dataplantDomain: {
      get () {
        return this.options.configuration.dataplant.domain
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.domain': value })
      }
    },
    errorDomain () {
      if (!this.options.configuration.dataplant.subdomain) return null
      if (!this.options.domainAvailable && !this.checkingDomain) {
        if (this.options.configuration.dataplant.subdomain.length < 3) return this.$t('dataplant.creation.details.subdomain.error_length')
        if (this.errorDomainResponse === 'domain is locked') return this.$t('dataplant.creation.details.subdomain.error_exists')
        return this.errorDomainResponse
      }
      return null
    },
    providerName () {
      if (!this.options.configuration.dataplant.provider) return null
      return _find(this.options.providers, { name: this.options.configuration.dataplant.provider })?.display_options?.display_name
    },
    regions () {
      const regions = this.options.providers.map((p) => {
        return p.regions.map((r) => {
          return {
            id: r.name,
            provider: r.provider,
            providerName: p.display_options?.display_name,
            regionName: r.display_options.display_name
          }
        })
      })
      return regions.reduce((a, b) => a.concat(b), [])
    },
    isFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    },
    isStandardPlan () {
      return this.subscription?.plan === 'standard'
    },
    canUseCurrentProvider () {
      const provider = this.options.configuration.dataplant.provider
      if (provider !== 'automatic' && (this.isFreeTrial || this.isStandardPlan)) return false
      return true
    }
  },
  mounted () {
    if (!this.options.configuration.dataplant.domain && this.availableDomains.length) this.dataplantDomain = this.availableDomains[0].domain
  },
  methods: {
    checkDomain: _debounce(async function () {
      if (this.options.configuration.dataplant.subdomain.length < 3) {
        this.checkingDomain = false
        return this.$emit('update', { domainAvailable: false })
      }
      // Normal 404 error return to let us know no domain has that name
      const checkDomain = await this.$api.FPAPI.checkDomainAvailability(this.dataplantSubdomain.toLowerCase(), this.dataplantDomain, this.organizationId)
      if (checkDomain.state === 404) {
        this.errorDomainResponse = null
        this.$emit('update', { domainAvailable: true })
      } else {
        this.errorDomainResponse = checkDomain.causedBy
        this.$emit('update', { domainAvailable: false })
      }
      this.checkingDomain = false
    }, 500),
    upgradePlan () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: true,
        refreshAfterComplete: true
      }, {
        height: 'auto',
        width: 900
      })
    },
    providerImage (regionProvider) {
      try {
        return require(`@/core/assets/img/providers/${regionProvider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    },
    browseTemplates () {
      this.$modal.show(Store, {
        name: this.$t('core.onboarding.store_template'),
        type: 'project.templates',
        onSelect: (template, modal) => {
          this.templateById(template)
          modal.$emit('close')
        }
      }, {
        width: 980,
        height: 640
      })
    },
    async templateById (selectedTemplate) {
      const storeTemplates = this.$store.getters.STORE_PACKAGES.project?.templates
      const modules = this.$store.getters.STORE_PACKAGES.project?.modules
      const template = storeTemplates[selectedTemplate.id]
      const meta = await this.$api.STORE.metas('project', 'templates', selectedTemplate.id, 'latest')
      const services = Object.keys(meta.options?.services).map(service => {
        let icon = ''
        try {
          icon = require(`@/core/components/Dataplant/assets/${service}.svg`)
        } catch (err) {
          icon = require('@/shared/assets/img/ovhcloud_logo_simplified_blue.png')
        }
        return {
          img: icon,
          service: _get(modules, [service, 'description'])
        }
      })
      this.chosenTemplate({
        id: selectedTemplate.id,
        services: services,
        title: template.description,
        description: template.long_description,
        image: template.image
      })
    },
    chosenTemplate (template) {
      this.$emit('update', { template })
      this.$emit('next')
    },
    showAdvancedOptions () {
      this.isShownAdvancedOptions = !this.isShownAdvancedOptions
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__provider-selection__personalize {
    position: absolute;
    bottom: 104px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 1120px;
    z-index: 1;
    .dp-personalize-container {
      background-color: #FFF;
      border-radius: 5px;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.17);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: stretch;
      padding: 20px 0 0 0;
      z-index: 2;
      opacity: 0;
      transition: opacity 100ms ease-out;
      &.show-content {
        opacity: 1;
      }
      > .project-template{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 309px;
        margin: 0 20px;
        padding: 10px;
        border: 1px solid #E4E7EC;

        .project-template-header {
          display: flex;
          align-content: center;

          .project-template-logo {
              width: 55px;
              height: 55px;
              border: 1px solid #E4E7EC;
              border-radius: 5px;

            img {
              width: 53px;
              height: 53px;
              border-radius: 5px;
            }
          }

          .project-template-title-description {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            margin-left: 9px;

            .project-template-title {
              flex-basis: 100%;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }

            .project-template-description {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              text-decoration-line: underline;
              color: #97A7B7;
              cursor: pointer;
            }
          }
        }

        .active-templates {
          margin-top: 10px;

          .active-templates-titles {
            flex-basis: 100%;
            margin-bottom: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            color: #97A7B7;
          }
          .active-templates-tag-list{
            display: flex;
            flex-wrap: wrap;
            align-content: center;

            .active-templates-tags {
              margin-right: 4px;
              margin-bottom: 4px;
              padding: 0px 8px 0px 10px;
              box-shadow: inset 0px -1px 0px rgba(151, 167, 183, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.25);
              border-radius: 12px;
              font-weight: 400;
              font-size: 11px;
              line-height: 17px;
              text-align: center;
              color: #97A7B7;
              background: #E4E7EC;
            }
          }
        }
      }
      > .provider-personalize {
        width: 451px;
        padding: 0 5px;
        border-left: 1px solid #E4E7EC;
        border-right: 1px solid #E4E7EC;
        > .not-available {
          background-image: url("~@/core/assets/img/rocket_create_dataplant_unable@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
          h3 {
            margin-top: 120px;
            margin-bottom: 8px;
            color: #3E4550;
            font-size: 20px;
            font-weight: 600;
          }
          span.description {
            font-size: 14px;
            color: #3E4550;
            margin-bottom: 26px;
          }
        }
        .row {
          &:not(:first-child) {
            margin-top: 10px;
          }
          &.domain-row {

            .slide-fade-enter-active, .slide-fade-leave-active {
              transition: all .25s ease;
            }
            .slide-fade-enter, .slide-fade-leave-to {
              transform: translateY(-25%);
              opacity: 0;
            }

            .advanced-options {
              padding-left: 15px;
              margin: 10px 0px;
              cursor: pointer;
              color: #97A7B7;

              .advanced-options-text {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
              }

              .advanced-options-right-arrow {
                margin-left: 5px;
              }

              > .fp4-sort-down{
                margin-left: 5px;
              }
            }

            img.loading {
              animation: rotating 1s infinite;
              -webkit-animation: rotating 1s infinite;
              width: 20px;
              position: absolute;
              right: 22px;
              bottom: 8px;
              opacity: 0;
              transition: opacity 20ms linear;
              &.is-loading {
                opacity: 1;
              }
            }
            .error-placeholder {
              display: flex;
              padding: 0 0 0 15px;
              margin: 0;
              height: 0;
              opacity: 0;
              transition: opacity 100ms ease-out, padding-top 500ms ease-out, height 500ms ease-out;
              span {
                color: #c93256;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              &.error {
                opacity: 1;
                padding-top: 5px;
                height: auto;
              }
            }
          }
        }
      }

      .provider-location-informations-region {
        width: 298px;
        padding: 20px 30px 20px 20px;

        .provider-location-informations {
          height: auto;
          display: flex;
          margin-bottom: 16px;
          &:after {
            content: "";
            position: absolute;
            width: 1px;
            margin-top: 17px;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: #E9ECF0;
          }
          > .provider-logo {
            user-select: none;

            width: 45px;
            img {
              height: 37px;
            }
          }
          > .provider-infos {
            display: flex;
            flex-direction: column;
            max-width: 200px;

            > .provider-name {
              color: #3E4550;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 18px;
            }
            > .provider-region {
              color: #B2BECA;
              font-size: 11px;
              letter-spacing: 0;
              line-height: 14px;
            }
          }
        }
        > .provider-region-selection {
          width: 278px;
          flex-basis: 100%;
          user-select: none;
          margin-top: 16px;
          .fpui-input-select-container {
            width: 278px;
            .dropdown{
              width: 278px;
            }
            .content {
              .default {
                .fpui-input-select {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  background: rgba(151, 167, 183, 0.06);
                  border: 1px solid rgba(151, 167, 183, 0.2);
                  border-radius: 4px;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #3E4550;
                  span {
                    padding-right: 5px;
                  }
                  .fp4-angle-down {
                    font-size: 16px;
                  }
                }
              }
              .dropdown {
                ul {
                  max-height: 195px;
                    > span{
                    padding: 10px 0px 5px 20px;
                  }
                  li {
                    height: 60px;
                    box-shadow: inset 0px -1px 0px #F6F9FC;
                    span {
                      display: block;
                        font-weight: 400;
                        line-height: 16px;
                      .select-label{
                        font-size: 14px;
                        color: #3E4550;
                      }
                      .select-sublabel{
                        margin: 0;
                        font-size: 12px;
                        color: #97A7B7;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .dataplant-creation-step-footer {
        flex: 100%;
      }
    }

    .provider-not-selected {
      color: rgba(255,255,255,0.5);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
</style>
