<template lang='pug'>
.tr.fp-tree-item(
  @dblclick="edit"
  :class="dragclass"
  :style="{ height: `${rowHeight}px` }"
)
  component(
    v-for="(column,index) in columns"
    :is="getComponent(column)"
    :icon="noIcon?'':icon"
    :auto-rename="toAutoRename(column)"
    :index="index"
    :columnsNumber="columns.length"
    :key="index"
    :value="value"
    v-bind="getProps(column)"
    :extraProps="extraProps(column)"
    :class="{ 'sort-active': sortOn===column , 'draggable':draggable}"
    :style="{ 'min-width': column.width, 'max-width': column.width }"
    :column="column"
    :context-menu-options="contextMenuOptions"
    :row-height="rowHeight"
    @focus="$emit('focus')"
    @toggle="$emit('toggle')"
    @select="handleClick"
    @input="(value)=>update(column,value)"
  )
  .actions(
    :style="{ height: `${rowHeight}px` }"
  )
    .action(v-for='action in actions')
      fp-loading(v-if="action.loading && action.if(value)")
      i(
        v-if='!action.loading && ((!action.if || action.if(value)) && !action.link) || (action.disabled && action.disabled(value) && action.if(value))'
        :key='action.id'
        :class='getActionIconClass(action)'
        :style="{ height: `${rowHeight}px`, 'line-height': `${rowHeight}px` }"
        :disabled='action.disabled && action.disabled(value)'
        v-tooltip='getTooltip(action)'
        @click="action.disabled && action.disabled(value) ? '' : action.click(value)"
      )
      a(
        v-if="!action.loading && (!action.if || action.if(value)) && action.link && (!action.disabled || (action.disabled && !action.disabled(value)))"
        :class='actionIcon(action)'
        :style="{ height: `${rowHeight}px`, 'line-height': `${rowHeight}px` }"
        v-tooltip='getTooltip(action)'
        :href="action.link(value)"
        :target="action.newWindow ? '_blank' : '_self'"
      )
</template>

<script>
import FpTreeCell from './FpTreeCell'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import interact from 'interactjs'
export default {
  props: {
    draggable: { type: Boolean, required: true },
    sortOn: { type: Object, required: true },
    autoRename: { type: Boolean, default: false },
    parser: { type: Object, required: true },
    icon: { type: String, required: true },
    noIcon: { type: Boolean, default: false },
    value: { type: Object, required: true },
    actions: { type: Array, required: true },
    columns: { type: Array, required: true },
    contextMenuOptions: { type: Object, default: () => {} },
    doubleClick: { type: Boolean, default: true },
    rowHeight: { type: Number, default: 45 }
  },
  data () {
    return {
      dragclass: null
    }
  },
  computed: {
    dropPath () {
      return this.isFolder ? _get(this, `value.object.${this.parser.id}`) : _get(this, `value.object.${this.parser.path}`)
    },
    isFolder () {
      return this.value.object.type === 'folder'
    }
  },
  mounted () {
    if (!this.draggable) return
    // Set draggable and droppable event on the row
    const element = interact(this.$el)
    element.draggable({
      inertia: true,
      autoScroll: true,
      onstart: (event) => {
        // We want to check if it's a part of a big selection
        const item = this.$parent.$parent.selected.find(s => _get(s.object, this.parser.id) === _get(this.value.object, this.parser.id))
        // We put the start item inside, so we will recover it from drop event
        this.dragclass = 'dragging'
        if (item) {
          // We are in massive move
          event.target.items = this.$parent.$parent.selected.map(s => s.object)
        } else {
          // Single move
          event.target.items = [this.value.object]
        }
      },
      onmove: (event) => {
        // keep the dragged position in the data-x/data-y attributes
        const x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx
        const y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy

        // translate the element
        event.target.style.webkitTransform = 'translate(' + x + 'px, ' + y + 'px)'
        event.target.style.transform = event.target.style.webkitTransform
        event.target.style.zIndex = 2000

        // update the posiion attributes
        event.target.setAttribute('data-x', x)
        event.target.setAttribute('data-y', y)
      },
      onend: (event) => {
        event.target.style.webkitTransform = 'translate(0px, 0px)'
        event.target.style.transform = event.target.style.webkitTransform
        event.target.setAttribute('data-x', 0)
        event.target.setAttribute('data-y', 0)
        event.target.style.zIndex = 1
        this.dragclass = null

        const elem = event.currentTarget
        elem.parentNode.removeChild(elem)
      }
    }).on('move', function (event) {
      const interaction = event.interaction
      if (interaction.pointerIsDown && !interaction.interacting() && event.currentTarget.getAttribute('clonable') !== 'false') {
        const original = event.currentTarget
        const clone = event.currentTarget.cloneNode(true)

        clone.style.position = 'absolute'
        clone.style.left = original.offsetLeft + 'px'
        clone.style.top = original.offsetTop + 'px'
        clone.style.transform = original.transform
        clone.class = original.class
        clone.setAttribute('class', 'tr fp-tree-item dragging')
        clone.setAttribute('clonable', 'false')

        original.parentNode?.appendChild(clone)
        interaction.start({ name: 'drag' }, event.interactable, clone)
      }
    })
    // On drop event
    element.dropzone({
      ondragenter: async (event) => {
        this.dragclass = 'droppable-target'
      },
      ondragleave: async (event) => {
        this.dragclass = null
      },
      ondrop: async (event) => {
        // Do not do anything if same element
        if (_isEqual(event.relatedTarget?.items[0], this.value?.object)) return

        if (!event.relatedTarget.items) return
        event.relatedTarget.items.forEach(item => {
          this.$emit('update', { target: this.parser.path, value: this.dropPath, row: item })
        })
        this.dragclass = null
        if (this.isFolder) await new Promise(resolve => this.$emit('open', resolve))
      }
    })
  },
  methods: {
    handleClick (e) { // Need this when need to open folder with simple click
      if (!this.doubleClick) this.edit()
      this.$emit('select', e)
    },
    edit () {
      if (this.value.object.type === 'folder') return this.$emit('toggle')
      this.$emit('edit')
    },
    toAutoRename (column) {
      return column.rename?.target === this.parser.name && this.autoRename
    },
    update (column, value) {
      this.$emit('update', { target: column.rename.target, value, row: this.value.object })
    },
    extraProps (column) {
      // TODO check if use, else remove
      return column.extraProps
    },
    // We can handle custom cell with component inside target of column
    getComponent (column) {
      // TODO May be we do not need the component because we can use target instead => check if used and replace by target
      if (column.component) return column.component
      if (typeof (column.target) === 'object') return column.target
      return FpTreeCell
    },
    // We can handle custom props for custom cell
    getProps (column) {
      if (column.props) {
        switch (typeof (column.props)) {
          case 'object':
            return column.props
          case 'function':
            return column.props(this.value.object)
        }
      }
      return {}
    },
    getActionIconClass (action) {
      const className = [action.icon]
      if (action.disabled && action.disabled(this.value)) className.push('disabled')
      return className
    },
    actionIcon (action) {
      switch (typeof (action.icon)) {
        case 'string':
          return action.icon
        case 'object':
          return action.icon
        case 'function':
          return action.icon(this.value)
      }
    },
    getTooltip (action) {
      if (action.tooltip) {
        switch (typeof (action.tooltip)) {
          case 'string':
            return action.tooltip
          case 'object':
            return action.tooltip
          case 'function':
            return action.tooltip(this.value)
        }
      }
      return ''
    }
  }
}
</script>
