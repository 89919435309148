<template lang='pug'>
  .fpui-input-toggle(:class="{disabled:disabled}")
    .fpui-input-toggle-content
      .fpui-input-label-container(
        v-if='label'
        :class="{disabled:disabled}"
      )
        span {{ label | translate }}
        fpui-helper(
          v-if='helper'
          :text="helper | translate"
          :title="helperTitle ? helperTitle : ''"
        )

        v-popover(v-if="popover" trigger='hover' placement='top')
          i.fp4.fp4-circle-info
          template(slot='popover')
            .tooltip-container
              .tooltip-body {{ popoverText }}
              .tooltip-link(@click="popoverAction") {{ popoverLink }}

      span.optional(v-if="optional")
        i {{ $t('fpui-input.optional')}}
      fp-loading(v-if="loading")
      toggle-button(
        v-else
        v-model='model',
        :disabled='disabled'
        :color="colors",
        :labels="labels"
        :width='widthToggle',
        :height='22',
        :sync='sync'
        :id="customId"
      )
    .sublabel-container(v-if="sublabel")
      span {{sublabel | translate}}
    slot
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    optional: { type: Boolean, default: false },
    value: { type: Boolean, required: true, default: false },
    width: { type: Number, default: 37 },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    popover: { type: Boolean, default: false },
    popoverText: { type: String, default: '' },
    popoverLink: { type: String, default: '' },
    popoverAction: { type: Function, default: () => {} },
    label: { type: String, default: '' },
    sublabel: { type: String, default: null },
    customId: { type: String, default: null },
    colors: {
      type: Object,
      default () {
        return { checked: '#00CCF9', unchecked: 'rgba(151,167,183,0.5)' }
      }
    },
    labels: { type: [Object, Boolean], default: false },
    sync: Boolean,
    loading: Boolean
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },
    widthToggle () {
      if (this.width) return this.width
      if (this.labels) {
        let longerText = ''
        let long = 0
        Object.keys(this.labels).forEach(key => {
          const length = (this.labels[key] && this.labels[key].length) || 0
          if (length > long) {
            long = length
            longerText = this.labels[key]
          }
        })
        if (long > 0) {
          return this.getTextWidth(longerText, 10) + 36
        }
      }
      return 80
    },
    // Not use in v4 but still here if we need it one day
    labelsToggle () {
      if (typeof this.labels === 'undefined') return { checked: 'Checked', unchecked: 'Unchecked' }
      return this.labels
    }
  },
  methods: {
    getTextWidth (text, fontSize, pStyle) {
      let lDiv = document.createElement('div')
      document.body.appendChild(lDiv)
      if (pStyle != null) {
        lDiv.style = pStyle
      }
      lDiv.style.fontSize = `${fontSize}px`
      lDiv.style.position = 'absolute'
      lDiv.style.left = -1000
      lDiv.style.top = -1000
      lDiv.innerHTML = text
      const lResult = {
        width: lDiv.clientWidth,
        height: lDiv.clientHeight
      }
      document.body.removeChild(lDiv)
      lDiv = null
      return lResult.width
    }
  }
}
</script>

<style lang='less'>
  .fpui-input-toggle {
    width:100%;
    .sublabel-container {
      color: #3E4550;
      font-size: 16px;
      line-height: 19px;
    }
    .fpui-input-label-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .v-popover {
        margin-left: 5px;
        margin-top: 3px;
        i {
          font-size: 16px;
          color: #CBD3DB;
          &:hover {
            color: #00CCF9;
          }
        }
      }
    }
    .optional {
      color: rgba(62,69,80,.25);
      font-size: 13px;
      line-height: 20px;
      padding: 0 20px;
    }
    .fpui-input-toggle-content {
      display: flex;
      width:100%;
      align-content: center;
      justify-content: space-between;
      color: #3E4550;
      font-family: "Source Sans Pro";
      font-size: 14px;
      .fpui-input-label-container {
        flex:1;
        .fpui-helper i, span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .fpui-input-toggle {
        text-align: right;
      }
      .v-switch-button {
        width: 18px !important;
        height: 18px !important;
        margin-top: -1px;
        margin-left: -1px;
      }
      .disabled {
        pointer-events: inherit !important;
        cursor: not-allowed !important;
      }
    }
  }
</style>
