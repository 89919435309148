<template lang="pug">
.tile-container(
  ref="tile"
  :class="{ 'not-editing': !editing, 'tile-is-small': tileIsSmall }"
  :style="{ opacity: editChartSettings && editChartSettings.tile.i !== tile.i ? 0.2 : 1 }"
)
  .tile-header
    .tile-filters-applied(
      v-if="queryType === 'sql' && (editing || filters.length)"
      v-tooltip="{ content: editing ? $t('dashbaord.filter_no_applied_sql') : noFiltersTooltipSQL, delay: 0 }"
    )
      span(
        v-if="!tileIsSmall"
      ) {{ $t('dashboard.no_filter_applied') }}
      i.fp4.fp4-circle-info
    tile-filters-options(
      v-if="queryType !== 'sql' && (ignoredFilters.length || editing) && filters.length"
      :dashboard="dashboard"
      :tile="tile"
      :filters-irrelevant="filtersIrrelevant"
      :editing="editing"
      :tile-is-small="tileIsSmall"
      @ignored-filters-updated="ignoredFiltersUpdate"
      @active-tile="activeTile"
      @deactive-tile="deactiveTile"
    )
    tile-options(
      :loading="internalLoading"
      :tile="tile"
      :query="query"
      @update-tile="updateOverrideParameters"
      @replace-query="replaceQuery"
      @duplicate-tile="duplicateTile"
      @delete-tile="deleteTile"
      @active-tile="activeTile"
      @deactive-tile="deactiveTile"
      @download-content="downloadContentCsv"
    )

  .tile-chart(
    :style="{ overflow: chartMode === 'table' ? 'hidden' : 'unset' }"
  )
    fp-loader(type="chart" v-if="internalLoading")
    chart-display(
      v-else
      :result="result"
      :chart-mode="chartMode"
      :error="error"
      :error-summary="errorSummary"
      :result-to-display="resultToDisplay"
      :type="queryType"
      :query-doesnt-exist="queryDoesntExist"
      :dashboard="dashboard"
      :active-drilldown="editing || fromDashboard || fromPreview"
      :from-preview="fromPreview"
      :from-dashboard="fromDashboard"
      :query="typeof tile.query === 'string' ? tile.query : tile.query.shared_id"
      @active-tile="activeTile"
      @deactive-tile="deactiveTile"
    )

  .tile-name-story
    .tile-name
      span.input-mirror {{ title }}
      input(
        ref="input"
        type="text"
        :disabled="disableEdit"
        :value="title"
        :class="{ 'not-editing': !editing }"
        @input="updateTitle"
      )
    .tile-story(
      :style="storyStyle"
      :class="{ 'not-editing': !editing }"
      v-tooltip="story.text ? '' : $t('dashbaord.add_story.tooltip')"
      @click="addEditStory"
    )
      i.fp4.fp4-notes(
        :style="iconStoryStyle"
      )
      .story-container(
        v-if="story.text"
        :style="{ right: tile.x === 0 && tile.width <= 3 ? '-182px' : 0 }"
      )
        .story-text {{ story.text }}
        story-author(
          v-if="editing"
          :story="story"
        )
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _intersection from 'lodash/intersection'
import _difference from 'lodash/difference'
import _debounce from 'lodash/debounce'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'

import EchartsFormatter from '@/shared/components/query-builder/QueryPreview/Charts/EchartsFormatter'
import ChartDisplay from '@/shared/components/dashboarding/ChartDisplay.vue'
import Config from '@/shared/Config'

import AddEditStory from '../Modals/AddEditStory.vue'
import StoryAuthor from '../StoryAuthor.vue'
import TileOptions from './TileOptions.vue'
import TileFiltersOptions from './TileFiltersOptions.vue'
import TileDrilldown from './TileDrilldown.vue'
import shortcutsDatePicker from '../utils.js'

export default {
  components: {
    StoryAuthor,
    ChartDisplay,
    TileOptions,
    TileFiltersOptions,
    TileDrilldown
  },
  props: {
    tile: { type: Object, default: () => ({}) },
    dashboard: { type: Object, default: () => ({}) },
    fromDashboard: { type: Boolean, default: false },
    editing: { type: Boolean, default: false },
    fromPreview: { type: Boolean, default: false }
  },
  data () {
    return {
      internalLoading: false,
      result: null,
      error: null,
      errorSummary: null,
      queryDoesntExist: null,
      story: {},
      config: null,
      tileIsSmall: false,
      displayDrilldown: false
    }
  },
  computed: {
    title () {
      return this.tile.title
    },
    filters () {
      return Object.keys(this.dashboard.filter || {})
    },
    ignoredFilters () {
      return this.tile.ignored_filters || []
    },
    noFiltersTooltipSQL () {
      return `${this.$t('dashboard.filter.not_applied_deploy')} <strong>${this.filters.join(', ')}</strong>`
    },
    query () {
      if (!this.tile.query) return {}
      if (typeof this.tile.query === 'string') return this.$store.getters.QB_QUERY_BY_SHARED_ID(this.dashboard.repository, this.tile.query, this.dashboard.repository_version) || {}
      return this.tile.query
    },
    queryType () {
      return this.query?.type || 'forepaas'
    },
    shortcutsDatePickerByKey () {
      return _keyBy(shortcutsDatePicker, 'key')
    },
    filtersFromUrl () {
      const query = this.$route.query
      const filtersFormatted = {}

      Object.keys(query).forEach(q => {
        if (this.filters.includes(q)) {
          const details = query[q].split('-')
          let condition = details.shift() // Get condition
          let values = details.join('-').slice(1, -1).split('","') // Put all together + remove first " and last " + split values

          if (this.shortcutsDatePickerByKey[values] && ['date', 'datetime'].includes(this.dashboard.filter[q]?.type)) {
            const format = this.dashboard.filter[q].type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
            const date = this.shortcutsDatePickerByKey[values].value
            values = [date.start().format(format), date.end().format(format)]
            condition = 'between'
          }

          filtersFormatted[q] = {
            [condition]: values
          }
        }
      })

      return filtersFormatted
    },
    filtersToUse () {
      const filtersToUse = _cloneDeep(this.filtersFromUrl) || {}
      Object.keys(this.filtersFromUrl || {}).forEach(f => {
        if (this.ignoredFilters.includes(f) || !Object.keys(filtersToUse[f]).length) delete filtersToUse[f]
      })

      return filtersToUse
    },
    queryWithFiltersOverride () {
      if (this.queryType === 'forepaas') {
        const filtersOverride = _cloneDeep(this.query.configuration.filter) || {}
        Object.keys(this.filtersToUse).forEach(f => {
          filtersOverride[f] = {
            ...filtersOverride[f],
            ...this.filtersToUse[f]
          }
        })

        return Object.assign(
          {},
          this.query,
          {
            configuration: {
              ...this.query.configuration,
              filter: filtersOverride
            }
          }
        )
      }

      return this.query
    },
    filtersIrrelevant () {
      if (this.fromDashboard) return []
      if (this.queryType === 'sql') return []

      const filters = Object.keys(this.dashboard.filter || {})
      const configuration = this.query.configuration
      const filtersIrrelevant = _difference(filters, this.getCompatiblesAttributes(configuration))

      return filtersIrrelevant || []
    },
    chartMode () {
      return this.tile?.override_parameters?.chart_active || this.query?.chart_active || 'table'
    },
    dashboardColors () {
      return this.$store.getters.DASHBOARD_COLORS_OPTIONS
    },
    resultToDisplay () {
      if (!this.result) return
      if (['line', 'pie', 'bar', 'scatter', 'area'].includes(this.chartMode)) {
        const fullOptions = {
          ..._cloneDeep(this.result),
          options: {
            ..._cloneDeep(this.query?.chart_settings[this.chartMode]),
            color: this.dashboardColors[this.dashboard?.override_parameters?.color_palette || 'forepaas_soft']
          },
          tooltipId: this.tile.i
        }

        if (this.result?.results?.length > 30 && this.chartMode === 'pie') {
          fullOptions.options.series.label.show = false
        }

        const options = new EchartsFormatter(fullOptions)

        options.grid = {
          left: 40,
          top: 20,
          bottom: 30,
          right: 0
        }
        options.tooltipId = this.tile.i

        return options
      }

      const results = this.result
      results.options = this.query?.chart_settings[this.chartMode]
      results.options.color = this.dashboardColors[this.dashboard?.override_parameters?.color_palette || 'forepaas_soft']
      results.tooltipId = this.tile.i

      return results
    },
    storyStyle () {
      const style = {}
      if (this.story?.text) style.display = 'flex'

      return style
    },
    iconStoryStyle () {
      return {
        color: this.story?.text ? '#FFCD2F' : '#CBD3DB'
      }
    },
    storyAuthor () {
      return this.$store.getters.IAM_USER_BY_UID(this.story.updated_by)
    },
    disableEdit () {
      // TODO acls
      return !this.editing
    },
    metasTables () {
      return this.$store.getters.DWH_METAS_TABLES
    },
    diamondsWithVirtualAttributes () {
      return this.$store.getters.DWH_DIAMONDS_WITH_VA
    },
    allAttributes () {
      return [...this.$store.getters.DWH_ALL_ATTRIBUTES].sort()
    },
    evolDepth () {
      if (!this.result?.query_params?.evol || !this.result.query_params.evol.scale) return 0
      return this.result?.query_params?.evol.depth || 1
    },
    editChartSettings () {
      return this.$store.getters.DASHBOARD_QB_EDIT_TILE_CHART_SETTINGS
    }
  },
  watch: {
    'tile.query': {
      handler () {
        this.run()
      }
    },
    'tile.ignored_filters': {
      handler (val = [], oldVal = []) {
        if (!val.length && !oldVal.length) return
        if (val.length > oldVal.length) { // We add value
          const valAdded = val.length === 1 ? val[0] : val.find(v => !oldVal.includes(v))
          if (valAdded && Object.keys(this.filtersFromUrl).includes(valAdded)) this.run()
        } else { // We remove value
          const valRemoved = oldVal.length === 1 ? oldVal[0] : oldVal.find(v => !val.includes(v))
          if (valRemoved && Object.keys(this.filtersFromUrl).includes(valRemoved)) this.run()
        }
      }
    },
    'tile.width': {
      handler () {
        if (this.$refs?.tile?.clientWidth < 230) this.tileIsSmall = true
        else this.tileIsSmall = false
      }
    },
    '$store.getters.QB_REFRESH_DASHBOARD_ID': {
      handler (value, oldValue) {
        if (value !== oldValue) this.run()
      }
    },
    '$route.query': {
      deep: true,
      handler (val, oldVal) {
        const filterUpdated = Object.keys(val).find(k => val[k] !== oldVal[k]) || Object.keys(oldVal).find(k => oldVal[k] !== val[k])
        if (filterUpdated && !this.ignoredFilters.includes(filterUpdated)) this.run()
      }
    },
    displayDrilldown: {
      handler (val) {
        if (val) this.activeTile()
        else this.deactiveTile()
      }
    }
  },
  created () {
    this.run = _debounce(this.run.bind(this), 300)
  },
  async mounted () {
    this.story = _cloneDeep(this.tile?.story) || {}

    this.internalLoading = true
    await this.run()
    if (this.ignoredFilters.length) this.removeIgnoredFiltersIfNoFilter()
    this.config = await Config()
  },
  updated () {
    this.$nextTick(() => {
      if (this.$refs?.tile?.clientWidth < 230) this.tileIsSmall = true
      else this.tileIsSmall = false
    })
  },
  methods: {
    async run () {
      if (!this.query || !Object.keys(this.query).length) {
        this.result = null
        this.error = null
        this.errorSummary = null
        this.queryDoesntExist = true
        this.internalLoading = false
        return
      }

      if (this.query && !Object.keys(this.query?.configuration?.data?.fields || []).length && !this.query.configuration?.scale?.fields?.length && this.query.type !== 'sql') {
        this.result = null
        this.error = null
        this.errorSummary = null
        this.queryDoesntExist = null
        this.internalLoading = false
        return
      }

      try {
        this.internalLoading = true

        // Add possibility to override url when execute query from dashboard deployed
        let url = null
        const queryUrl = this.dashboard.tags?.tags.find(t => t.includes('query_url'))
        if (queryUrl && this.fromDashboard) {
          const urlDetail = queryUrl.split('=')
          const urlToUse = _get(urlDetail, '[1]')
          url = urlToUse
        }

        const options = {
          legacy: false,
          source: `${this.fromDashboard ? 'Live dashboard' : 'Dashboard'}`,
          dashboardId: this.dashboard._id,
          queryId: this.query.shared_id,
          fromDashboard: this.fromDashboard,
          url
        }

        if (this.queryType === 'forepaas' && this.fromDashboard) {
          options.filters = {
            filter: this.filtersToUse
          }
        }

        this.result = await this.$api.QB.getResult(this.queryWithFiltersOverride, null, options)

        this.internalLoading = false
        this.error = null
        this.errorSummary = null
        this.queryDoesntExist = null
      } catch (error) {
        this.error = error.message
        this.errorSummary = error.error_code
        this.queryDoesntExist = null
        this.internalLoading = false
      }
    },
    addEditStory () {
      if (!this.editing) return
      this.$modal.show(AddEditStory, {
        story: this.story,
        result: this.result,
        chartMode: this.chartMode,
        error: this.error,
        resultToDisplay: this.resultToDisplay,
        queryDoesntExist: this.queryDoesntExist,
        type: this.query?.type,
        loading: this.internalLoading,
        handleChange: text => {
          this.$analytics.track('Edit chart or dashboard story', {
            organization_id: this.config.ORGANIZATION_ID,
            shared_id: this.dashboard.shared_id,
            type: 'chart'
          })
          // TODO Optimize when WS
          const uid = this.$store.getters.SESSION?.uid
          this.story = Object.assign({}, { ...this.story, text, updated_by: uid, updated_at: new Date() })
          this.$emit('tile-updated', { tileId: this.tile.i, key: 'story', value: this.story })
        }
      },
      {
        height: 'auto',
        width: '600px'
      })
    },
    duplicateTile () {
      this.$emit('duplicate-tile', this.tile)
    },
    deleteTile () {
      this.$emit('delete-tile', this.tile)
    },
    activeTile () {
      this.$emit('active-tile', this.tile)
    },
    deactiveTile () {
      this.$emit('deactive-tile', this.tile)
    },
    updateTitle (e) {
      this.$emit('tile-updated', { tileId: this.tile.i, key: 'title', value: e.target.value })
    },
    updateOverrideParameters (value) {
      this.$emit('tile-updated', { tileId: this.tile.i, key: 'override_parameters', value })
    },
    ignoredFiltersUpdate (value) {
      this.$emit('tile-updated', { tileId: this.tile.i, key: 'ignored_filters', value })
    },
    replaceQuery () {
      this.$emit('replace-query', this.tile)
    },
    getCompatiblesAttributes (queryConfiguration) { // Also used in AddFilter.vue, AdvancedFiltersModal.vue, QueryFields.vue
      if (!queryConfiguration) return []
      /* Hide incompatible fields vs selected fields */

      // If table is forces, get only attributes from this table
      if (queryConfiguration.table_name) {
        const tableForced = this.metasTables.find(table => table.name === queryConfiguration.table_name)
        const attributesTableForced = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(tableForced?._id).map(att => att.name)

        const diamonds = this.diamondsWithVirtualAttributes.find(diamond => diamond.name === queryConfiguration.table_name)

        return diamonds?.attributes || attributesTableForced
      } else {
        // Get Data, Scales and Filters fields selected
        let dataScaleFilterOrder = []
        Object.keys(queryConfiguration).forEach(val => {
          if (val === 'filter' && queryConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val])]
          }
          if (val === 'scale' && queryConfiguration[val]?.fields && queryConfiguration[val]?.fields.length > 0) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...queryConfiguration[val].fields]
          }
          if (val === 'data' && queryConfiguration[val]?.fields) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val].fields)]
          }
          if (val === 'order' && queryConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val])]
          }
        })

        if (dataScaleFilterOrder.length > 0 && this.diamondsWithVirtualAttributes.length) {
          let diamonds = []

          // If field(s) selected ...
          if (dataScaleFilterOrder.length === 1) {
            diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dataScaleFilterOrder[0]))
          } else {
            const diamondsNameList = []
            dataScaleFilterOrder.forEach(dsf => {
              // For each fields selected, get a list of diamond's name they are into
              const diamond = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dsf))
              const diamondsNames = []
              diamond.forEach(d => {
                diamondsNames.push(d.name)
              })
              diamondsNameList.push(diamondsNames)
            })
            let diamondNameCommon = []
            diamondsNameList.forEach((t, idx) => {
              // Get the diamond's name in common between the list of names we get previously
              if (idx === 0) {
                diamondNameCommon = t
              } else {
                diamondNameCommon = _intersection(diamondNameCommon, t)
              }
            })
            // Get the diamonds with compatible fields from from the names
            diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamondNameCommon.includes(diamond.name))
          }

          if (diamonds.length > 0) {
            let allFields = []
            diamonds.forEach(diamond => {
              allFields = [...allFields, ...diamond.attributes]
            })

            return this.allAttributes.filter(field => allFields.includes(field))
          } else {
            return Object.keys(queryConfiguration.data.fields) || []
          }
        } else {
          return this.allAttributes
        }
      }
    },
    removeIgnoredFiltersIfNoFilter () {
      const notInFilters = []
      this.ignoredFilters.forEach(f => {
        if (!this.filters.includes(f)) notInFilters.push(f)
      })

      if (notInFilters.length) this.ignoredFiltersUpdate(this.ignoredFilters.filter(f => !notInFilters.includes(f)))
    },
    formatResultToExport () { // Also used in QueryPreview.vue
      let csvContent = ''
      const headerValues = []
      const limit = 10_000_000
      let maxRows = null
      let rowsDownloaded = null

      // Get columns
      const columnsInfo = []
      if (this.queryType !== 'sql') {
        if (this.result?.query_params?.scale?.fields) {
          this.result.query_params.scale.fields.forEach(scale => {
            headerValues.push(scale)
            columnsInfo.push({
              label: scale,
              target: `scales.${scale}`
            })
          })
        }
        if (this.result?.query_params?.data?.fields) {
          Object.keys(this.result.query_params.data.fields).forEach(data => {
            this.result.query_params.data.fields[data].forEach(cm => {
              for (let evol = 0; evol <= this.evolDepth; evol++) {
                headerValues.push(cm === 'select' ? data : cm + ' ' + data)
                columnsInfo.push({
                  label: cm === 'select' ? data : cm + ' ' + data,
                  target: `data.${data}.${cm}[${evol}].value`
                })
              }
            })
          })
        }
      } else {
        (this.result?.results?.columns || []).forEach(column => {
          headerValues.push(column)
          columnsInfo.push({
            label: column,
            target: column
          })
        })
      }
      csvContent += `${headerValues.join('\t')}\n`

      // Get rows
      let rows = this.result?.results
      maxRows = rows.length
      let estimateRowsToLimit = 0

      // Used for sql queries
      const { results, columns } = this.result?.results || {}
      if (this.queryType === 'sql') {
        rows = new Array(results?.length || 0)
        maxRows = rows.length
      }

      for (let i = 0; i < rows.length; i++) {
        if (this.queryType === 'sql') {
          // eslint-disable-next-line no-sequences
          rows[i] = results[i].reduce((acc, cur, i) => ((acc[columns[i]] = cur), acc), {})
        }

        const values = []
        columnsInfo.forEach(c => {
          let value = _get(rows[i], c.target)
          if (typeof value === 'string') value = value.replace(/(\r\n|\n|\r|\t)/gm, ' ')
          values.push(value)
        })

        const test = csvContent + `${values.join('\t')}\n`
        if (i === 0 || estimateRowsToLimit === limit) {
          const firstRowSize = new Blob([test]).size
          estimateRowsToLimit = Math.floor(limit / (firstRowSize || 1))
        }

        if (maxRows > estimateRowsToLimit && i % estimateRowsToLimit === 0 && new Blob([test]).size > limit) {
          maxRows = rows.length
          rowsDownloaded = i
          break
        }
        csvContent = test
      }

      const size = new Blob([csvContent]).size
      if (size && size > limit) {
        const csvContentArray = csvContent.split('\n')
        const ratio = size / limit
        const rowsToKeep = Math.floor(csvContentArray.length / ratio)

        csvContent = csvContentArray.slice(0, rowsToKeep).join('\n')
        // We do not count column name in line downloaded
        rowsDownloaded = rowsToKeep - 1
      }

      return {
        csvContent,
        maxRows,
        rowsDownloaded
      }
    },
    async downloadContentCsv () { // Also used in QueryPreview.vue
      this.downloadLoading = true

      await new Promise(resolve => {
        setTimeout(() => { // Put timeout to display 'loading' in UI
          if (!this.result) return
          this.$analytics.track('Download the query result', {
            shared_id: this.dashboard.shared_id,
            format: 'csv',
            from: 'Dashboard edition'
          })

          try {
            const { csvContent, maxRows, rowsDownloaded } = this.formatResultToExport()
            const blob = new Blob([csvContent], { type: 'text/csv' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = this.tile.title
            a.click()
            a.remove()

            const message = rowsDownloaded === null ? this.$t('query.preview.download.csv.all') : this.$t('query.preview.download.csv.limit', [rowsDownloaded, maxRows])
            this.$fpuiMessageBlock.success(message)
          } catch (err) {
            this.$fpuiMessageBlock.error(err)
            console.error(err)
          }
          resolve(true)
        }, 100)
      })

      this.downloadLoading = false
    }
  }
}
</script>

<style lang="less">
.tile-container {
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #E9ECF0;
  border-radius: 5px;
  padding: 10px;

  .tile-header {
    position: absolute;
    top: 7px;
    right: 15px;
    height: 20px;
    width: calc(~"100% - 15px");
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    display: flex;

    .tile-filters-applied {
      display: none;
      height: 20px;
      padding: 0 3px 0 6px;
      background: #F6F9FC;
      border-radius: 4px;
      cursor: default;

      span {
        font-weight: 400;
        font-size: 9px;
        line-height: 20px;
        color: #B2BECA;
        margin-right: 3px;
      }
      .fp4-circle-info {
        color: #B2BECA;
        font-size: 12px;
        height: 12px;
      }
    }

    .tile-options-container {
      .fp4-ellipsis {
        display: none;
      }
    }

    .tile-filters-options-applied {
      display: none;
    }
  }

  .tile-chart {
    height: calc(~"100% - 40px");
    position: relative;
    overflow: hidden;

    .fp-loader {
      top: 10px;
    }
  }

  .tile-name-story {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 9px;
    position: relative;
    .tile-name {
      width: calc(~"100% - 40px");
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.01em;
      height: 34px;
      border-radius: 5px;

      > .input-mirror {
        visibility: hidden;
        white-space: pre;
        margin-right: 2px;
      }
      > input {
        z-index: 2;
        position: absolute;
        left: 0;
        width: 100%;
        border-color: transparent;
        background: transparent;
        color: #3E4550;
        &:disabled {
          cursor: not-allowed;
        }
        &.error-name {
          color: @red;
        }
        &.blur-error {
          &:not(:focus) {
            color: @red;
            border-color: @red;
          }
        }
        &.not-editing {
          cursor: default;
        }
      }
      > input, > .input-mirror {
        font-family: 'Source Sans Pro', sans-serif;
        padding: 0;
        padding-left: 60px;
        padding-right: 20px;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        width: calc(~"100% - 40px");
        letter-spacing: -0.01em;
        color: @text-color;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .error-text {
        font-size: 11px;
        color: #FF6F90;
        line-height: 15px;
        margin-top: -27px;
        display: block;
      }
    }
    .tile-story {
      position: absolute;
      display: none;
      height: 34px;
      width: 34px;
      justify-content: center;
      align-items: center;
      right: 0;
      cursor: pointer;

      i {
        font-size: 20px;
      }

      .story-container {
        display: none;
        position: absolute;
        top: 45px;
        right: 0;
        width: 364px;
        background: #FFFFFF;
        box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
        border-radius: 7px;

        .story-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #3E4550;
          padding: 12px 20px;
          border-bottom: 1px solid rgba(151,167,183,0.21);
        }

        .story-author {
          padding-left: 15px;
        }
      }

      .input-transition();
      &:hover {
        background-color: #f9fafb;
        .story-container {
          display: block;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }
      &.not-editing {
        cursor: default;
      }
    }
  }

  &:hover:not(.not-editing) {
    border: 1px solid #CBD3DB;
    .tile-header {
      .tile-options-container {
        margin-left: 10px;
        .fp4-ellipsis {
          display: block;
        }
      }
    }

    .tile-name-story {
      .tile-name {
        background: #F6F9FC;
      }

      .tile-story {
        display: flex;
        border: 1px solid #E9ECF0;
        border-radius: 5px;
      }
    }
  }
  &:hover {
    .tile-header {
      .tile-filters-applied {
        display: flex;
        align-items: center;
      }
      .tile-filters-options-applied {
        display: block;
      }
    }
  }

  &.tile-is-small {
    .tile-header {
      .tile-filters-applied {
        padding: 0 3px;
      }
    }

    .tile-name-story {
      .tile-name {
        > input, > .input-mirror {
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
        }
      }
    }

    &:hover {
      .tile-name-story {
        .tile-name {
          > input, > .input-mirror {
            width: calc(~"100% - 40px");
          }
        }
      }
    }
  }
}
</style>
