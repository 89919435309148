<template lang="pug">
  .dataplant-selector(
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  )
    .logo.logo-organization(
      v-if="type === 'organization'"
    )
      a.home-link(
        :href="`#/home/${organizationId}`"
        @click.stop="goToOrganizationOrProject"
      )
        img.ovh-logo(src="@/shared/assets/img/ovhcloud_logo_simplified_blue.png")
    .logo.logo-dataplant(
      v-if="type === 'dataplant'"
    )
      a.dataplant-link(
        :href="returnUrl"
        @click.stop="goToOrganizationOrProject"
      )
        i.fp4.project-icon(
          :class="[iconSettings.type || 'fp4-dataplants']"
          :style="{ color: iconSettings.color }"
        )

    .closed(
      :class="{ shown: displayClosed }"
      @click="open"
    )
      .name-container
        .organization-dataplant-selector-container
          .organization-name(
            v-if='organizationId && type === "organization"'
          ) {{ $t('header.current_organization') }}
          .organization-name(
            v-else-if='organizationId && type === "dataplant"'
          ) {{ organizationName }}
          span.separator(v-if="type === 'dataplant' && dataplantName") |
          .dataplant-name(
            v-if="type === 'dataplant' && dataplantName"
          ) {{ $t('header.current_project') }}
        span.name(v-if="item") {{ item.display_name || item.name }}
      i.fp4.fp4-chevron-down

    .opened(
      v-if="opened"
    )
      .search-container(
        v-if="items.length > 4"
      )
        fpui-input-search(
          v-model="search"
          :placeholder="type === 'organization' ? $t('header.search_organization') : $t('header.search_project')"
          autofocus
        )
      ul.items
        li(v-for="i in itemsFiltered")
          a.item(
            :href="getLink(i)"
            :class="{ disabled: i.notReady, active: isActiveItem(i) }"
            v-tooltip="{ content: i.notReady ? $t('header.project_is_not_ready') : '', delay: 0 }"
            @click.stop="goToItem(i)"
          )
            .name-info-icon-container
              i.fp4.project-icon(
                v-if="type === 'dataplant'"
                :class="[getProjectIconSettings(i).type || 'fp4-dataplants']"
                :style="{ color: getProjectIconSettings(i).color }"
              )
              .name-info-container(
                :style="{ width: type === 'dataplant' ? 'calc(100% - 37px)' : '100%' }"
              )
                .name-container
                  .name {{ i.name || i.display_name }}
                  i.fp4.fp4-hourglass(
                    v-if="type === 'organization' && requestToJoinPending(i)"
                  )
            i.fp4.fp4-arrow-right(
              v-if="!i.notReady"
            )
      .buttons
        fpui-button(
          color="blue-flash"
          reverse
          icon="fp4 fp4-plus"
          icon-left
          noshadow
          auto-width
          :disabled="!canCreate"
          v-tooltip="disabledCreationTooltip"
          @click="newItem"
        ) {{ $t(type === 'dataplant' ? 'dataplant.new' : 'organizations.new') }}
</template>

<script>
import Config from '@/shared/Config'
import DataplantCreationFullscreen from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import Services from '@/core/helpers/services'
import ovhSupportUrl from '@/shared/store/constants/supportUrl'

export default {
  props: {
    type: { type: String, default: '' },
    hoverFromParent: { type: String, default: '' },
    fromHome: { type: Boolean, default: false },
    fromServices: { type: Boolean, default: false },
    fromOrganization: { type: Boolean, default: false },
    fromDataplant: { type: Boolean, default: false },
    fromSupport: { type: Boolean, default: false },
    lightMode: { type: Boolean, default: false },
    currentService: { type: String, default: null }
  },
  data () {
    return {
      config: null,
      opened: false,
      search: '',
      hover: false,
      hide: false // Used to hide closed if another selector is active
    }
  },
  computed: {
    projectId () {
      return this.$route.params.dataplantId || this.$route.params.projectId
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    organizations () {
      return [...this.$store.getters.ORGANIZATIONS].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    },
    dataplant () {
      if (this.projectId) return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
      return null
    },
    iconSettings () {
      return this.dataplant?.display_settings?.icon || {}
    },
    organizationId () {
      return this.organization?._id || this.config?.ORGANIZATION_ID
    },
    organization () {
      return this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params) || this.$store.getters.ORGANIZATION_BY_ID(this.dataplant?.organization_id)
    },
    organizationName () {
      return this.organization?.name || this.config?.ORGANIZATION_NAME
    },
    dataplantName () {
      return this.dataplant?.display_name || this.config?.PROJECT_NAME
    },
    subscription () {
      return this.organization?.subscription
    },
    servicesNames () {
      let servicesNames = []
      Services.forEach(category => {
        const services = category.services.filter(s => !s.hidden).map(s => s.serviceId)
        servicesNames = servicesNames.concat(services)
      })

      return servicesNames
    },
    items () {
      if (this.type === 'dataplant') {
        return this.dataplantsByOrganizationId(this.organizationId)
          // .filter(dp => dp.id !== this.dataplant?.id)
          .map(dp => {
            let activeServices = 0

            this.servicesNames.forEach(sn => {
              if (dp.services?.includes(sn)) activeServices += 1
            })

            return {
              ...dp,
              activeServices,
              notReady: !dp?.global_status || dp?.global_status === 'project_deploying'
            }
          })
      }

      return this.organizations
        // .filter(orga => orga._id !== this.organization?._id)
        .map(orga => {
          return {
            ...orga,
            dataplants: this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(orga?._id)
          }
        })
    },
    itemsFiltered () {
      if (!this.search) return this.items

      return this.items.filter(item => {
        if (this.type === 'organization' && (item._id + '-' + item.name).toLowerCase().includes(this.search.toLowerCase())) return true
        if (this.type === 'dataplant' && (item.id + '-' + item.display_name).toLowerCase().includes(this.search.toLowerCase())) return true
        return false
      })
    },
    item () {
      if (this.type === 'dataplant') return this.dataplant
      return this.organization
    },
    returnUrl () {
      if (!this.config?.PROJECT_ID) return null
      return `${window.location.origin}/#/project/${this.config?.PROJECT_ID}`
    },
    displayClosed () {
      if (this.type === 'organization' && (this.fromHome || this.hover) && !this.hide) return true
      if (this.type === 'dataplant' && (this.fromDataplant || this.hover) && !this.hide) return true
      return false
    },
    canCreate () {
      if (this.type === 'dataplant') {
        const acl = this.config?.CMP ? this.organizationId : (this.currentService === 'iam' ? this.organizationId : true)
        return this.$acl(acl).can('core', 'organizations', 'create')
      }
      if (this.type === 'organization') return this.config?.CREATE_ORGANIZATION
      return true
    },
    disabledCreationTooltip () {
      if (this.type === 'dataplant' && !this.canCreate) return { content: this.$t('dashboard.access.tooltip'), delay: 0 }
      if (this.type === 'organization' && !this.canCreate) return { content: this.$t('organization.creation.no_access'), delay: 0 }
      return null
    }
  },
  watch: {
    hoverFromParent: {
      handler (val) {
        if (this.lightMode) return
        if ((!val && ((this.type === 'organization' && this.fromHome) || (this.type === 'dataplant' && this.fromDataplant))) || val === this.type) this.hide = false
        else if (val !== this.type) {
          this.hide = true
          this.opened = false
        }
      }
    },
    '$store.getters.CLICK_FROM_IFRAME': {
      handler () {
        this.close()
      }
    },
    async projectId (newId, oldId) {
      this.config = await Config()
    },
    '$route' (val) {
      this.hide = false
    }
  },
  async mounted () {
    this.config = await Config()
    window.addEventListener('click', this.close)
    if (!this.subscription && this.organization) await this.organization.loadSubscription()
  },
  destroyed () {
    window.removeEventListener('click', this.close)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    getProjectIconSettings (project) {
      return this.items.find(p => p.id === project.id)?.display_settings?.icon || {}
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    async open () {
      // Due to the click listener we need to delay the opening
      await this.$nextTick()
      this.opened = !this.opened
    },
    close ($event) {
      if (($event && this.isParent($event.target))) return
      this.opened = false
      this.search = ''
    },
    mouseOver () {
      if (this.type === 'organization' && !this.fromHome && !this.lightMode) this.hover = 'organization'
      if (this.type === 'dataplant' && !this.fromDataplant) this.hover = 'dataplant'
      this.$emit('hover', this.type)
    },
    mouseLeave () {
      if (this.lightMode) return
      if (!this.opened) {
        this.hover = false
        this.$emit('hover', null)
      }
    },
    dataplantsByOrganizationId (organizationId) {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId)
    },
    async newItem () {
      this.opened = false
      this.mouseLeave()
      if (this.type === 'dataplant') {
        if (!this.subscription) await this.organization.loadSubscription()
        if ((['active', 'non_renewing'].includes(this.subscription?.status)) || (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')) {
          if (!this.canCreate) return
          this.$analytics.track('Open new project funnel', { from: 'header' })
          this.$modal.show(DataplantCreationFullscreen, {
            organizationId: this.organization._id,
            afterConfirm: () => {
              this.$router.push(`home/${this.organization._id}`)
            }
          }, {
            height: '100%',
            width: '100%',
            adaptative: true,
            classes: []
          })
        } else {
          this.$modal.show(SubscriptionNew, {
            organizationId: this.organization._id,
            trial: this.organization?.subscription?.status === 'in_trial' && this.organization?.subscription?.scheduled_status === 'cancelled',
            onConfirm: () => this.newItem()
          }, {
            height: 'auto',
            width: 900
          })
        }
      } else {
        this.$router.push('/home/new')
      }
    },
    getLink (item) {
      let link = ''
      if (item.notReady) return

      if (this.type === 'dataplant') {
        link = `#/project/${item.id}`
      } else {
        if (this.fromOrganization && !this.requestToJoinPending(item)) link = `#/organization/${item.id}`
        else if (this.fromServices && !this.requestToJoinPending(item)) link = `#/home/${item.id}/services`
        else if (this.fromSupport && !this.requestToJoinPending(item)) link = this.config?.DATA_PLATFORM_ACCESS ? ovhSupportUrl : `#/support/${item.id}`
        else {
          if (close) this.$emit('hover', 'organization')
          link = `#/home/${item.id}`
        }
      }
      return link
    },
    async goToItem (item) {
      if (item.notReady) return
      this.opened = false
      this.mouseLeave()
      if (this.type === 'dataplant') {
        window.localStorage.setItem('tracking-from', JSON.stringify({
          from: 'header',
          fromOrganizationId: this.organizationId,
          fromProjectId: this.projectId
        }))
      } else {
        await this.$store.commit('SET_ACTIVE_ORGANIZATION', item._id)
        window.localStorage.setItem('tracking-from', JSON.stringify({
          from: 'header',
          fromOrganizationId: this.organizationId
        }))
      }
    },
    isActiveItem (item) {
      if (this.type === 'dataplant') return item?.id === this.projectId
      return item?.id === this.organizationId
    },
    requestToJoinPending (organization) {
      return organization.pending_request
    },
    onResize () {
      this.elementTop = this.$el?.getBoundingClientRect()?.top
    },
    goToOrganizationOrProject () {
      window.localStorage.setItem('tracking-from', 'header breadcrumb')
    }
  }
}
</script>

<style lang="less">
// Style in src/shared/styles/header.less

.dataplant-selector {
  float:left;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  user-select: none;
  color: #3E4550;
  position: relative;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
  border-radius: 5px;
  cursor: pointer;
  margin-right: 14px;

  ul, li {
    list-style: none;
    padding:0;
    margin:0;
    text-align: left;
  }
  .items {
    max-height: 427px;
    overflow: auto;
  }
  .item {
    height: 46px;
    cursor: pointer;
    padding-left: 10px;
    color: #3E4550;
    font-weight: 400;
    background:white;
    z-index: 2;
    position: relative;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 10px;
    .name-info-icon-container {
      display: flex;
      align-items: center;
      width: 100%;
      .project-icon {
        font-size: 24px;
        margin-right: 13px;
      }
      .name-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        .name-container {
          display: flex;
          align-items: center;
          .name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #3E4550;
          }
        }
        .info-container {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #97A7B7;
        }
      }
    }
    .fp4-arrow-right {
      display: none;
      color: #97A7B7;
      font-size: 20px;
    }
    .fp4-hourglass {
      color: #FFA312;
      font-size: 18px;
    }
    &:hover {
      background: rgba(246, 249, 252, 0.5);
      border-radius: 7px;
      .fp4-arrow-right {
        display: block;
      }

      .name-info-icon-container {
        width: calc(~"100% - 30px");
      }
    }
    &.active {
      color: #0089C0;
      background: #E2FAFF;
      border-radius: 6px;
    }
    &.disabled {
      cursor: not-allowed;
      .name {
        color: #97A7B7 !important;
      }
    }
  }
  .buttons {
    border-top: 1px solid #F6F9FC;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .button-container {
      display: inline-block;
      button {
        width: 100%;
      }
    }
  }
  .opened {
    position: absolute;
    top: 50px;
    border: 0px;
    min-width: 263px;
    width: 100%;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 7px;
    .search-container {
      padding: 10px 20px;
      border-bottom: 1px solid #F6F9FC;
    }


  }
}
</style>
