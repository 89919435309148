<template lang="pug">
.result.chart-display
  fp-loader(v-if="loading")
  .result-container(
    v-if="!hasNoResult && !error && !queryDoesntExist"
    :style="{ 'padding-top': !fullHeight ? '19px' : '0' }"
  )
    component(
      ref="chart"
      :is="chartDisplayed"
      :value="resultToDisplay"
      :sql="type === 'sql'"
      :key="chartMode"
      :active-drilldown="activeDrilldown"
      @click-chart="addDrilldown"
      @deactive-tile="deactiveTile"
    )

  .no-result(v-if="hasNoResult || error || queryDoesntExist")
    img(
      src='@/shared/assets/img/placeholder_preview_chart@2x.png'
    )
    .message(
      v-if="hasNoResult && !error && !queryDoesntExist"
    ) {{ $t('dashboard.result.empty') }}
    .message(
      v-if="queryDoesntExist && !error"
    ) {{ $t('dashboard.result.no_query') }}
    .message(
      v-if="error && !fromDashboard && !fromPreview"
    )
      a(:href="redirectToQuery()"  target="_blank" rel="noopener noreferrer") {{ $t('dashboard.result.error') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import pascalcase from 'pascalcase'

import Charts from '@/shared/components/query-builder/QueryPreview/Charts'
import Config from '@/shared/Config'
import DashboardConfig from '@/dashboard/config'

export default {
  components: {
    ...Charts
  },
  props: {
    result: { type: Object, default: () => null },
    chartMode: { type: String, default: 'table' },
    error: { type: String, default: '' },
    errorSummary: { type: Boolean, default: false },
    resultToDisplay: { type: Object, default: () => {} },
    type: { type: String, default: 'forepaas' },
    fullHeight: { type: Boolean, default: false },
    queryDoesntExist: { type: Boolean, default: false },
    dashboard: { type: Object, default: () => ({}) },
    activeDrilldown: { type: Boolean, default: false },
    query: { type: String, default: '' },
    fromDashboard: { type: Boolean, default: false },
    fromPreview: { type: Boolean, default: false }
  },
  data () {
    return {
      tooltipValues: null,
      filterOutElem: null,
      filterInElem: null,
      showValuesElem: null,
      breakdownFurtherElem: null,
      loading: false,
      config: null
    }
  },
  computed: {
    chartDisplayed () {
      const chart = `chart-${this.chartMode}`
      if (Object.keys(Charts).includes(pascalcase(chart))) return chart
      return 'chart-echarts'
    },
    hasNoResult () {
      if (this.chartMode === 'table') return false

      // TO OPTIMIZE en rajoutant les visuels si vide dans les types de charts directement
      return (!this.result || !this.result?.results.length)
    },
    tooltipId () {
      return this.resultToDisplay?.tooltipId
    },
    fullAttributes () {
      return this.$store.getters.DWH_ATTRIBUTES_FULL || {}
    },
    filterDisplayed () {
      return !!Object.keys(this.result.query_params.filter)?.length
    }
  },
  async mounted () {
    if (this.fromDashboard) this.config = DashboardConfig
    else this.config = await Config()
  },
  methods: {
    redirectToQuery () {
      const queries = this.$store.getters.QB_QUERIES_FILTERED
      let targetQuery = {}
      if (queries && queries.length) {
        targetQuery = this.$store.getters.QB_QUERIES_FILTERED.find(q => q.shared_id === this.query)
      }
      return !Object.keys(targetQuery)?.length ? '' : `/am/#/${this.$route.params.dataplantId}/query/${targetQuery._id}`
    },
    insertAfter (referenceNode, newNode) {
      referenceNode?.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    },
    addDrilldown (e) {
      this.tooltipValues = e

      const tooltipDisplayed = document.getElementById(`tooltip-drilldown-${this.tooltipId}`)
      if (tooltipDisplayed) {
        tooltipDisplayed.remove()
        this.tooltipValues = null
        // 'Filter out' and 'Filter on this' should be shown always except in Dashboard where there is no filters
        // Do 'removeEventListener' only when 'Filter out' and 'Filter on this' are on the UI. Otherwise there will be errors.
        if (!(this.fromDashboard && !this.filterDisplayed)) {
          this.filterOutElem.removeEventListener('click', this.filterOut)
          this.filterInElem.removeEventListener('click', this.filterIn)
        }
        this.showValuesElem.removeEventListener('click', this.showValues)
        this.breakdownFurtherElem.removeEventListener('click', this.breakdownFurther)
      } else {
        const tooltipElement = document.getElementById(`tooltip-echarts-${this.tooltipId}`)
        const el = document.createElement('div')

        const filterDiv = `
          <div class="category">${this.$t('dashboard.drilldown.filter_whole_dashboard')}</div>
          <div class="item" id="filter-out">
            <i class="fp4 fp4-filter-barred"></i>
            <span class="label">${this.$t('dashboard.drilldown.filter_out')}</span>
          </div>
          <div class="item" id="filter-in">
            <i class="fp4 fp4-filter-empty"></i>
            <span class="label">${this.$t('dashboard.drilldown.filter_on_this')}</span>
          </div>
        `
        const showDetailDiv = `
          <div class="category">${this.$t('dashboard.drilldown.more')}</div>
          <div class="item" id="show-values">
            <i class="fp4 fp4-eye"></i>
            <span class="label">${this.$t('dashboard.drilldown.show_these_values')}</span>
          </div>
          <div class="item" id="breakdown-further">
            <i class="fp4 fp4-magnifying-glass"></i>
            <span class="label">${this.$t('dashboard.drilldown.breakdown_further')}</span>
          </div>
        `

        // 'Filter out' and 'Filter on this' should be shown always except in Dashboard where there is no filters
        el.innerHTML = this.fromDashboard && !this.filterDisplayed
          ? `<div class="tooltip-drilldown no-filter" id="tooltip-drilldown-${this.tooltipId}">` + showDetailDiv + '</div>'
          : `<div class="tooltip-drilldown" id="tooltip-drilldown-${this.tooltipId}">` + filterDiv + showDetailDiv + '</div>'


        this.insertAfter(tooltipElement, el)

        if (tooltipElement) {
          // 'Filter out' and 'Filter on this' should be shown always except in Dashboard where there is no filters
          if (!(this.fromDashboard && !this.filterDisplayed)) {
          // Filter out action
            this.filterOutElem = document.getElementById('filter-out')
            this.filterOutElem.addEventListener('click', this.filterOut)

            // Filter on this action
            this.filterInElem = document.getElementById('filter-in')
            this.filterInElem.addEventListener('click', this.filterIn)
          }

          // Show these values action
          this.showValuesElem = document.getElementById('show-values')
          this.showValuesElem.addEventListener('click', this.showValues)

          // Breakdown further action
          this.breakdownFurtherElem = document.getElementById('breakdown-further')
          this.breakdownFurtherElem.addEventListener('click', this.breakdownFurther)
          this.$emit('active-tile', e)
        }
      }
    },
    async filterOut () {
      this.trackDrilldown('filter out')
      this.loading = true

      try {
        const query = _cloneDeep(this.$route.query)
        const resultClone = _cloneDeep(this.result)
        const scales = this.result.query_params.scale.fields
        const scalesResult = resultClone?.results[this.tooltipValues?.dataIndex]?.scales || {}

        await Promise.all(scales.map(async s => {
          query[s] = `not_equal-"${scalesResult[s]}"`
          await this.createFilter(s, 'not_equal', scalesResult[s])
        }))

        this.$router.push({
          path: this.$route.path,
          query
        })
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false

      if (this.$refs?.chart) this.$refs.chart.handleDrilldown()
    },
    async filterIn () {
      this.trackDrilldown('filter')
      this.loading = true

      try {
        const query = _cloneDeep(this.$route.query)
        const resultClone = _cloneDeep(this.result)
        const scales = this.result.query_params.scale.fields
        const scalesResult = resultClone?.results[this.tooltipValues?.dataIndex]?.scales || {}

        await Promise.all(scales.map(async s => {
          query[s] = `equal-"${scalesResult[s]}"`
          await this.createFilter(s, 'equal', scalesResult[s])
        }))

        this.$router.push({
          path: this.$route.path,
          query
        })
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false

      if (this.$refs?.chart) this.$refs.chart.handleDrilldown()
    },
    async createFilter (attribute, condition, value) {
      // It won't create and save filter for deployed dashboard mode because we do not want to overwrite the classic dashboard filter by editing deployed dashboard filter.
      if (!this.fromDashboard) {
        const queryName = attribute
        let query = this.$store.getters.QB_QUERIES.find(q => q.tags?.path === `Automatically saved from dashboards/${this.dashboard.name}/Filters` && q.display_name === queryName)
        if (!query) {
          query = this.$api.QUERY_ADMIN.queries.new({
            tags: {
              path: `Automatically saved from dashboards/${this.dashboard.name}/Filters`
            },
            repository: this.$store.getters.QB_REPOSITORY_ACTIVE?._id,
            repository_version: this.$store.getters.QB_REPOSITORY_ACTIVE?.editingVersion?._id,
            name: `${this.dashboard.name}_${queryName}`,
            display_name: queryName,
            configuration: {
              data: {
                fields: {
                  [attribute]: ['select_distinct']
                }
              }
            }
          })
          query = await query.create()
          this.$store.commit('FOREPAAS_QB_QUERY_CREATE', query)
        }

        this.dashboard.update('filter', {
          ...this.dashboard.filter,
          [attribute]: {
            type: this.getFilterType(attribute),
            query: query.shared_id,
            [condition]: value
          }
        })
      }
    },
    getFilterType (attribute) {
      const type = this.fullAttributes[attribute]?.type?.main
      if (!type) return 'categorical'

      if (['date'].includes(type)) return 'date'
      if (['datetime'].includes(type)) return 'datetime'
      if (['bool'].includes(type)) return 'boolean'

      return 'categorical'
    },
    showValues () {
      this.trackDrilldown('explore values')

      const data = this.tooltipValues?.seriesName?.split(' ')
      if (data.length) {
        const dataUsed = data.length === 1 ? data[0] : data[1]
        const computedUsed = 'select'

        const filter = this.generateFilterFromScales(this.result)
        const query = {
          data: {
            fields: {
              [dataUsed]: [computedUsed]
            }
          },
          filter
        }

        this.redirectNewQuery(query)
      }

      if (this.$refs?.chart) this.$refs.chart.handleDrilldown()
    },
    breakdownFurther () {
      this.trackDrilldown('breakdown')

      const filter = this.generateFilterFromScales(this.result)
      const orignalQuery = _cloneDeep(this.result.query_params) || {}
      const query = {
        ...orignalQuery,
        filter: {
          ...orignalQuery.filter,
          ...filter
        }
      }
      if (query.order && Array.isArray(query.order)) { // Order is an array here and we need to format it to object
        const newOrder = {}
        query.order.forEach(o => {
          if (Array.isArray(o) && o.length === 2) newOrder[o[0]] = o[1]
        })
        query.order = newOrder
      }

      this.redirectNewQuery(query)

      if (this.$refs?.chart) this.$refs.chart.handleDrilldown()
    },
    generateFilterFromScales (result) {
      const scales = result?.results[this.tooltipValues?.dataIndex]?.scales || {}
      const filter = {}
      Object.keys(scales).forEach(s => {
        filter[s] = {
          equal: [scales[s]]
        }
      })

      return filter
    },
    redirectNewQuery (query) {
      window.localStorage.setItem('show-values-from-dashboard', JSON.stringify(query))
      const url = `${this.config.FPUI}/am/#/${this.config.PROJECT_ID}/query` +
        `?repository=${this.$store.getters.QB_REPOSITORY_ACTIVE?._id}` +
        `&repository_version=${this.$store.getters.QB_REPOSITORY_ACTIVE?.editingVersion?._id}` +
        '&queryFromDashboard=true'

      window.open(url, '_blank')
    },
    trackDrilldown (type) {
      this.$analytics.track('Drill-down on dashboard', {
        shared_id: this.dashboard.shared_id,
        type
      })
    },
    deactiveTile (e) {
      this.$emit('deactive-tile')
    }
  }
}
</script>

<style lang="less">
  .result {
    height: 100%;
    width: 100%;
    .result-container {
      height: 100%;
      width: 100%;
    }

    .no-result {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: calc(~"100% - 80px");
        max-width: 230px;
        margin-bottom: 20px;
      }
      .message {
        font-size: 14px;
        line-height: 18px;
        color: @grey-chart;
        text-align: center;
        a {
          cursor: pointer;
          text-decoration: underline;
          color: inherit;
          &:hover {
            color: #0089c0 !important;
          }
        }
      }
    }
  }

  .tooltip-drilldown {
    &.no-filter{
      height: 120px !important;
    }
    height: 232px;
    border-top: 1px solid #E4E7EC;
    margin-left: -20px;
    margin-right: -20px;
    font-family: 'Source Sans Pro', sans-serif;

    .category {
      height: 28px;
      padding: 10px 20px 5px;
      font-weight: 600;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
      color: #97A7B7;
    }
    .item {
      height: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 20px;
      margin: 0;

      i {
        font-size: 20px;
        color: #CBD3DB;
      }

      .label {
        margin-left: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #3E4550;
        padding: 0;
      }

      &:hover {
        background: rgba(246, 249, 252);
        box-shadow: inset 0px -1px 0px #F6F9FC;
        font-weight: 400;
        i {
          color: #0089c0;

          &.fp4-filter-empty {
            color: #0089c0;
          }
        }
      }

      &#breakdown-further {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
</style>
