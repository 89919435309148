<template lang="pug">
form.organization-login
  img.logo(:src="logo")
  fp-loader(v-if="!config || loading")
  .inner-content(v-else)
    fp-loader.inner-content.organization-connecting(v-if="connecting")
    h1 {{ title }}
    p.message {{ message }}
    .sso-message(v-if='authModes.length === 1 && !$store.getters.NEED_ORGANIZATION_MFA')
      i.fp4.fp4-lock-keyhole
      span {{ $t('authentication.login.organization.sso.title') }}
    .organization-mfa(v-if='$store.getters.NEED_ORGANIZATION_MFA')
      .mfa-button.sso-auth(
        @click="openUserProfileMfa($event)"
      )
        i.fp4.fp4-circle-user
        span {{ $t('authentication.login.organization.mfa_activate') }}
    .organization-auth-modes(v-else)
      .auth-mode(
        v-for="mode in authModes"
        :class="{ 'forepaas-auth': mode.type === 'forepaas', 'sso-auth': authModes.length === 1 }"
        @click="openAuthMode($event, mode)"
      )
        img(
          v-if="mode.type === 'forepaas'"
          :src="require('@/shared/components/authentication/assets/ovh.png')"
        )
        span {{ mode.type === 'forepaas' ? $t('authentication.oauth_provider.signin_with', ['Data Platform']) : $t('authentication.login.organization.enterprise_login') }}
    .organization-login-footer
      div {{ $t('authentication.login.organization.footer.title') }}
      .back-or-logout
        a(@click="backToOrganizationList") {{ $t('authentication.login.organization.footer.back') }}
        div {{ $t('organization_selector.or') }}
        a(@click="logout") {{ $t('organization_selector.logout') }}
</template>

<script>
import Config from '@/shared/Config'
import _sortBy from 'lodash/sortBy'

export default {
  data () {
    return {
      config: null,
      loading: true,
      connecting: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.config?.ORGANIZATION_ID || this.$route.params.organizationId)
    },
    authModes () {
      let authModes = []
      if (this.$store.getters.ORGANIZATION_AUTH_MODES) authModes = this.$store.getters.ORGANIZATION_AUTH_MODES(true)
      else {
        authModes = this.$store.getters.PREFERENCES.auth_mode.filter(authMode => {
          return authMode.visible && !authMode.hidden
        })
      }

      return _sortBy(authModes, (a) => a.type !== 'forepaas')
    },
    logo () {
      return require('@/shared/assets/img/ovhcloud_dataplatform_logo.svg')
    },
    title () {
      return this.$t('authentication.login_organization.title')
    },
    message () {
      if (this.$store.getters.NEED_ORGANIZATION_MFA) {
        return this.$t('authentication.login_organization.message.mfa_required', [this.organization?.name])
      }
      return this.organization?.name ? this.$t('authentication.login_organization.message', [this.organization?.name]) : this.$t('authentication.login_organization.message.no_organization', [this.organization?.name])
    },
    ready () {
      return !this.errors.length
    }
  },
  async mounted () {
    this.config = await Config()
    this.$store.commit('REMOVE_ACTIVE_ORGANIZATION')

    if (!this.$store.getters.ORGANIZATIONS.length) await this.$store.dispatch('LOAD_ORGANIZATIONS')
    // Check if user can go to this organization
    if (this.$store.getters.ORGANIZATION_BY_ID(this.config.ORGANIZATION_ID)) {
      this.$store.commit('SET_ACTIVE_ORGANIZATION', this.config?.ORGANIZATION_ID)
      this.loading = false
    } else {
      window.open('/', '_self')
    }
  },
  methods: {
    async openAuthMode ($event, authMode) {
      $event.preventDefault()
      this.connecting = true
      try {
        if (authMode.type === 'forepaas') {
          const { token } = await this.$api.KING.users.me({ noOrganizationId: true })
          await this.$api.KING.reply(token)
        } else {
          return await this.$api.FPAPI.loginRedirect(authMode, false, 'forepaas', this.config?.ORGANIZATION_ID)
        }
        this.$store.commit('SET_NEED_ORGANIZATION_LOGIN', false)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      } finally {
        this.connecting = false
      }
    },
    openUserProfileMfa ($event) {
      $event.preventDefault()
      this.$store.commit('SET_ORGANIZATION_LOADING', true)
      document.location.href = `${window.location.origin}/#/user?tab=security&mfa_redirect=${this.config?.ORGANIZATION_ID}`
      this.$store.commit('REMOVE_ACTIVE_ORGANIZATION')
      this.$store.commit('SET_NEED_ORGANIZATION_LOGIN', false)
      document.location.reload()
    },
    backToOrganizationList () {
      this.$store.commit('REMOVE_ACTIVE_ORGANIZATION')
      this.$store.commit('SET_NEED_ORGANIZATION_LOGIN', false)
      document.location.href = window.location.origin
    },
    logout () {
      this.$store.commit('SET_OPEN_SIDE_BAR', false)
      this.$store.dispatch('LOGOUT')
    }
  }
}
</script>

<style lang="less">
  .authentication .content {
    .organization-login {
      .inner-content {
        h1 {
          margin-bottom: 10px;
          font-size: 38px;
        }
        p {
          margin-top: 0;
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: 300;
          &.message {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            color: #748294;
          }
        }
        > .sso-message {
          display: flex;
          font-size: 13px;
          color: #97A7B7;
          align-items: center;
          justify-content: center;
          padding-bottom: 10px;
          i {
            font-size: 20px;
            padding-right: 3px;
          }
        }
        .organization-auth-modes, .organization-mfa {
          display: flex;
          align-items: center;
          gap: 10px;
          .auth-mode, .mfa-button {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 1 100%;
            height: 34px;
            padding: 9px 10px;
            font-size: 13px;
            gap: 10px;
            color: #97A7B7;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 5px;
            border: 1px solid #E9ECF0;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.03);
            cursor: pointer;
            &.forepaas-auth {
              background: #0050D7;
              color: #FFF;
              font-weight: 700;
            }
            &.sso-auth {
              background-color: @blue_flash;
              color: #FFF;
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
        .organization-login-footer {
          margin-top: 10px;
          text-align: center;
          padding: 10px;
          color: #B2BECA;
          font-size: 12px;
          font-weight: 400;
          .back-or-logout {
            padding-top: 10px;
            display: flex;
            justify-content: center;
            gap: 5px;
          }
          a {
            cursor: pointer;
            color: @blue;
            font-weight: 600;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      img.logo {
        max-height: 200px;
        max-width: 200px;
      }
    }
  }
</style>
