<template lang="pug">
.fpui-input-select-container(
  :id="customId"
)
  fpui-input-label(
    v-if="label || icon",
    :helper="helper"
    :helper-title="helperTitle"
    :required="required"
    :class="{ 'with-sublabel': subLabel }"
  )
    i.fp4(v-if="icon" :class="[icon]")
    | {{ $t(label) }}
  .content
    .sublabel(v-if="subLabel") {{ subLabel }}
    slot(
      v-if="($scopedSlots.content)"
      name="content",
      :value="value",
      :open="open"
    )
    .default(
      v-else
      :class="classes"
      v-tooltip="valueTooltip"
    )
      .fpui-input-select(
        @click="open"
        :class="classes"
        :style="{ 'padding-right': iconOnly ? '17px' : clearable && ((!multiple && value) || (multiple && value.length)) ? '50px' : '40px' }"
      )
        i.icon(
          v-if="iconElement"
          :class="iconElement"
        )
        img.image(
          v-if="imageElement"
          :src="imageElement"
          :class="imageElement"
        )
        span.select-label(
          v-if="!multiple || (multiple && Array.isArray(value) && value.length <= 1)"
          :class="{ placeholder: !value }"
          :style="{ overflow: subvalueFormat ? 'unset' : 'hidden' }"
          v-html="$sanitize($t(valueFormat))"
        )
        span.select-label(
          v-if="multiple && Array.isArray(value) && value.length > 1"
        ) {{ $t('fpui.input.select.multiple', [value.length]) }}
        span.select-sublabel(v-if="subvalueFormat && showSelectedSublabel") {{subvalueFormat}}
        i.fp4.fp4-angle-down(
          :style="{ right: clearable && ((!multiple && value) || (multiple && value.length)) && !disabled ? '30px' : '10px' }"
        )
      i.fp4.fp4-circle-xmark(
        v-if="clearable && ((!multiple && value) || (multiple && value.length)) && !disabled"
        @click.stop="removeSelection"
      )

    .dropdown(
      v-if="openState"
      :class="[dropdownClasses, { 'input-design': !isFilter}]"
      :style="{ right: ($scopedSlots.content || widthFit) ? 0 : '' }"
    )
      .search-container(
        v-if="searchable"
      )
        fpui-input-search(
          ref="search"
          v-model="search"
          autofocus
          :placeholder="searchPlaceholder"
          @enter="confirm"
        )
      ul
        li(
          v-for="option in filtered"
          v-tooltip="{ content: option.tooltip, delay: option.disabled ? 0 : 750 }"
          :disabled="option.disabled"
          :class="[{ active: isActive(option), disabled: option.disabled, multiple }, option.class]"
          :style="option.style"
          @click.prevent.stop="select(option)"
        )
          fpui-input-checkbox(
            v-if="multiple"
            :value="isActive(option)"
            @input="select(option)"
          )
          i.icon(
            v-if="option.icon"
            :class="option.icon"
          )
          img.image(
            v-if="option.image"
            :src="option.image"
            :class="option.image"
          )
          span(v-if="option.label && isNaN(option.label)")
            span.select-label(
              :class="customLabelClass"
              :id="option.id"
              v-tooltip="optionTooltip(option)"
              v-html="$sanitize(optionLabel(option))"
            )
            span.select-sublabel(
              v-if="option.sublabel"
              v-tooltip.left-start="option.sublabel"
            ) {{ option.sublabel | translate }}
          span(
            v-else
            v-html="$sanitize(option.label)"
          )
        li.empty(
          v-if="!create && !filtered.length && !$scopedSlots.content"
        ) {{empty | translate}}
        li(v-if="create && search && !searchByLabel(search)" @click="$emit('create', search)")
          span.new
            span.select-label(:class="{ placeholder: !search }") {{ createPlaceholder || search || $t('fpui.input.select.add.placeholder') }}
            span.select-new
              i.fp4-plus.fp4
              span {{ create | translate }}
</template>

<script>
import _isEqual from 'lodash/isEqual'
export default {
  props: {
    create: { type: String, default: null },
    createPlaceholder: { type: String, default: null },
    placeholder: { type: String, default: 'fpui.input.select.placeholder' },
    helper: { type: String, default: null },
    helperTitle: { type: String, default: '' },
    required: { type: Boolean, default: false },
    label: { type: String, default: null },
    valueTooltip: { type: [String, Object], default: null },
    subLabel: { type: String, default: null },
    showSelectedSublabel: { type: Boolean, default: true },
    icon: { type: String, default: null },
    iconLabel: { type: String, default: null },
    empty: { type: String, default: 'fpui.input.select.empty' },
    direction: { type: String, default: 'auto' },
    options: { type: Array, default: () => [] },
    htmlOptions: { type: Boolean, default: false },
    value: { type: [Array, String, Number, Object, Boolean], default: '' },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    searchable: { type: Boolean, default: true },
    searchPlaceholder: { type: String, default: '' },
    customValueFormat: { type: String, default: 'label' },
    widthFit: { type: Boolean, default: true },
    isFilter: { type: Boolean, default: false }, // Use to apply specific design if filter selectbox
    autoi18n: Boolean, // Automatically translate tooltip & label options
    iconOnly: { type: Boolean, default: false },
    customId: { type: String, default: null },
    customLabelClass: { type: String, default: null }
  },
  data () {
    return {
      openState: false,
      search: '',
      elementTop: 0
    }
  },
  computed: {
    isAvailableCurrentName () {
      if (!this.search) return false
      const opt = this.searchByLabel(this.search)
      if (opt) return false
      return true
    },
    iconElement () {
      if (this.multiple) {
        return this.iconLabel
      }
      return this.options.find(opt => this.isEqual(opt.value, this.value))?.icon || this.iconLabel
    },
    imageElement () {
      if (this.multiple) {
        return null
      }
      return this.options.find(opt => this.isEqual(opt.value, this.value))?.image
    },
    valueFormat () {
      if (this.multiple) {
        if (!this.value.length) return this.$t(this.placeholder)
        return this.value.map(v => isNaN(v) ? this.$t(this.options.find(opt => this.isEqual(opt.value, v))?.label) : this.options.find(opt => opt.value === v).label).join(', ')
      }
      return this.$t(this.options.find(opt => this.isEqual(opt.value, this.value))?.[this.customValueFormat]) || this.$t(this.placeholder)
    },
    subvalueFormat () {
      if (this.multiple) { return null }
      if (!this.value) return null
      return this.$t(this.options.find(opt => this.isEqual(opt.value, this.value))?.sublabel)
    },
    dropdownClasses () {
      if (this.direction !== 'auto') return this.direction
      const view = document.querySelector('.view')?.getBoundingClientRect()
      if (!view) return 'bottom'
      let dropdownHeight = this.filtered.length * 43 + 20
      if (dropdownHeight > 300) dropdownHeight = 300
      const height = dropdownHeight + 58
      if (this.elementTop - view.top + height > view.height) return 'top'
      return 'bottom'
    },
    classes () {
      const classes = []
      if (this.disabled) classes.push('disabled')
      if (this.multiple) classes.push('multiple')
      if (this.multiple && this.value.length) classes.push('multiple-selected')
      if (this.imageElement) classes.push('image')
      if (!this.isFilter) classes.push('input-design')
      return classes.join(' ')
    },
    filtered () {
      return this.options.filter(value => {
        if (value.if === false) return false
        if (!this.search) return true
        if (value.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
        if (typeof value.value === 'string' && value.value.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
        if (value.sublabel && value.sublabel.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
        return false
      })
    }
  },
  mounted () {
    window.addEventListener('click', this.close)
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed () {
    window.removeEventListener('click', this.close)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    searchByLabel (label = '') {
      return this.options.find(o => (o.label || '').toLowerCase() === label.toLowerCase())
    },
    confirm () {
      if (!this.search) return
      const opt = this.searchByLabel(this.search)
      const autoCreate = { value: this.search }
      this.select(opt || autoCreate)
      if (this.create) {
        this.$emit('create', this.search)
        this.close()
      }
    },
    isEqual (val1, val2) {
      if (typeof (val1) === 'object') return _isEqual(val1, val2)
      return val1 === val2
    },
    isActive (option) {
      if (!this.multiple) return this.isEqual(option.value, this.value)
      return this.value.includes(option.value)
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.openState = false
      this.search = ''
    },
    open () {
      if (this.disabled) return
      if (!this.openState) {
        this.openState = true
        process.nextTick(_ => {
          if (this.$refs.search?.focus) this.$refs.search.focus()
        })
      } else {
        this.openState = false
      }
    },
    removeSelection () {
      if (this.multiple) this.$emit('input', [])
      else this.$emit('input', '')
    },
    select ({ value, disabled }) {
      if (disabled) return
      if (!this.multiple) {
        this.$emit('input', value)
        this.close()
        return
      }
      this.$emit('select', value)
      const newValue = [...this.value]
      const idx = newValue.indexOf(value)
      if (idx === -1) {
        newValue.push(value)
      } else {
        newValue.splice(idx, 1)
      }
      this.$emit('input', newValue)
    },
    onResize () {
      this.elementTop = this.$el?.getBoundingClientRect()?.top
    },
    optionTooltip (option) {
      const output = option.tooltip ? '' : option.label
      return this.autoi18n ? this.$t(output) : output
    },
    optionLabel (option) {
      return this.autoi18n ? this.$t(option.label) : option.label
    }
  }
}
</script>

<style lang="less">
.fpui-input-select-container {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #97a7b7;
  font-weight: 600;
  text-transform: uppercase;

  .fpui-input-label-container {
    text-transform: none;
    font-weight: 400;
    font-size: 12px;
  }

  .content {
    position: relative;
    .sublabel {
      margin-bottom: 5px;
      font-size: 12px;
      color: #97a7b7;
    }
    .default {
      position: relative;
      .disabled {
        border: unset !important;
        cursor: not-allowed !important;
        color: rgba(62,69,80,0.65) !important;
      }
      .fpui-input-select {
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        height: 34px;
        display: flex;
        align-items: center;
        padding: 0px 30px 0 11px;
        line-height: 32px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        margin: 0px;
        border: 1px solid #E9ECF0;
        background-color: white;
          > .icon {
            font-size: 24px;
            margin-right: 10px;
          }
          > .image {
            width: 25px;
            margin-right: 10px;
          }
        span.select-sublabel {
          color: #B2BECA;
          margin-left: 6px;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .fp4-circle-xmark {
          color: #97A7B7;
        }
        .icon {
          font-size: 20px;
        }
        .image {
          width: 25px;
          margin-right: 10px;
        }
        span,.icon {
        }
        .fp4.fp4-angle-down {
          position: absolute;
          right: 10px;
          top: 8px;
          font-size: 18px;
        }

        &.image {
          display: flex;
          align-items: center;
        }

        &:hover {
          background: #FAFAFA;
        }

        .select-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.placeholder {
            color: #97a7b7;
          }
        }

        &.multiple-selected {
          background: #97A7B7;
          color: white;

          .fp4-circle-xmark {
            color: #CBD3DB;
          }
        }

        &.input-design {
          color: #3e4550;
          font-weight: 400;
          text-transform: initial;
          background: rgba(151, 167, 183, 0.06);
          border-radius: 3px;
          box-shadow: none;
        }
      }
      .fp4-circle-xmark {
        position: absolute;
        right: 10px;
        top: 8px;
        font-size: 18px;
        cursor: pointer;
      }

      &.multiple-selected {
        .fp4-circle-xmark {
          color: #CBD3DB;
        }
      }
    }
    .dropdown {
      position: absolute;
      box-shadow: 0 2px 10px 0 rgba(62,69,80,0.25);
      background-color: #FFFFFF;
      border-radius: 5px;
      left: 0;
      top: 0;
      z-index: 11;
      min-width: 100%;
      &.top {
        top: auto;
        bottom: 0;
      }
      .search-container {
        padding: 10px 20px;
        border-bottom: 1px solid #F6F9FC;
        width: 100%;
        .fpui-input-search {
          margin: 0 !important;
          width: 100% !important;
          .fpui-input-text {
            margin: 0 !important;
            width: 100% !important;
          }
        }
      }
      ul {
        margin:0;
        padding:0;
        overflow: auto;
        overflow: overlay;
        max-height: 404px;
        li {
          display: flex;
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 12px 20px;
          list-style: none;
          position: relative;
          border-bottom: 1px solid #F6F9FC;
          color: #3E4550;
          font-weight: 400;
          letter-spacing: -0.01em;
          > .icon {
            font-size: 24px;
            font-weight: 400;
          }
          > .image {
            width: 25px;
            margin-right: 10px;
          }
          &:last-of-type {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-bottom: none;
          }
          &:hover {
            background: rgba(246, 249, 252);
            box-shadow: inset 0px -1px 0px #F6F9FC;
            font-weight: 400;
            cursor: pointer;
          }
          &.disabled {
            border: unset !important;
            cursor: not-allowed !important;
            color: rgba(62,69,80,0.65);
          }
          &.active:not(.multiple) {
            color:#0089C0;
            font-weight: 400;
          }
          &.empty {
            font-weight: normal;
            color:#3e4550;
            background:white;
            line-height: 30px;
            cursor: default;
          }
          .fpui-input-checkbox {
            margin-right: 0;
            top: 5px;
            .check {
              background: white;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
            }
            .fp-checkbox {
              top: 0;
              height: 20px;
            }
          }
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span.select-sublabel {
            color: #B2BECA;
            margin-left: 6px;
            font-size: 14px;
          }
          span.new {
            display: flex;
            align-items: center;
            .select-label {
              flex: 1;
              margin-right: 10px;
              &.placeholder {
                color: rgba(62, 69, 80, 0.25);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            span.select-new {
              display: flex;
              align-items: center;
              color:#00CCF9;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              float:right;
            }
          }
        }
      }

      &.input-design {
        li {
          text-transform: initial;
        }
      }
    }
  }
}
</style>
