<template lang="pug">
  form(@submit="validateOTP($event)").mfas.login
    fp-loader(v-if="loading")
    img.logo(:src="logo")
    h1 {{ $t('authentication.mfa.title') }}
    .mfa-method-select(v-if="showMfaOptionSelector")
      .description
        div {{ $t('authentication.mfa.description') }}
        div {{ $t('authentication.mfa.description2') }}
      .mfa-select(:style="{ 'justify-content': isMfaMethodMoreThanOne ? 'space-between' : 'center'}")
        .mfa(
          v-for="mfa in modes"
          :class="{active:current === mfa}"
          :key="mfa"
          v-tooltip="tooltip(mfa)"
          :disabled="isSmsNoPhone(mfa)"
          :style="{'cursor':isSmsNoPhone(mfa)?'not-allowed':'pointer','opacity':isSmsNoPhone(mfa)?0.5:1}"
          @click="select(mfa)"
        )
          i(:class="icons(mfa)")
          span {{ $t('authentication.mfa.'+mfa) }}
      component(
        :is="current"
        v-if="current",
        v-model="otp"
        @input="val => authAppCode = val"
        @send-sms="alreadySentSms = true"
      )
    .input-backup-code(v-if="!showMfaOptionSelector")
      .input-wrapper
        fpui-input-text(
          :label="$t('authentication.backup.label')"
          :value="backupCode"
          name="backup-code-input"
          @input="val => backupCode = val.replaceAll(' ', '')"
        )
        fpui-button(
          v-if="!showMfaOptionSelector"
          color="green"
          :disabled="backUpCodeSendButtonDisabled"
        ) {{ $t('authentication.backup.send')  }}

    .mfa-actions
      .submit
        fpui-button(
          v-if="authenticatorSelected || smsAuthSelected"
          color="green"
          :disabled="signInButtonDisabled"
        ) {{ $t('authentication.signin') }}
      .link(v-if="showMfaOptionSelector")
        a.no-access(
          @click="showMfaOptionSelector = false"
        ) {{ $t('authentication.no_access') }}
      .buttons(:style="{ 'justify-content' : showMfaOptionSelector ? 'start' : 'space-between' }")
        fpui-button.logout(
          color="blue-flash"
          auto-width
          @click="logout"
        ) {{ $t('authentication.logout') }}
        fpui-button(
          v-if="!showMfaOptionSelector"
          color="dark-blue"
          auto-width
          @click="showMfaOptionSelector = true"
        ) {{ $t('authentication.go_back')  }}

</template>

<script>
import pascalcase from 'pascalcase'
import mfas from './mfas'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  components: mfas,
  data () {
    return {
      current: null,
      otp: '',
      loading: false,
      showMfaOptionSelector: true, // if true, show the page to select authenticate method. if false, show the page to input backup code
      backupCode: '',
      authAppCode: '',
      alreadySentSms: false
    }
  },
  computed: {
    color () { return this.$store.getters.PREFERENCES.color || '#00ccf9' },
    mfa () { return this.$store.getters.PREFERENCES.mfa },
    modes () {
      const mfa = []
      if (this.mfa.authenticator && this.user.mfa?.authenticator?.active) mfa.push('authenticator')
      // TODO: Uncomment when the email option is available
      // if (this.mfa.email) mfa.push('email')
      if (this.mfa.sms && this.user.mfa?.sms?.active) mfa.push('sms')
      return mfa
    },
    isMfaMethodMoreThanOne () {
      return this.modes?.length > 1
    },
    logo () {
      const defaultLogo = require('@/shared/assets/img/ovhcloud_dataplatform_logo.svg')
      return this.$store.getters.PREFERENCES?.logo || defaultLogo || ''
    },
    message () {
      return this.$store.getters.PREFERENCES.message || this.$store.getters.PREFERENCES.description
    },
    signInButtonDisabled () {
      if (this.showMfaOptionSelector && this.otp?.length === 6) return false
      return true
    },
    backUpCodeSendButtonDisabled () {
      if (!this.showMfaOptionSelector && this.backupCode?.length === 12) return false
      return true
    },
    authenticatorSelected () {
      return this.current === 'authenticator' && this.current !== null && this.showMfaOptionSelector
    },
    smsAuthSelected () {
      return this.current === 'sms' && this.alreadySentSms && this.showMfaOptionSelector
    },
    user () {
      return this.$store.getters.SESSION
    }
  },
  methods: {
    async validateOTP ($event) {
      $event.preventDefault()
      if (this.loading) return
      // Need to have 'return' here in the cases above because we use the form tag and it will launch the event even we have disabled property
      if ((this.authenticatorSelected || this.smsAuthSelected) && this.signInButtonDisabled) return
      if (!this.showMfaOptionSelector && this.backUpCodeSendButtonDisabled) return

      try {
        if (this.showMfaOptionSelector) { // If it is either Authenticator or sms
          this.loading = true
          await this.$store.dispatch('VALIDATE_MFA_CODE', {
            type: this.current,
            otp: this.otp
          })
        } else { // If it is backup
          const confirm = await ConfirmModal(this, {
            title: this.$t('authentication.backup.modal.title'),
            content: this.$t('authentication.backup.modal.content'),
            yes: {
              text: this.$t('authentication.backup.send'),
              color: 'red'
            }
          })
          if (confirm) {
            this.loading = true
            await this.$store.dispatch('RESET_MFA_CODE', { code: this.backupCode })
            this.$fpuiMessageBlock.success(this.$t('authentication.backup.success'))
            // call timeout to wait a little second to let user read the banner message above
            await this.timeout()
            await this.$store.dispatch('SESSION_INIT')
          }
        }
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        this.loading = false
      }
    },
    async timeout () {
      return await new Promise(resolve => setTimeout(resolve, 2000))
    },
    logout ($event) {
      $event.preventDefault()
      this.$store.dispatch('LOGOUT')
      this.$emit('logout-done') // Send logout done for not doing LOGOUT_CMP in the logout.vue. It causes an error.
    },
    isSmsNoPhone (mfa) {
      if (mfa === 'sms' && !this.$store.getters.SESSION.phone) return true
      return false
    },
    select (mfa) {
      if (this.isSmsNoPhone(mfa)) return
      this.current = mfa
    },
    tooltip (mfa) {
      if (this.isSmsNoPhone(mfa)) {
        const ret = {
          content: this.$t('authentication.mfa.no-phone'),
          delay: {
            show: 0
          }
        }
        return ret
      }
      return ''
    },
    icons (mfa) {
      return mfas[pascalcase(mfa)].icon
    }
  }
}
</script>

<style lang="less">
.mfas{
  .description {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.5;
  }
  .mfa-button {
    height: 34px;
    width: 170px;
    line-height: 32px;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.07), 0 5px 8px 0 rgba(0,0,0,.14);
    border: none;
    cursor: pointer;
  }
  .mfa-select {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .mfa {
      cursor: pointer;
      border-radius: 5px;
      width: 230px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size:14px;
      border: 0.5px solid rgba(151, 167, 183, 0.21);
      box-shadow: 0 2px 4px 0 rgba(52, 65, 78, 0.05);
      i {
        font-size: 40px;
      }
      &.active {
        border: 2px solid #0089c0;
        color: #0089c0;
      }
    }
  }
  .input-backup-code {
    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fpui-input-text {
        width: 100%;
        .fpui-input-label-container {
          cursor: auto;
        }
      }
      .button-container{
        margin-left: 10px;
      }
    }
  }
  .mfa-actions {
    .submit {
      display: flex;
      justify-content: center;
    }
    .link {
      margin-top: 30px;
      a {
        color: #B2BECA;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: @blue;
        }
      }
    }
    .buttons {
      display: flex;
      margin-top: 15px;
    }
  }
}
</style>
