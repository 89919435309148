import { render, staticRenderFns } from "./FpuiInputRadio.vue?vue&type=template&id=3479a452&lang=pug&"
import script from "./FpuiInputRadio.vue?vue&type=script&lang=js&"
export * from "./FpuiInputRadio.vue?vue&type=script&lang=js&"
import style0 from "./FpuiInputRadio.vue?vue&type=style&index=0&id=3479a452&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports