import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _findIndex from 'lodash/findIndex'
import _maxBy from 'lodash/maxBy'
import Date from '@/shared/filters/Date'
import Version from './Version'
let repositoryOrderIndex = 0

class Repository extends APIObject {
  constructor (options) {
    // Init
    super('DPE', options)

    this.display_name = this.display_name || ''
    this.description = this.description || ''
    this.type = this.type || 'forepaas'
    this.params = this.params || {}
    this.versions = (this.versions && this.versions.map(version => new Version(version))) || []
    this.tags = this.tags || {}
    this.tags.order = typeof (this.tags.order) !== 'undefined' ? this.tags.order : repositoryOrderIndex++
    this.created_by = this.created_by || null
    this.updated_by = this.updated_by || null
    this.commits_behind = this.commits_behind || 0

    this._editingVersion = null
    this._git_link = this._git_link || false
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  _filter (object) {
    return _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by'
    ])
  }

  async create (type = 'forepaas', params = {}) {
    this.version = { display_name: 'v1' }
    this.type = type
    this.params = params
    return super.create({
      method: 'POST',
      url: 'v3/repositories'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v3/repositories/${this._id}`
    })
  }

  remove () {
    return this.request({
      method: 'DELETE',
      url: `v3/repositories/${this._id}`
    })
  }

  newVersion (options) {
    return new Version(options)
  }

  removeVersion (versionId) {
    const idx = _findIndex(this.versions, { _id: versionId })
    if (idx === -1) return
    this.versions.splice(idx, 1)
  }

  applyVersion (version) {
    if (version.active) {
      const oldActiveVersion = this.activeVersion
      oldActiveVersion.active = false
    }
    this.versions = this.versions.filter(v => v._id !== version._id)
    this.versions.push(new Version(version))
    this.editingVersion = version._id
  }

  async createVersion (version) {
    return this.request({
      method: 'POST',
      timeout: 60000,
      url: `v3/repositories/${this._id}/versions`,
      data: {
        display_name: version.name,
        description: version.description,
        active: true,
        from: this.editingVersion._id
      }
    })
  }

  async pull (version) {
    return this.request({
      method: 'POST',
      url: `v3/repositories/${this._id}/pull`,
      data: {
        repository_version: version
      }
    })
  }

  async push (versionId, message) {
    return this.request({
      method: 'POST',
      url: `v3/repositories/${this._id}/push`,
      data: {
        repository_version: versionId,
        msg: message
      }
    })
  }

  async revert (hash) {
    return this.request({
      method: 'POST',
      url: `v3/repositories/${this._id}/revert`,
      data: {
        hash,
        repository_version: this.trackingVersion._id
      }
    })
  }

  async reset () {
    return this.request({
      method: 'PUT',
      url: `v3/repositories/${this._id}/reset`
    })
  }

  async tag (name, hash) {
    return this.request({
      method: 'POST',
      url: `v3/repositories/${this._id}/tag`,
      data: {
        name,
        hash
      }
    })
  }

  async fetch () {
    const r = await this.request({
      method: 'GET',
      url: `v3/repositories/${this._id}/fetch_commits`
    })
    this.commits_behind = r
    return r
  }

  // Version deployed
  get activeVersion () {
    return this.versions.find(version => version.active) || this.versions[0]
  }

  set activeVersion (versionId) {
    const oldActiveVersion = this.activeVersion
    if (versionId !== oldActiveVersion._id) {
      oldActiveVersion.active = false
      const version = this.versions.find(version => version._id === versionId)
      version.update('active', true, true) // auto save is enabled
    }
  }

  // Version editing getter, returns a full version object
  // if not set, return the active (deployed) version
  get editingVersion () {
    if (this.isGit) return this.versions.find(version => version.name === 'HEAD')
    if (this._editingVersion) return this.versions.find(version => version._id === this._editingVersion)
    return this.latestVersion
  }

  // Version editing setter
  set editingVersion (versionId) {
    this._editingVersion = versionId
  }

  // Version editing getter, returns a full version object
  // if not set, return the active (deployed) version
  get trackingVersion () {
    if (this.type === 'git') return this.versions.find(version => version.name === 'HEAD')
    if (this._editingVersion) return this.versions.find(version => version._id === this._editingVersion)
    return this.latestVersion
  }

  // Returns whether or not the user can make changes on the editing version
  get readOnly () {
    if (this.type === 'git') return false
    if ((this.editingVersion?._id === this.activeVersion?._id) && this.versions?.length === 1) return false
    return this.editingVersion?._id === this.activeVersion?._id
  }

  // Returns the latest created version from the versions list
  get latestVersion () {
    const latestVersion = _maxBy(this.versions, (version) => { return version.created_at })
    return latestVersion
  }

  get isGit () {
    return this.type === 'git' && this.params?.ssh_key
  }
}

class Repositories extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v3/repositories?full=true'
    }).then(repositories => {
      return repositories.map(repository => {
        try {
          return new Repository(repository)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    })
  }

  find (id) {
    return this.request({
      url: `v3/repositories/${id}`,
      params: {
        full: true
      }
    }).then(repository => new Repository(repository))
  }

  new () {
    return new Repository()
  }
}

export default Repositories

export {
  Repository,
  Repositories
}
