<template lang="pug">
.project-template-box(
  :class="{ disabled }"
  :style="{ height: fromFunnel ? '104px' : '136px', cursor: fromFunnel ? 'pointer' : 'default', 'justify-content': fromFunnel ?  'space-around' : 'space-between' }"
  @click="$emit('chosenTemplate', item)"
)
  .service-icons-container(:class='{ few: (services.length < 5 || (services.length < 4 && fromFunnel))}')
    .icon-container(
      v-for='service in services'
      v-tooltip="{ content: disabled ? '' : service.service, delay: 0 }"
    )
      img(
        v-if='service.img'
        :src='service.img'
      )
      span(
        v-else-if='service.text'
      ) {{ service.text }}
  .title(
    :id="fromFunnel && item.id === 'end-to-end' ? 'button-template-end-to-end' : null"
  )
    span {{ $t(`project.${item.id}.prompt`) }}
    i.fp4.fp4-circle-info(
      @click.stop="templateDescription"
    )
  .subtitle(
    v-if="!fromFunnel"
    @click.stop='projectFromTemplate'
  )
    span {{ subtitle }}
    i.fp4.fp4-angle-right
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import TemplateDescriptionModal from '@/shared/components/project/TemplateDescriptionModal'

export default {
  components: {
    TemplateDescriptionModal
  },
  props: {
    item: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    fromFunnel: { type: Boolean, default: false },
    fromOnboarding: { type: Boolean, default: true }
  },
  data () {
    return {
      servicesWithDCAndLM: _cloneDeep(this.item.services)
    }
  },
  computed: {
    services () {
      const numberOfBoxes = 4
      if (this.servicesWithDCAndLM?.length > numberOfBoxes + 1) {
        const slicedServices = this.servicesWithDCAndLM.slice(0, numberOfBoxes)
        const leftover = this.servicesWithDCAndLM.length - slicedServices.length
        const moreServices = this.servicesWithDCAndLM.slice(numberOfBoxes, slicedServices.length + leftover).map(s => s.service)
        return [
          ...slicedServices,
          {
            text: `+${leftover}`,
            service: moreServices.join(', ')
          }
        ]
      }
      return this.servicesWithDCAndLM || []
    },
    subtitle () {
      return this.item?.subtitle || this.$t('core.project_template_box.subtitle_default')
    }
  },
  mounted () {
    const dmService = this.item.services.find(s => s.id === 'dm')
    if (dmService) {
      this.servicesWithDCAndLM.unshift({ id: 'lakehouse', img: require('@/core/assets/img/services/lakehouse.svg'), service: 'Lakehouse Manager' })
      this.servicesWithDCAndLM.unshift({ id: 'data-catalog', img: require('@/core/assets/img/services/data-catalog.svg'), service: 'Data Catalog' })
      this.servicesWithDCAndLM = this.servicesWithDCAndLM.filter(s => s.id !== 'dm')
    }
  },
  methods: {
    projectFromTemplate () {
      if (this.fromFunnel) {
        this.$emit('chosenTemplate', this.item)
        this.$emit('next')
      } else {
        this.$emit('create-project', this.item)
      }
    },
    templateDescription () {
      this.$modal.show(TemplateDescriptionModal, {
        item: this.item,
        services: this.servicesWithDCAndLM,
        onConfirm: this.projectFromTemplate,
        disabled: this.disabled
      }, {
        height: 'auto',
        width: 539
      })
    }
  }
}
</script>

<style lang="less">
.project-template-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 205px;
  height: 136px;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
  border-radius: 5px;

  > .service-icons-container {
    display: flex;
    justify-content: space-between;
    &.few {
      justify-content: start;
      > .icon-container {
        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
    .icon-container {
      display: flex;
      padding: 4px;
      width: 32px;
      height: 32px;
      border: 1px solid #E4E7EC;
      border-radius: 5px;
      position: relative;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
      }
      span {
        font-size: 14px;
        line-height: 18px;
        color: @blue;
      }
    }
  }
  > .title, > .subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: @text-color;
    }
    > i {
      color: @blue;
      padding-left: 15px;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        font-size: 18px;
        padding-left: 12px;
      }
    }
  }
  > .subtitle {
    cursor: pointer;
    > span, > i {
      color: @blue_flash;
    }
  }

  &.disabled {
    background: #E9ECF0;
    border: 1px solid #E4E7EC;
    cursor: not-allowed;

    .icon-container {
      background: rgba(246, 249, 252, 0.5)
    }
  }
}
</style>
